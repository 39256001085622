const os = require('os')
const { exec } = require('child_process')


function maxZIndex() {

    return Array.from(document.querySelectorAll('body .v-dialog__content'))
        .map(d => {
            return parseInt(d.style['z-index']);
        })
        .sort((a, b) => b - a)
    // .map(a => parseFloat(window.getComputedStyle(a)['z-index']))
    // .filter(a => !isNaN(a))
    // .sort()
    // .pop();
}
export default {
    mostrarTeclado({ commit, state }) {
        // console.log(os.platform());
        // console.log(exec());
        // exec('osk')
        // commit('setMostrarTeclado', true);
        console.log("MOSTRAAAR");
        setTimeout(() => {
            let i = maxZIndex()[0];
            commit('setZIndex', i)
            console.log("z index " + i)

            document.getElementsByClassName("tecladoBottom")[0].parentNode.style['z-index'] = i;
        }, 1000);
    },
    esconderTeclado({ commit, state }) {
        document.getElementById('app').focus();
        // document.activeElement.blur();
        commit('setMostrarTeclado', false);
    },
}