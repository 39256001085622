var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"max-height":"100%"}},[_c('v-col',{staticClass:"scroll-y--helper",class:_vm.$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-1' : 'px-0',staticStyle:{"max-height":"100%"},attrs:{"cols":"12","md":"8","lg":"9"}},[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'pb-16 mb-4' : ''},[(_vm.pasoPidiendo == 0 && !_vm.busquedaActiva)?[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg ficha-generica",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","color":hover ? 'primary lighten-4' : 'primary borde-blanco'},on:{"click":function($event){_vm.paso = 'listado'}}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-card-text',{staticClass:"text-uppercase font-weight-bold text-center",class:_vm.$vuetify.breakpoint.smAndDown
                          ? 'caption'
                          : _vm.$vuetify.breakpoint.mdAndDown
                          ? 'body-2'
                          : 'body-1',style:(_vm.$vuetify.breakpoint.smAndDown ? 'line-height:1;' : '')},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"black--text",attrs:{"x-large":""}},[_vm._v("mdi-arrow-left-bold")])],1)],1)],1)],1)],1)]}}],null,false,3847363169)})],1),_vm._l((_vm.categorias),function(c){return _c('v-col',{key:c.id,staticClass:"pa-2",attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg pedido-categoria",attrs:{"elevation":"4","color":hover ? 'secondary lighten-4' : ' borde-blanco'},on:{"click":function($event){return _vm.clickCategoria(c)}}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-img',{attrs:{"src":"/img/pedidosInternos/imgPedidoCategoria.svg"}},[_c('v-card-text',{staticClass:"\n                          text-uppercase\n                          font-weight-black\n                          text-center\n                          pa-0\n                        ",class:_vm.$vuetify.breakpoint.mdAndDown ? '' : 'text-h4',staticStyle:{"height":"100%"}},[_c('v-row',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"}},[_c('v-col',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(
                              _vm.$vuetify.breakpoint.lgAndDown ? 3 : 4
                            ),expression:"\n                              $vuetify.breakpoint.lgAndDown ? 3 : 4\n                            "}],staticClass:"font-weight-black body-2 my-auto pa-0 px-1",staticStyle:{"word-break":"normal !important"}},[_vm._v(" "+_vm._s(c.nombre)+" ")])],1)],1)],1)],1)],1)]}}],null,true)})],1)})],2)]:(_vm.pasoPidiendo == 1 || _vm.busquedaActiva)?[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg ficha-generica",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","color":hover ? 'primary lighten-4' : 'primary borde-blanco'},on:{"click":function($event){_vm.pasoPidiendo--}}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-card-text',{staticClass:"text-uppercase font-weight-bold text-center",class:_vm.$vuetify.breakpoint.smAndDown
                          ? 'caption'
                          : _vm.$vuetify.breakpoint.mdAndDown
                          ? 'body-2'
                          : 'body-1',style:(_vm.$vuetify.breakpoint.smAndDown ? 'line-height:1;' : '')},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"black--text",attrs:{"x-large":""}},[_vm._v("mdi-arrow-left-bold")])],1)],1)],1)],1)],1)]}}])})],1),_vm._l((_vm.productosDefinitivos),function(p){return _c('v-col',{key:p.id,staticClass:"pa-2",attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg pedido-articulo",attrs:{"elevation":"4","color":hover ? 'secondary lighten-4' : ' borde-blanco'},on:{"click":function($event){return _vm.elegirProducto(p)}}},[(p.cantidadCarrito)?_c('v-chip',{staticClass:"\n                      custom-badge\n                      producto-badge\n                      elevation-3\n                      info\n                      body-1\n                      font-weight-bold\n                      white--text\n                    "},[_vm._v(_vm._s(p.cantidadCarrito)+" ")]):_vm._e(),_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-img',{attrs:{"src":"/img/pedidosInternos/imgPedidoArticulo.svg"}},[_c('v-card-text',{staticClass:"\n                          text-uppercase\n                          font-weight-black\n                          text-center\n                          pa-0\n                        ",staticStyle:{"height":"100%"}},[_c('v-row',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"}},[_c('v-col',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(
                              _vm.$vuetify.breakpoint.lgAndDown ? 3 : 4
                            ),expression:"\n                              $vuetify.breakpoint.lgAndDown ? 3 : 4\n                            "}],staticClass:"font-weight-black body-2 my-auto pa-0 px-1",staticStyle:{"word-break":"normal !important"}},[_vm._v(" "+_vm._s(p.nombre)+" ")])],1)],1)],1)],1)],1)]}}],null,true)})],1)})],2)]:_vm._e()],2)]),(_vm.$vuetify.breakpoint.mdAndUp)?[_c('v-col',{staticClass:"pa-0",staticStyle:{"position":"relative","z-index":"999"},attrs:{"cols":"7","md":"4","lg":"3"}},[_c('v-card',{staticClass:"mx-auto border",attrs:{"elevation":"0","height":'calc(100vh - ' + _vm.$vuetify.application.top * 2 + 'px)',"width":"100%"}},[_c('v-navigation-drawer',{staticClass:"grey lighten-5 elevation-0",attrs:{"absolute":"","width":"100%","permanent":""},scopedSlots:_vm._u([(!_vm.editandoProductos)?{key:"append",fn:function(){return [_c('div',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"text-h6 black--text",attrs:{"x-large":"","color":"primary","block":"","disabled":!_vm.pedidoEditandoObj.productos ||
                    _vm.pedidoEditandoObj.productos.length < 1 ||
                    !_vm.pedidoEditandoObj.id},on:{"click":function($event){return _vm.$store.commit('pedidos/setCobrarDialog', true)}}},[_vm._v(" Cobrar "),_c('v-spacer'),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.precioTotal)+" €")])],1)],1),_c('div',{staticClass:"pa-0"},[_c('cobrar')],1)]},proxy:true}:null],null,true)},[_c('productos-carrito',{attrs:{"productos":_vm.carritoInterno}})],1)],1)],1)]:_vm._e()],2),_c('v-footer',{staticClass:"pa-0",attrs:{"height":"78","app":""}},[_c('v-toolbar',{staticClass:"ma-0",attrs:{"dark":"","height":"78","flat":"","fluid":""}},[_c('v-spacer'),(_vm.pedidoEnCurso.estado == 'realizando')?_c('v-btn',{staticClass:"px-16 rounded-lg",staticStyle:{"background":"#464646"},attrs:{"outlined":"","x-large":"","loading":_vm.$store.state.internos.pedidoEnviarLoading,"disabled":_vm.$store.getters['internos/enviarButtonDisabled']},on:{"click":_vm.enviarPedidoInterno}},[_vm._v(" Enviar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-send")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }