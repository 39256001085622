<template>
  <v-dialog
    v-model="abrirInfoCliente"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    max-width="600"
    scrollable
    :content-class="
      $vuetify.breakpoint.mdAndUp
        ? 'mt-n4 dialogo--custom-fs elevation-0'
        : 'dialogo--custom-fs'
    "
  >
    <v-card
      v-if="pedidoEditandoObj"
      class="transparent"
      :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
    >
      <v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="py-6 transparent">
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
          color="primary"
          @click="$store.commit('setAbrirInfoCliente', false)"
          absolute
          right
          :class="$vuetify.breakpoint.smAndDown ? 'mt-16' : 'mt-10 mr-n4'"
          ><v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 54" color="black"
            >mdi-close</v-icon
          ></v-btn
        ></v-card-title
      >
      <v-card-title
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'caption'
            : $vuetify.breakpoint.mdAndDown
            ? 'body-2'
            : 'body-1'
        "
        class="text--primary rounded-t-lg blue-grey lighten-5"
        style="height: 78px"
      >
        <v-icon color="info" class="mr-4"> mdi-information-outline </v-icon>

        <div>
          <span class="font-weight-bold"> </span>
        </div>
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          fab
          height="48"
          width="48"
          color="primary"
          @click="$store.commit('setAbrirInfoCliente', false)"
          absolute
          right
          class="mr-n2 mt-n1"
          ><v-icon size="36" color="black">mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text
        class="pt-4 blue-grey lighten-5"
        :class="
          pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
            ? 'rounded-0'
            : 'rounded-b-lg'
        "
      >
        <div v-if="pedidoEditandoObj">
          <v-alert
            v-if="!!pedidoEditandoObj.facturaCliente"
            type="warning"
            dense
            colored-border
            border="left"
            class="mb-2 rounded-lg"
            icon="mdi-alert"
            >Pedido factura generada. No se puede cambiar el cliente</v-alert
          >
          <template v-if="pedidoEditandoObj.copyUser">
            <v-row>
              <v-col
                v-if="pedidoEditandoObj.copyUser.displayName"
                cols="10"
                class="font-weight-bold mb-2"
                :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'text-h5'"
              >
                {{ pedidoEditandoObj.copyUser.displayName }}

                <!-- José Adolfo Fernández de las Heras Martínez-Díaz -->
                <!-- {{ pedidoEditandoObj.copyUser.localApellido1 }}
                {{ pedidoEditandoObj.copyUser.localApellido2 }}  -->
              </v-col>
              <v-col cols="2">
                <v-btn
                  @click="editarCliente"
                  color="primary"
                  class="black--text rounded-lg"
                  block
                  :disabled="esContado || !!pedidoEditandoObj.facturaCliente"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="pedidoEditandoObj.copyUser.phoneNumber">
              <v-col
                cols="12"
                class="d-flex flex-row align-start"
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'body-1'"
              >
                <v-icon
                  :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                  class="mr-4"
                >
                  mdi-phone
                </v-icon>
                {{ pedidoEditandoObj.copyUser.phoneNumber }}
              </v-col>
            </v-row>
          </template>
          <template v-if="pedidoEditandoObj.direccion">
            <v-row>
              <v-col
                cols="12"
                class="d-flex flex-row align-start"
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'body-1'"
              >
                <v-icon
                  :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                  class="mr-4"
                >
                  mdi-map-marker
                </v-icon>
                <div>
                  {{ pedidoEditandoObj.direccion.calle
                  }}<span v-if="pedidoEditandoObj.direccion.numero"
                    >, {{ pedidoEditandoObj.direccion.numero }}</span
                  ><span v-if="pedidoEditandoObj.direccion.cp"
                    >.
                    {{ pedidoEditandoObj.direccion.cp }}
                  </span>
                  <span v-if="pedidoEditandoObj.direccion.municipio">
                    - {{ pedidoEditandoObj.direccion.municipio }}
                  </span>
                  <span v-if="pedidoEditandoObj.direccion.provincia">
                    ({{ pedidoEditandoObj.direccion.provincia }})
                  </span>
                  <div
                    v-if="pedidoEditandoObj.direccion.adicional"
                    class="mt-1"
                  >
                    {{ pedidoEditandoObj.direccion.adicional }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
          <!--  -->
          <template
            v-if="
              pedidoEditandoObj.repartoTexto &&
              pedidoEditandoObj.repartoTexto != 'Sin más info'
            "
          >
            <v-row>
              <v-col
                cols="12"
                class="d-flex flex-row align-start font-weight-regular"
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'body-2'"
                style="line-height: 1.2"
              >
                <v-icon
                  :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                  class="mr-4"
                >
                  mdi-note-text
                </v-icon>
                {{ pedidoEditandoObj.repartoTexto }}
              </v-col>
            </v-row>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="py-4 blue-grey lighten-5 justify-end">
        <!--         <v-btn
          @click="editarCliente"
          large
          color="primary"
          class="black--text rounded-lg mr-4"
          :disabled="esContado"
        >
          Editar cliente
        </v-btn> -->
        <v-btn
          v-if="pedidoEditandoObj.direccion"
          @click="cambiarCliente"
          large
          color="primary"
          class="black--text rounded-lg mr-4"
          :disabled="!!pedidoEditandoObj.facturaCliente"
        >
          CAMBIAR DIRECCIÓN</v-btn
        >
        <v-btn
          @click="cambiarCliente"
          large
          color="primary rounded-lg"
          class="black--text"
          :disabled="!!pedidoEditandoObj.facturaCliente"
        >
          {{ esContado ? "ASIGNAR " : "CAMBIAR " }} CLIENTE</v-btn
        >
      </v-card-actions>
      <template
        v-if="
          pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
        "
      >
        <v-divider></v-divider>
        <v-card-actions
          class="py-3 blue-grey lighten-5"
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : 'rounded-0'"
        >
          <v-row>
            <v-col
              v-if="pedidoEditandoObj.fechaMostrar"
              cols="12"
              sm="6"
              class="pb-0 pb-sm-3"
            >
              <v-icon size="18" class="mr-2 mt-n1"> mdi-clock-outline </v-icon
              >Registrado a las
              <span class="font-weight-bold">
                &nbsp;{{ pedidoEditandoObj.fechaMostrar }}
              </span>
            </v-col>
            <v-col
              v-if="
                pedidoEditandoObj.copyUsuarioTpv &&
                pedidoEditandoObj.copyUsuarioTpv.nombre
              "
              cols="12"
              sm="6"
              :offset-sm="!pedidoEditandoObj.fechaMostrar ? 6 : 0"
              class="text-sm-right pt-0 pt-sm-3"
            >
              <v-icon
                v-if="$vuetify.breakpoint.xsOnly"
                size="18"
                class="mr-2 mt-n1"
              >
                mdi-account-box </v-icon
              >Atendido por
              <span class="font-weight-bold">
                &nbsp;{{ pedidoEditandoObj.copyUsuarioTpv.nombre }}
              </span>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    esContado() {
      return this.pedidoEditandoObj && this.pedidoEditandoObj.uid == "0";
    },
    numeroPidiendo() {
      return this.pedidoEditandoObj
        ? this.pedidoEditandoObj.numPedidoRestDia
        : "-";
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      /* set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      }, */
    },
    abrirInfoCliente: {
      get() {
        return this.$store.state.abrirInfoCliente;
      },
      set(v) {
        this.$store.commit("setAbrirInfoCliente", v);
      },
    },
    cambiandoCliente: {
      get() {
        return this.$store.state.cambiandoCliente;
      },
      set(v) {
        this.$store.commit("pedidos/setCambiandoCliente", v);
      },
    },
  },
  methods: {
    cambiarCliente() {
      this.cambiandoCliente = true;
      this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
      this.abrirInfoCliente = false;
      this.$store.commit("pedidos/setCobrarDialogFullscreen", false);
      this.$store.commit("pedidos/setPedidoDialog", true);
    },
    editarCliente() {
      this.cambiandoCliente = true;

      if (this.pedidoEditandoObj.uid) {
        this.$store.commit("clientes/setDialogoClientes", false);

        this.$store.dispatch(
          "clientes/buscarClienteEditar",
          this.pedidoEditandoObj.uid
        );
        this.abrirInfoCliente = false;
        this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
        this.$store.commit("pedidos/setPedidoDialog", true);
        setTimeout(() => {
          this.$store.commit("clientes/setDialogoClientes", true);
        }, 100);
      }
    },
  },
};
</script>
