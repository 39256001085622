import moment from "moment";
export default {
  numPedidos(state, getters, rootState) {
    var pedidosRecorrer;
    if (rootState.directorio.directorioMatriz) {
      pedidosRecorrer = [
        ...state.pedidosArray,
        ...state.pedidoRealizandoTpv,
        ...state.pedidosDirectorioMatriz.filter((p) => {
          return (
            p.negocio + "-" + p.restaurante !== p.restauranteDirectorioMatriz
          );
        }), //eliminaos los que el restaurante matriz es sobre dónde se ha hecho el pedido, porque vienen en pedidosRaw
      ].filter((p) => !!p || !p.multiplePadre);
      // pedidosRecorrer = this.pedidosRaw;
    } else {
      pedidosRecorrer = [...state.pedidosArray, ...state.pedidosRealizandoTpv];
    }
    return pedidosRecorrer.filter((p) => !p.eliminado).length;
  },
  mesaObjPedido(state, getters, rootState, rootGetters) {
    let mesas = rootGetters["mesasFiltradas"];
    if (state.pedidoEditandoObj.mesa) {
      let f = mesas.find((m) => m.id == state.pedidoEditandoObj.mesa);
      return f ? f : false;
    } else {
      return false;
    }
  },
  pedidos(state, getters, rootState) {
    var pedidosRecorrer;
    if (rootState.directorio.directorioMatriz) {
      pedidosRecorrer = [
        ...state.pedidosRealizandoTpv,
        ...state.pedidosArray,
        ...state.pedidosDirectorioMatriz.filter((p) => {
          return (
            p.negocio + "-" + p.restaurante !== p.restauranteDirectorioMatriz
          );
        }), //eliminaos los que el restaurante matriz es sobre dónde se ha hecho el pedido, porque vienen en pedidosRaw
      ].filter((p) => !!p);
      // pedidosRecorrer = this.pedidosRaw;
    } else {
      pedidosRecorrer = [...state.pedidosArray, ...state.pedidosRealizandoTpv];
    }
    // return pedidosRecorrer
    let devolver = pedidosRecorrer.map((p) => {
      let colorFila = "";
      let zonaHoraria = p.copyRest.confGeneral.zonaHoraria
        ? p.copyRest.confGeneral.zonaHoraria
        : "Europe/Madrid";

      if (p.eliminado) {
        colorFila = "red lighten-2";
      } else if (p.solicitarEliminado) {
        colorFila = "yellow lighten-1";
      }
      let contadorMensaje = p.historial
        ? p.historial.reduce(
            (n, h) => n + h.mensaje.includes("Mensaje desde la web"),
            0
          )
        : 0;
      let auxReparto = {};
      switch (
        p.estadoReparto //no-gestionado, creado, asignado, recogido, entregado, problema
      ) {
        case "no-gestionado":
          auxReparto.repartoColor = "orange";
          auxReparto.repartoTexto = "Sin gestionarse";
          break;
        case "creado":
          auxReparto.repartoColor = "yellow";
          auxReparto.repartoTexto = "Creado la petición de reparto";
          break;
        case "asignado":
          auxReparto.repartoColor = "pink";
          auxReparto.repartoTexto = "Asignado a repartidor";
          break;
        case "en-camino":
          auxReparto.repartoColor = "blue";
          auxReparto.repartoTexto = "En reparto";
          break;
        case "entregado":
          auxReparto.repartoColor = "green";
          auxReparto.repartoTexto = "Entregado";
          break;
        case "problema":
          auxReparto.repartoColor = "red";
          auxReparto.repartoTexto = "Problema en el reparto";
          break;
        default:
          auxReparto.repartoColor = "red";
          auxReparto.repartoTexto = "Sin más info";
          break;
      }

      return {
        ...p,
        fechaMostrar: moment(p.fechaServidor.toDate())
          .tz(zonaHoraria)
          .format("HH:mm"),
        fechaMostrarDia: moment(p.fechaServidor.toDate())
          .tz(zonaHoraria)
          .format("DD/MM/YY HH:mm"),
        fechaEntrega: moment
          .unix(p.programadoFecha)
          .tz(zonaHoraria)
          .format("HH:mm"),
        colorFila: colorFila,
        contadorMensaje: contadorMensaje,
        ...auxReparto,
      };
    });

    //acomplamos pedidos múltiples
    devolver = devolver.map((multi) => {
      if (multi.multipleHijos && multi.multipleHijos.length > 0) {
        return {
          ...multi,
          multipleHijosObj: multi.multipleHijos.map((hijo) => {
            let found = devolver.find((p) => p.id == hijo);
            return found ? found : {};
          }),
        };
      } else {
        return multi;
      }
    });
    if (rootState.directorio.directorioMatriz) {
      // es el restaurante matriz del directorio y no tiene que mostrar hijos
      devolver = devolver.filter((p) => !p.multiplePadre);
    }

    return devolver.sort(
      (a, b) =>
        moment(b.fechaServidor.toDate()).unix() -
        moment(a.fechaServidor.toDate()).unix()
    );
  },
  // pedidoEditandoObj(state, getters) {
  //   let f = getters.pedidos.find(p => p.id == state.pedidoEditando)
  //   return f ? f : null
  // }
  //seee agrupa por modos
  resumenesPedidos(state, getters, rootState, rootGetters) {
    let objBase = {
      nombre: "",
      // tipo: 'formaPago',
      total: 0,
      numPedidos: 0,
      //formas pago
      efectivo: 0,
      tarjeta_domicilio: 0,
      tarjeta_online: 0,
      tarjeta_local: 0,
      local: 0,
      otro: 0,
    };
    var res = [];
    res.push({
      ...objBase,
      nombre: "TOTALES",
    });
    rootState.datosTpv.modos.forEach((m) => {
      res.push({
        ...objBase,
        nombre: "MODO " + m.nombre.toUpperCase(),
        id: m.id,
      });
    });
    rootState.datosTpv.cuentas.forEach((m) => {
      res.push({
        ...objBase,
        nombre: "CUENTA " + m.nombre.toUpperCase(),
        id: m.email,
      });
    });
    getters.pedidos
      .filter((p) => !!p.cobrado)
      .forEach((p) => {
        if (!!p.modoId) {
          res[0].total += parseFloat(p.precioTotal);
          res[0].numPedidos++;
          res[0][p.forma_pago] += parseFloat(p.precioTotal);
          let fIndex = res.findIndex((r) => r.id == p.modoId);
          if (fIndex > -1) {
            res[fIndex].total += parseFloat(p.precioTotal);
            res[fIndex].numPedidos++;
            res[fIndex][p.forma_pago] += parseFloat(p.precioTotal);
          }
          if (!!p.cuentaTpv) {
            let fIndexCuenta = res.findIndex((r) => r.id == p.cuentaTpv);
            if (fIndexCuenta > -1) {
              res[fIndexCuenta].total += parseFloat(p.precioTotal);
              res[fIndexCuenta].numPedidos++;
              res[fIndexCuenta][p.forma_pago] += parseFloat(p.precioTotal);
            }
          }
        }
      });

    // getters.pedidos.forEach(p => {
    //   res.total.total += p.precioTotal;
    //   res.total.numPedidos++;
    //   if (!!p.cobrado) {
    //     res.cobrado.total += p.precioTotal;
    //     res.cobrado.numPedidos++;
    //   }
    //   console.log(p);
    //   if (!!p.modoId) {
    //     res[p.modoId].total += p.precioTotal;
    //     res[p.modoId].numPedidos++;
    //   }
    //   if (!!p.forma_pago) {
    //     res[p.forma_pago].total += p.precioTotal;
    //     res[p.forma_pago].numPedidos++;
    //   }
    // })

    return res;
    return rootState.datosTpv.modos;
  },
  tituloPedido(state) {
    var res = "";
    if (!!state.pedidoEditandoObj) {
      if (
        state.pedidoEditandoObj.copyMesa &&
        state.pedidoEditandoObj.modoCopy
      ) {
        return (
          "MESA " +
          state.pedidoEditandoObj.copyMesa.nombre +
          " " +
          state.pedidoEditandoObj.modoCopy.nombre
        );
      } else if (state.pedidoEditandoObj.modoCopy) {
        let s = state.pedidoEditandoObj.numPedidoRestDiaModoMostrar
          ? " " + state.pedidoEditandoObj.numPedidoRestDiaModoMostrar
          : state.pedidoEditandoObj.numPedidoRestDia
          ? " " + state.pedidoEditandoObj.numPedidoRestDia
          : "";
        return state.pedidoEditandoObj.modoCopy.nombre + s;
      }

      switch (state.pedidoEditandoObj.modo) {
        case "domicilio":
          res = "DOMICILIO";
          break;
        case "recoger":
          res = "RECOGER";
          break;
        case "mesa":
          res = "MESA " + state.pedidoEditandoObj.copyMesa.nombre;
          break;
        case "mostrador":
          res = "MOSTRADOR ";
          break;
      }
    }
    return res;
  },
};
