import firebase from "firebase";
import { db } from "@/firebase";

export default {
  firebaseLogin: ({ commit }, user) => {
    //let _vm = this
    return firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then((user) => {
        return db
          .collection("users")
          .doc(user.user.uid)
          .get()
          .then((doc) => {
            let user_db = {};
            if (doc.exists) {
              user_db = doc.data();
            }
            //reducir el objeto user a lo necesario
            //var array_promesas = [];
            let user_combinado = { ...user, ...user_db };
            commit("setUser", user_combinado);
            location.reload();
          });
      });
  },
  firebaseLogout: ({ state, rootState, commit }, reload = true) => {
    var promesa = Promise.resolve();

    if (firebase.auth().currentUser) {
      var uid = firebase.auth().currentUser.uid;
      var userStatusDatabaseRef = firebase.database().ref("/status/" + uid);
      if (rootState.restauranteObj && rootState.restauranteObj.id) {
        var isOfflineForDatabase = {
          uid: uid,
          state: "offline",
          rest: rootState.restauranteObj.id,
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };
        promesa = userStatusDatabaseRef.set(isOfflineForDatabase);
      }
      promesa
        .then(() => firebase.auth().signOut())
        .then(() => {
          commit("logout");

          if (reload) location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      commit("logout");
    }
    return promesa;
  },
  firebaseLoginToken({ commit }, token) {
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then((user) => {
        commit("SET_LOGIN_COMANDERO", true);
        // console.log(firebase.currentUser.uid);
        // return db
        //   .collection("users")
        //   .doc(user.user.uid)
        //   .get()
        //   .then((doc) => {
        //     console.log(doc);
        //     let user_db = {};
        //     if (doc.exists) {
        //       user_db = doc.data();
        //     }
        //     //reducir el objeto user a lo necesario
        //     //var array_promesas = [];
        //     let user_combinado = { ...user, ...user_db };
        //     commit("setUser", user_combinado);
        //     location.reload();
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      });
  },
};
