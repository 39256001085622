import { db, functions } from "@/firebase"
import moment from 'moment'

export default {
    getReservasSinConfirmar({ rootState, commit }) {
        const rest = rootState.restauranteObj.id;
        return db.collection('restaurantes').doc(rest)
            .collection('eventos')
            .where('tipo', '==', 'reserva')
            .where('confirmado', '==', false)
            .onSnapshot(r => {
                commit('setReservasNoConfirmada',
                    r.size > 0 ?
                        r.docs.map(reserva => {
                            return {
                                ...reserva.data(),
                                id: reserva.id
                            }
                        }) : []
                )
            })
    },
    getReservasMesViendo({ rootState, commit }, fecha) {
        const rest = rootState.restauranteObj.id;
        return db.collection('restaurantes').doc(rest)
            .collection('eventos')
            .where('tipo', '==', 'reserva')
            .where('reservaInicioUnix', '>', fecha.inicio)
            .where('reservaInicioUnix', '<', fecha.fin)
            .get()
            .then(r => {
                commit('setReservas',
                    r.size > 0 ?
                        r.docs.map(reserva => {
                            return {
                                ...reserva.data(),
                                id: reserva.id
                            }
                        }) : []
                )
            })
    },
    getConfiguracionReservas({ rootState, commit }) {
        const rest = rootState.restauranteObj.id;
        return db.collection('restaurantes').doc(rest)
            .collection('reservas').doc('configuracion').get()
            .then(r => {
                if (r.exists) {
                    commit('setReservasConfiguracion', r.data()) //por si queremos usarlo lectura
                    return r.data(); // para la vista reservas configuración, asociarla a un objeto modificable
                } else {
                    commit('setReservasConfiguracion', {})
                }
            })
    },
    confirmarReserva({ rootState }, reserva) {
        const rest = rootState.restauranteObj.id;
        var conf = functions.httpsCallable('httpRestaurantesConfirmarReserva');
        return conf({
            reserva: reserva,
            rest: rest
        })
            .then((r) => {
                if (!r.data.ok) { return Promise.reject(r.data.error) }
                else { return Promise.resolve() }
            })
    },
    confirmarReservaManual({ rootState }, reserva) {
        const rest = rootState.restauranteObj.id;
        return db.collection('restaurantes')
            .doc(rest)
            .collection('eventos')
            .doc(reserva.id).set({
                confirmado: moment().unix()
            }, { merge: true })
    },
    saveReserva({ rootState }, reserva) {
        const rest = rootState.restauranteObj.id;

        if (!reserva.id) {
            return db.collection('restaurantes').doc(rest)
                .collection('eventos').add({ ...reserva, tipo: 'reserva' })
        } else {
            return db.collection('restaurantes').doc(rest)
                .collection('eventos').doc(reserva.id).set(reserva, { merge: true })
        }
    },
    eliminarReserva({ rootState }, reservaId) {
        const rest = rootState.restauranteObj.id;

        return db.collection('restaurantes').doc(rest)
            .collection('eventos').doc(reservaId).delete()
    },
    mounted() {
        this.$store.dispatch("calendario/getConfiguracionReservas").then((r) => {
            if (!!r) {
                this.config = r;
            }
        });
    }
}