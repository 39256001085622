export default {
  setPedidos(state, p) {
    state.pedidos = p;
  },
  setCategorias(state, c) {
    state.categorias = c;
  },
  setProductos(state, p) {
    state.productos = p;
  },
  setBusqueda(state, str) {
    state.busqueda = str;
  },
  setCarritoInterno(state, v) {
    state.pedidoEnCurso.carritoInterno = v;
  },
  setFechaInicio(state, f) {
    state.pedidoEnCurso.fechaInicio = f;
  },
  setId(state, id) {
    state.pedidoEnCurso.id = id;
  },
  setPedidoGuardado(state, v) {
    state.pedidoGuardado = v;
  },
  setPedidoEnviado(state, v) {
    state.pedidoEnviado = v;
  },
  setPedidoEnCurso(state, p) {
    state.pedidoEnCurso = p;
  },
  resetPedidoEnCurso(state) {
    state.pedidoEnCurso = {
      id: null,
      fechaInicio: null,
      fechaFin: null,
      carritoInterno: [],
    };
  },
  setPedidoGuardadoLoading(state, v) {
    state.pedidoGuardadoLoading = v;
  },
  setPedidoEnviarLoading(state, v) {
    state.pedidoEnviarLoading = v;
  },
  setPedidoEnCursoComprobar(state, p) {
    state.pedidoEnCursoComprobar = p;
  },
  continuarPedidoComprobar(state) {
    state.pedidoEnCurso = state.pedidoEnCursoComprobar;
    state.pedidoEnCursoComprobar = {
      id: null,
      fechaInicio: null,
      fechaFin: null,
      carritoInterno: [],
    };
  },
  resetPedidoComprobar(state) {
    state.pedidoEnCursoComprobar = {
      id: null,
      fechaInicio: null,
      fechaFin: null,
      carritoInterno: [],
    };
  },
  setEditarProductoInternoObj(state, obj) {
    state.editarProductoInternoObj = obj;
  },
  SET_INTERNOS_DIALOG(state, b) {
    state.internosDialog = b;
  },
  SET_PASO(state, str) {
    state.paso = str;
  },
  SET_PASO_PIDIENDO(state, i) {
    state.pasoPidiendo = i;
  },
};
