export default {
  data() {
    return {
      cols: {
        cols: "6",
        sm: "4",
        md: "3",
      },
    };
  },
  created: function () {},
  computed: {},
  methods: {
    calcularColumnas(items, small = false) {
      let maximo;

      if (this.$vuetify.breakpoint.xs) {
        maximo = 6;
      } else if (this.$vuetify.breakpoint.sm) {
        maximo = 8;
      } else {
        maximo = 12;
      }
      if (items.length <= maximo) {
        this.cols = {
          cols: "6",
          sm: "3",
          md: "2",
        };
      } else {
        this.cols = {
          cols: small ? "3" : "4",
          sm: small ? "2" : "3",
          md: small ? "1" : "2",
        };
      }
    },
  },
};
