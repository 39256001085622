import { db } from "@/firebase";
import moment from "moment";

import axios from "axios";
var url = "https://api.printnode.com";
import firebase from "firebase";

export default {
  ejecutarImpresion({ commit, dispatch }, trabajoImprimir) {
    var impresionCorrecta;

    return dispatch(
      "configuracion/enviarDatosDispositivo",
      {
        datos: trabajoImprimir.datos,
        dispositivo: trabajoImprimir.dispositivo,
      },
      { root: true }
    )
      .then((r) => {
        impresionCorrecta = r !== false;

        if (
          impresionCorrecta &&
          trabajoImprimir.pedidoid &&
          trabajoImprimir.impresionCocina
        )
          return db
            .collection("pedidos")
            .doc(trabajoImprimir.pedidoid)
            .set(
              {
                impresionCocina: trabajoImprimir.impresionCocina,
              },
              { merge: true }
            )
            .then(() => true);
      })
      .then((r) => {
        //if (trabajoImprimir.obligatorio) {
        if (impresionCorrecta && !!trabajoImprimir.pedidoid) {
          return db.collection("pedidos").doc(trabajoImprimir.pedidoid).set(
            {
              impresionCaja: moment().unix(),
              impresionLocalEstado: "impreso",
            },
            { merge: true }
          );
        }
        //}
      })
      .then(() => {
        if (impresionCorrecta || !trabajoImprimir.obligatorio) {
          console.log("impiresion correcto " + impresionCorrecta);
          if (!!trabajoImprimir.idImpresion) {
            return dispatch(
              "pedidos/marcarImpresionNulo",
              trabajoImprimir.idImpresion,
              { root: true }
            ).then(() => {
              commit("eliminarPrimero");
            });
          } else {
            commit("eliminarPrimero");
          }
        }
      })

      .catch((e) => {
        console.log(e);
        console.log("no se ha imprimido");
      });
  },
  ejecutarImpresionPN({ rootState, commit, dispatch }, trabajoImprimir) {
    var impresionCorrecta;
    if (
      !!rootState.configuracionTpv &&
      !rootState.configuracionTpv.printnodeExclusivo
    )
      commit("marcarImprimiendoPrimero");

    return dispatch("printJob", {
      datos: trabajoImprimir.datos,
      impObj: trabajoImprimir.dispositivo,
      obligatorio: trabajoImprimir.obligatorio,
    })
      .then((r) => {
        impresionCorrecta = r !== false;
        if (
          /*impresionCorrecta && */ trabajoImprimir.pedidoid &&
          trabajoImprimir.impresionCocina
        ) {
          // return db
          //     .collection("pedidos")
          //     .doc(trabajoImprimir.pedidoid)
          //     .set(
          //         {
          //             impresionCocina: trabajoImprimir.impresionCocina,
          //             // impresionCocina: firebase.firestore.FieldValue.arrayUnion(...trabajoImprimir.impresionCocina)
          //         },
          //         { merge: true }
          //     )
          //     .then(() => true);
        }
      })
      .then((r) => {
        if (!!trabajoImprimir.obligatorio) {
          if (
            impresionCorrecta &&
            trabajoImprimir &&
            trabajoImprimir.pedidoid
          ) {
            return db.collection("pedidos").doc(trabajoImprimir.pedidoid).set(
              {
                impresionCaja: moment().unix(),
                impresionLocalEstado: "impreso",
              },
              { merge: true }
            );
          }
        }
      })
      .then(() => {
        if (impresionCorrecta || !trabajoImprimir.obligatorio) {
          console.log("impiresion correcto " + impresionCorrecta);
          if (!!trabajoImprimir.idImpresion) {
            return dispatch(
              "pedidos/marcarImpresionNulo",
              trabajoImprimir.idImpresion,
              { root: true }
            ).then(() => {
              commit("eliminarPrimero");
            });
          } else {
            commit("eliminarPrimero");
          }
        }
      })

      .catch((e) => {
        console.log(e);
        console.log("no se ha imprimido");
        return Promise.reject(e);
      });
  },

  printJob({ rootState }, { datos, impObj, obligatorio }) {
    //pedido.carrito.productos = pedidoUtils.carritoMostrar(pedido.carrito);
    const apikey = rootState.configuracionTpv.printnodeApikey;
    if (datos) {
      let file64 = Buffer.from(datos).toString("base64");
      let copias = !!impObj && impObj.copias ? impObj.copias : 1;
      var p = [];
      for (let i = 1; i <= copias; i++) {
        let data = {
          printerId: impObj.printnodeImpresora,
          title: " Ticket PRINTNODE TPV",
          contentType: "raw_base64",
          content: Buffer.from(datos).toString("base64"),
          source: "TPV FUNEAT",
        };
        if (!obligatorio) data.expireAfter = 60 * 4;
        if (apikey) {
          p.push(
            axios({
              baseURL: url + "/printjobs",
              auth: { username: apikey },
              method: "post",
              data: data,
            })
              .then((res) => {
                return;
                let r = res.data;
                return db
                  .collection("pedidos")
                  .doc(pedido.id)
                  .set(
                    {
                      printNodeArray: admin.firestore.FieldValue.arrayUnion(r),
                      historial: admin.firestore.FieldValue.arrayUnion({
                        fecha: moment().unix(),
                        usuario: "sistema",
                        mensaje:
                          "Ticket enviado " +
                          (impObj.nombre ? impObj.nombre.toUpperCase() : "") +
                          " " +
                          r +
                          ", copia " +
                          copia,
                        adicional: {
                          tipo: "printnode",
                          printNodeTicket: r,
                          printNodeEstado: true,
                        },
                      }),
                    },
                    { merge: true }
                  )
                  .then(() => {
                    return r;
                  });
              })

              .catch((e) => {
                console.log(e);
              })
          );
        }
      }
      return Promise.all(p);
    }
  },
};
