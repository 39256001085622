<template>
  <!--datos de cliente que está realizando el pedido-->
  <v-card color="transparent" flat>
    <v-card-title>CLIENTE</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Nombre"
            readonly
            v-model="nombreClienteMostrar"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Teléfono"
            readonly
            v-model="telefonoClienteMostrar"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    pedido() {
      return this.$store.state.pedidos.pedidoEditandoObj;
    },
    cliente() {
      return this.pedido && this.pedido.copyUser
        ? this.pedido.copyUser
        : {
            nombre: "",
            telefono: "",
            email: "",
          };
    },
    nombreClienteMostrar() {
      if (this.cliente) {
        return this.cliente.localNombre
          ? this.cliente.localNombre
          : this.cliente.displayName
          ? this.cliente.displayName
          : "Sin nombre";
      } else {
        return "";
      }
    },
    telefonoClienteMostrar() {
      if (this.cliente) {
        return this.cliente.phoneNumber
          ? this.cliente.phoneNumber
          : this.cliente.telefono
          ? this.cliente.telefono
          : "Sin teléfono";
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>

<style>
</style>