export default {
  role(state) {
    return state.user && state.user.role ? state.user.role : null;
  },
  isAdmin(state, getters) {
    return getters.role == "admin";
  },
  isTpv(state, getters) {
    return getters.role == "tpv";
  },
  isComandero(state, getters) {
    return getters.role == "tpv-comandero";
  },
  /*perfilUsuario(state, getters, rootState) {
        let user = state.user;
        const found = rootState.users.roles.find(e => e.value == user.role);
        let roleObj = {
            text: 'Sin asignar',
            value: null
        }
        if (found) {
            roleObj = {
                text: found.text,
                value: found.value
            }
        }
        return {
            nombre: (user.nombre) ? user.nombre : user.displayName,
            apellidos: (user.apellidos) ? user.apellidos : '',
            email: user.email,
            role: roleObj
        }
    }*/
};
