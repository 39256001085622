<template>
  <v-dialog
    v-model="infoProductoDialogo"
    max-width="800"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="
      $vuetify.breakpoint.mdAndUp
        ? 'mt-n4'
        : ''
    "
  >
    <v-card
      class="transparent"
      :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
    >
      <v-card-title
        v-if="$vuetify.breakpoint.mdAndUp"
        class="py-6 transparent"
      >
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
          color="primary"
          @click="infoProductoDialogo = false"
          absolute
          right
          :class="$vuetify.breakpoint.smAndDown ? 'mt-16' : 'mt-10 mr-n4'"
          ><v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 54"
            color="black"
            >mdi-close</v-icon
          ></v-btn
        ></v-card-title
      >
      <v-card-title
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'caption'
              : $vuetify.breakpoint.mdAndDown
              ? 'title rounded-t-lg'
              : 'display-1 rounded-t-lg'
          "
          class="text--primary blue-grey lighten-5"
        >
          <!-- <v-menu rounded="lg">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :class="$vuetify.breakpoint.mdAndUp ? 'mr-6' : 'mr-2'"
              >
                <v-icon :large="$vuetify.breakpoint.mdAndUp"
                  >mdi-account</v-icon
                >
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>Menú opción 1</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Menú opción 2</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Menú opción 3</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          INFORMACIÓN DEL PRODUCTO<!--  <span class="text-capitalize">{{ producto.nombre }}</span> -->
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            fab
            height="48"
            width="48"
            color="primary"
            @click="infoProductoDialogo = false"
            absolute
            right
            class="mr-n2"
            ><v-icon size="36" color="black">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
<!--       <v-toolbar dark style="z-index: 1">
        <v-spacer />
        <h2
          :class="
            $vuetify.breakpoint.smAndDown ? 'caption font-weight-black' : ''
          "
        >
          INFORMACIÓN DEL PRODUCTO <span class="text-capitalize">{{ producto.nombre }}</span>
        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
          color="primary"
          @click="infoProductoDialogo = false"
          absolute
          top
          right
          :class="$vuetify.breakpoint.smAndDown ? 'mt-8 mr-n2' : 'mt-10'"
        >
          <v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 54" color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-toolbar> -->
      <v-card-text class="blue-grey lighten-5 rounded-b-lg">
        <v-row class="my-4">
          <v-col offset="1" cols="3" class="white rounded-lg d-flex align-center">
            <v-img
              :src="infoProductoObj.imagen"
            ></v-img>
          </v-col>
          <v-col offset="1" cols="7">
            <h1 class="text-capitalize">{{ infoProductoObj.nombreMostrar }}</h1>
            <v-row class="mt-4">
              <v-col cols="5" sm="4" md="3">Precio:</v-col>
              <v-col cols="7" sm="8" md="9">{{ infoProductoObj.precioMostrar }}€</v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="4" md="3">Impuestos:</v-col>
              <v-col cols="7" sm="8" md="9">{{ infoProductoObj.impPorcentaje }}%</v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="4" md="3">Descripción:</v-col>
              <v-col cols="7" sm="8" md="9">{{ infoProductoObj.descripcionMostrar }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="4" md="3">Código:</v-col>
              <v-col cols="7" sm="8" md="9">{{ infoProductoObj.codigoLector }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="4" md="3">Categoría:</v-col>
              <v-col cols="7" sm="8" md="9" class="text-capitalize">{{ infoProductoObj.categoria }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mostrarIdioma } from "@/utils/helper.utils.js";
export default {
  watch: {
    infoProductoDialogo(v) {
      if (!v) {
        this.$store.commit("setInfoProductoObj", {});
      }
    },
  },
  computed: {
    producto() {
      if (!!this.infoProductoObj) {
        return {
          ...this.infoProductoObj,
          //   nombre: mostrarIdioma(this.infoProductoObj.nombre),
        };
      } else {
        return {};
      }
    },
    ...mapState(["infoProductoObj"]),
    infoProductoDialogo: {
      get() {
        return this.$store.state.infoProductoDialogo;
      },
      set(v) {
        this.$store.commit("setInfoProductoDialogo", v);
      },
    },
  },
};
</script>

<style>
</style>