<template>
  <fullscreen-dialog
    name="internos/internos"
    titulo="Pedidos internos"
    :loading="loading"
  >
    <template v-slot:botones>
      <v-text-field
        v-if="paso == 'form'"
        dark
        hide-details
        style="background: #464646"
        class="mr-4 rounded-lg"
        label="Buscar articulo"
        outlined
        append-icon="mdi-magnify"
        v-model="busqueda"
      ></v-text-field>
    </template>
    <internos-listado v-if="paso == 'listado'" />
    <internos-form v-else-if="paso == 'form'" />
  </fullscreen-dialog>
</template>

<script>
import FullscreenDialog from "./FullscreenDialog.vue";
import InternosListado from "./InternosListado.vue";
import InternosForm from "./InternosForm.vue";
import { mapState } from "vuex";
export default {
  components: { FullscreenDialog, InternosForm, InternosListado },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    paso(v) {
      console.log(this.pedidoEnCurso);
      if (v == "listado") {
        // this.$store.commit("internos/resetPedidoEnCurso");
      }
    },
    internosDialog(v) {
      if (!v) {
        this.$store.commit("internos/setEditarProductoInternoObj", null);
        this.paso = "listado";
        this.pasoPidiendo = 0;
      } else {
        this.$store.dispatch("internos/getPedidos");
      }
    },
  },
  computed: {
    ...mapState({
      internosDialog: (state) => state.internos.internosDialog,
      categorias: (state) => state.internos.categorias,
      productos: (state) => state.internos.productos,
      pedidoEnCursoComprobar: (state) => state.internos.pedidoEnCursoComprobar,
      pedidoEnCurso: (state) => state.internos.pedidoEnCurso,
      editandoProductos: (state) => state.internos.editarProductoObj,
      // pasoPidiendo: (state) => state.internos.pasoPidiendo,
    }),
    busqueda: {
      get() {
        return this.$store.state.internos.busqueda;
      },
      set(v) {
        this.$store.commit("internos/setBusqueda", v);
      },
    },
    migasItems() {
      let aux = [
        {
          text: "Listado pedidos",
          disabled: this.paso == "",
          href: "",
        },
      ];
      if (this.paso == "form") {
        aux.push({
          text: "Categorías",
          disabled: this.paso == "",
          href: "",
        });
      }
      if (this.pasoPidiendo == 1) {
        aux.push({
          text: "Productos",
          disabled: this.paso == "",
          href: "",
        });
      }
      return aux;
    },
    paso: {
      get() {
        return this.$store.state.internos.paso;
      },
      set(v) {
        this.$store.commit("internos/SET_PASO", v);
      },
    },
    pasoPidiendo: {
      get() {
        return this.$store.state.internos.pasoPidiendo;
      },
      set(v) {
        this.$store.commit("internos/SET_PASO_PIDIENDO", v);
      },
    },
  },
  mounted() {},
};
</script>

<style>
</style>