<template>
  <div>
    <v-row v-if="!hideTextField">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="filtro.fid"
          label="ID"
          ref="fid"
          @click="focusCampo(filtro, 'fid')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="filtro.ftelefono"
          label="Teléfono"
          ref="ftelefono"
          @click="focusCampo(filtro, 'ftelefono')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="filtro.fnif"
          label="NIF / CIF"
          ref="fnif"
          @click="focusCampo(filtro, 'fnif')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="filtro.fapellidos"
          label="Apellidos"
          ref="fapellidos"
          @click="focusCampo(filtro, 'fapellidos')"
        />
      </v-col>
      <v-col v-if="!acciones" cols="12" md="6">
        <v-btn color="primary" class="black--text">Seleccionar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-slide-y-transition>
        <v-col cols="12" v-if="clientes.length > 0">
          <v-card elevation="2" class="rounded-lg mt-1">
            <v-card-text>
              <v-list :three-line="$vuetify.breakpoint.mdAndUp" class="pt-0">
                <template v-for="(item, index) in clientes">
                  <v-list-item
                    :key="item.id"
                    @click="direcciones(item)"
                    v-if="index < 3"
                    :class="index > 0 ? 'borde-separador' : ''"
                    class="py-1 px-0"
                  >
                    <v-list-item-avatar
                      v-if="item.avatar"
                      :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-3'"
                      class="my-auto"
                    >
                      <v-img
                        :width="$vuetify.breakpoint.mdAndUp ? '40' : '24'"
                        :src="item.avatar"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-icon
                      v-else
                      :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-3'"
                      class="my-auto"
                    >
                      <v-icon :x-large="$vuetify.breakpoint.mdAndUp">
                        mdi-account-circle
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="font-weight-light"
                        :class="
                          $vuetify.breakpoint.mdAndUp ? 'display-1' : 'body-1'
                        "
                        v-html="item.telefono"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.localNombre"
                        class="text-md-h6"
                      >
                        {{ item.localNombre }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon
                      v-if="
                        item.direccionesLocal &&
                        item.direccionesLocal.length > 0
                      "
                      :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-6'"
                      class="my-auto"
                    >
                      <v-icon :large="$vuetify.breakpoint.mdAndUp">
                        mdi-home
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="10"
          sm="5"
          md="5"
          v-else-if="filtro.ftelefono && clientes == 0"
          class="mt-16"
        >
          <v-card
            @click="nuevoCliente"
            class="rounded-lg ficha-generica"
            elevation="4"
            color="primary"
          >
            <v-responsive :aspect-ratio="1.618" class="align-center">
              <v-card-text
                class="text-uppercase text-center font-weight-black"
                :class="
                  $vuetify.breakpoint.mdAndDown ? 'headline px-1' : 'display-1'
                "
              >
                Nuevo cliente
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-col>
      </v-slide-y-transition>
      <v-spacer></v-spacer>
    </v-row>

    <!-- dialogo form direcciones-->
    <v-dialog
      v-model="direccionesFormDialog"
      max-width="800"
      overlay-opacity="0.90"
      content-class="mt-n4 elevation-0"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
    >
      <v-card class="transparent" ref="direccionesFormDialog">
        <v-card-title
          class="py-6 transparent"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-btn
            fab
            :height="$vuetify.breakpoint.mdAndUp ? 88 : 48"
            :width="$vuetify.breakpoint.mdAndUp ? 88 : 48"
            color="primary"
            @click="direccionesFormDialog = false"
            absolute
            right
            class="mt-10 mr-n4"
            ><v-icon :size="$vuetify.breakpoint.mdAndUp ? 54 : 36" color="black"
              >mdi-close</v-icon
            ></v-btn
          >
        </v-card-title>
        <v-card-title
          class="text--primary rounded-t-lg blue-grey lighten-5"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'caption'
              : $vuetify.breakpoint.mdAndDown
              ? 'title'
              : 'display-1'
          "
        >
          Formulario dirección {{ nombreClienteMostrar }}
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            fab
            height="48"
            width="48"
            color="primary"
            @click="direccionesFormDialog = false"
            absolute
            right
            class="mr-n2"
            :disabled="mostrarTeclado == true"
            ><v-icon size="36" color="black">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 blue-grey lighten-5">
          <v-alert
            color="warning"
            class="white--text"
            icon="mdi-alert"
            v-if="!direccionesForm.direccionLocal"
          >
            Esta dirección la añadió el usuario desde el portal web, y no se
            puede modificar
          </v-alert>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Calle"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.calle"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Número"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.numero"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="Información adicional"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.adicional"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="Municipio"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.municipio"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Código Postal"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.cp"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Provincia"
                v-model="direccionesForm.provincia"
                :disabled="!direccionesForm.direccionLocal"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <!--             <v-col cols="12" md="6">
              <v-text-field
                label="Pais"
                v-model="direccionesForm.pais"
                :disabled="!direccionesForm.direccionLocal"
                @input="direccionesFormCambio = true"
              />
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4 blue-grey lighten-5 rounded-b-lg">
          <v-spacer />
          <v-btn
            v-if="direccionesForm.direccionLocal"
            :disabled="!direccionesFormCambio"
            color="primary"
            class="rounded-lg black--text"
            @click="guardarDireccion"
            :loading="guardarBotonLoading"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialogo direcciones SI SIRVE-->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { key } from "firebase-key";
import ClientesComponent from "../components/ClientesComponent.vue";
export default {
  components: {
    ClientesComponent,
  },
  props: {
    acciones: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideTextField: {
      type: Boolean,
      required: false,
      default: false,
    },
    modoLista: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadingEliminar: false,
      clienteSeleccionado: [],

      guardarBotonLoading: false,
      // editarDialog: false,
      search: "",
      loading: false,
      headers: this.headersComputed,
      direccionesFormDialog: false,
      direccionesFormCambio: false,
      direccionesForm: {
        direccionLocal: false,
        id: null,
        calle: "",
        numero: "",
        adicional: "",
        municipio: "",
        cp: "",
        provincia: "",
        coordenadas: [],
        pais: "",
      },
      input: "",
      // editarCliente: null,
    };
  },
  watch: {
    direccionesFormDialog(v) {
      if (!v) {
        this.direccionesForm = {
          calle: "",
          numero: "",
          adicional: "",
          municipio: "",
          cp: "",
          provincia: "",
          coordenadas: [],
          pais: "",
        };
        this.direccionesFormCambio = false;
      } else {
        console.log("está en agregar direcciones");
      }
    },
    editarDialog(v) {
      console.log("seteado  " + v);
      if (!v && this.cambiandoCliente) {
        if (this.cambiandoCliente) {
          this.$store.commit("pedidos/setCobrarDialogFullscreen", true);

          if (!!this.pedidoEditando.cobrado) {
            this.$store.commit("pedidos/setPedidoDialog", false);
          } else {
            console.log(this.$store.state.pedidos.modoPantalla);
            this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
          }
          setTimeout(() => {
            this.$store.commit("pedidos/setCambiandoCliente", false);
          }, 1000);
        }
        // this.mostrarTeclado = false;
      } else if (!v && !this.estaPidiendoAgregandoClienteSinDirecciones) {
        this.editarCliente = null;
        // this.mostrarTeclado = false;
      } else {
      }
    },
    direccionesDialog(v) {
      if (!v && !this.estaPidiendoAgregandoClienteSinDirecciones) {
        this.editarCliente = null;
        this.direccionesFormDialog = false;
      }
    },
    filtro: {
      handler(v) {
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    nombreClienteMostrar() {
      if (this.editarCliente) {
        return this.editarCliente.nombreLocal
          ? this.editarCliente.nombreLocal
          : this.editarCliente.displayName
          ? this.editarCliente.displayName
          : "Sin nombre";
      } else {
        return "";
      }
    },
    mostrarTeclado: {
      get() {
        return this.$store.state.teclado.mostrarTeclado;
      },
      set(v) {
        console.log(v);
        if (!!v) {
          this.$store.dispatch("teclado/mostrarTeclado");
        } else {
          this.$store.dispatch("teclado/esconderTeclado");
        }
      },
    },
    editarDialog: {
      get() {
        return this.$store.state.clientes.dialogoClientes;
      },
      set(v) {
        this.$store.commit("clientes/setDialogoClientes", v);
      },
    },
    editarCliente: {
      get() {
        return this.$store.state.clientes.editarCliente;
      },
      set(v) {
        this.$store.commit("clientes/setEditarCliente", v);
      },
    },
    headersBase() {
      return [
        { text: "Nombre", value: "localNombre", filter: "", width: "25%" },
        { text: "Teléfono", value: "telefono", filter: "", width: "25%" },
        { text: "Email", value: "email", filter: "", width: "25%" },
      ];
    },
    headersComputed() {
      return this.acciones
        ? [
            ...this.headersBase,
            {
              sortable: false,
              text: "Acciones",
              value: "acciones",
              align: "right",
              width: "25%",
            },
          ]
        : this.headersBase;
    },
    ...mapState({
      clientesBruto: (state) => state.clientes.clientesArray,
      clientesFiltradosBruto: (state) => state.clientes.clientesFiltrados,
      filtro: (state) => state.clientes.filtro,
      pedidoEditando: (state) => state.pedidos.pedidoEditandoObj,
      cambiandoCliente: (state) => state.pedidos.cambiandoCliente,
      facturandoPedido: (state) => state.pedidos.facturandoPedido,
    }),
    clientes() {
      return this.clientesFiltradosBruto.map((c) => {
        return {
          ...c,
          localNombre: c.localNombre ? c.localNombre : c.displayName,
          telefono: c.phoneNumber ? c.phoneNumber : c.localTelefono,
        };
      });
    },
    estaPidiendoAgregandoClienteSinDirecciones() {
      return (
        this.editarCliente &&
        (!this.editarCliente.direccionesLocal ||
          (this.editarCliente.direccionesLocal &&
            this.editarCliente.direccionesLocal.length == 0)) &&
        (!this.editarCliente.direcciones ||
          (this.editarCliente.direcciones &&
            this.editarCliente.direcciones.length == 0))
      );
    },
    estaAgregandoUsuarioEnPedido() {
      return (
        !!this.editarCliente &&
        (this.pedidoEditando.modo == "mostrador" ||
          this.pedidoEditando.modo == "mesa")
      );
    },
  },
  methods: {
    focusCampo(obj, prop) {
      if (this.$store.state.necesarioTeclado) {
        this.$store.commit("teclado/setMostrarTeclado", true);
        //this.$store.commit("teclado/setLimpiarInput", true);

        this.$store.commit("teclado/setActualValor", obj[prop]);
        this.$store.commit("teclado/setCampoEditarObj", obj);
        this.$store.commit("teclado/setCampoEditarPropiedad", prop);
        setTimeout(() => {
          console.log(obj);
          console.log(prop);
          let pos = obj[prop].length ? obj[prop].length : 0;
          this.$refs[prop].$el
            .querySelector("input")
            .setSelectionRange(pos, pos);
        }, 200);
      }
    },
    focusTeclado(prop) {
      // this.$store.commit("teclado/setCampoEditarObj", this.editarCliente);
      // this.$store.commit("teclado/setCampoEditarPropiedad", prop);
      // this.$store.commit("teclado/setActualValor", this.editarCliente[prop]);
    },

    nuevaDireccionDialog() {
      this.direccionesForm.direccionLocal = true;
      this.direccionesFormDialog = true;
      // this.mostrarTeclado = true;
    },
    editDir(dir) {
      this.direccionesFormDialog = true;
      this.direccionesForm = JSON.parse(JSON.stringify(dir));
      // this.direccionesForm =
    },
    guardarDireccion() {
      if (!!this.direccionesForm.id) {
        //edita
        var direccionesLocalAux = this.editarCliente.direccionesLocal;
        let fI = direccionesLocalAux.findIndex(
          (d) => d.id == this.direccionesForm.id
        );
        if (fI > -1) {
          direccionesLocalAux[fI] = this.direccionesForm;
          this.guardarBotonLoading = true;
          this.$store
            .dispatch("clientes/saveDireccionesLocal", {
              idCliente: this.editarCliente.id,
              direccionesLocal: direccionesLocalAux,
            })
            .then((r) => {
              this.$store.commit("snackbar/setMensajeSuccess", {
                msj: "Dirección actualizada",
              });
              this.filtrar();
              this.editarCliente = JSON.parse(
                JSON.stringify(this.editarCliente)
              );
              this.direccionesFormDialog = false;
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("snackbar/setMensajeDanger", {
                msj: "Error al actualizar la dirección: " + e,
              });
            })
            .finally(() => (this.guardarBotonLoading = false));
        } else {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al actualiar la dirección",
          });
        }
      } else {
        //nueva dirección
        let centinelaSinDireccion =
          !!this.estaPidiendoAgregandoClienteSinDirecciones;
        var direccionesLocalAux = this.editarCliente.direccionesLocal
          ? this.editarCliente.direccionesLocal
          : [];
        var dirAdd = { ...this.direccionesForm, id: key() };
        direccionesLocalAux.push(dirAdd);

        this.guardarBotonLoading = true;
        console.log(this.editarCliente);
        this.$store
          .dispatch("clientes/saveDireccionesLocal", {
            idCliente: this.editarCliente.id,
            direccionesLocal: direccionesLocalAux,
          })
          .then((r) => {
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Dirección añadida",
            });

            this.filtrar();
            if (centinelaSinDireccion) {
              this.elegirDireccion(dirAdd);
            }
            this.editarCliente.direccionesLocal = direccionesLocalAux;
            this.direccionesFormDialog = false;
          })
          .catch((e) => {
            console.log(e);
            this.$store.commit("snackbar/setMensajeDanger", {
              msj: "Error al añadir la dirección: " + e,
            });
          })
          .finally(() => (this.guardarBotonLoading = false));
        // this.$store.dispatch("clientes/getCliente",this.editarCliente.id)
      }
    },
    filaClicked(item, data) {
      if (!this.acciones) {
        this.clienteSeleccionado = [item];
      }
    },
    filtrar() {
      this.loading = true;
      this.$store
        .dispatch("clientes/filtrarClientes")
        .finally(() => (this.loading = false));
    },

    nuevoCliente() {
      let nuevo = JSON.parse(
        JSON.stringify(this.$store.state.clientes.clienteNuevo)
      );
      nuevo.restaurantes.push(this.$store.state.restauranteObj.id);
      nuevo.localTelefono = this.filtro.ftelefono;
      this.$store.commit("clientes/setClienteDialog", true);
      this.$store.commit("clientes/setPasoCliente", "editarCliente");
      // this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
      this.$store.commit("clientes/setEditarCliente", nuevo);
      // this.mostrarTeclado = true;
    },
    direcciones(item) {
      if (!this.modoLista) {
        //funcionamiento dentro de pedidos
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "uid",
          value: item.id,
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "copyUser",
          value: {
            ...item,
            displayName: item.localNombre
              ? item.localNombre
              : item.displayName
              ? item.displayName
              : "",
            phoneNumber: item.localTelefono
              ? item.localTelefono
              : item.phoneNumber
              ? item.phoneNumber
              : "",
          },
        });
        this.editarCliente = item;
        if (this.facturandoPedido) {
          this.$store.commit("clientes/setPasoCliente", "facturar");
        } else {
          // this.$store.commit("clientes/setClienteDialog", false);
        }

        if (
          !!this.cambiandoCliente &&
          !!this.$store.state.pedidos.cobrarDialog
        ) {
          //eestá en cobrarr, hay quee guardar el cliieente
          this.$store.dispatch("pedidos/savePedido", this.pedidoEditando);
        } else if (this.pedidoEditando.modo == "domicilio") {
          // this.direccionesDialog = true;
          console.log(item);
          console.log(this.editarCliente);
          if (this.estaPidiendoAgregandoClienteSinDirecciones) {
            // this.nuevaDireccionDialog();
            this.$store.commit("clientes/setDireccionesDialog", true);
          } else {
            this.$store.commit("clientes/setDireccionesDialog", true);
          }
        } else if (
          this.pedidoEditando.modo == "recoger" &&
          !this.pedidoEditando.id
        ) {
          //está agregando un pedido recoger
          this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
          this.$store.commit("pedidos/setPedidoDialog", true);
          this.$store.commit("clientes/setClienteDialog", false);
        } else {
          this.$store.commit("clientes/setClienteDialog", false);
        }
        this.$store.commit("pedidos/setAbriendoNoGrabar", false);

        // this.$store.dispatch("pedidos/savePedido", this.pedidoEditando);
      } else {
        this.$store.commit("clientes/setClienteDialog", true);
        this.$store.commit(
          "clientes/setEditarCliente",
          JSON.parse(JSON.stringify(item))
        );
        this.$store.commit("clientes/setPasoCliente", "formDatos");
      }
    },
    borrarDireccion(item) {
      var confirm = false;
      var fI;
      this.$confirm(
        "¿Desea eliminar la dirección? Esta acción es irreversible",
        {
          buttonTrueText: "Aceptar",
          buttonFalseText: "No",
          color: "warning",
          icon: "warning",
          width: 350,
        }
      )
        .then((r) => {
          if (r) {
            confirm = true;
            this.loadingEliminar = true;
            var direccionesLocalAux = JSON.parse(
              JSON.stringify(this.editarCliente.direccionesLocal)
            );
            fI = direccionesLocalAux.findIndex((d) => d.id == item.id);
            if (fI > -1) {
              direccionesLocalAux[fI] = this.direccionesForm;
              direccionesLocalAux.splice(fI, 1);
              return this.$store.dispatch("clientes/saveDireccionesLocal", {
                idCliente: this.editarCliente.id,
                direccionesLocal: direccionesLocalAux,
              });
            }
          }
        })
        .then(() => {
          if (confirm) {
            this.filtrar();
            this.editarCliente.direccionesLocal.splice(fI, 1); //para que sea reactivo en el modal
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Dirección eliminada",
            });
          }
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al eliminar la dirección " + e,
          });
        })
        .finally(() => {
          this.loadingEliminar = false;
        });
    },
    ...mapActions({}),
    contactar() {},
    getClientes() {
      this.loading = true;
      this.$store
        .dispatch("clientes/getClientes")
        .finally(() => (this.loading = false));
    },
  },
  beforeDestroy() {
    this.$store.commit("setMostrarFabAdd", true);
  },
  mounted() {
    this.$store.commit("setMostrarFabAdd", false);
    this.getClientes();
    if (!!this.telefonoProps) {
      this.ftelefono = this.telefonoProps;
    }
  },
};
</script>


<style lang="scss" scoped>
.borde-separador {
  border-top: 1px solid rgba(0, 0, 0, 0.24);
}
.theme--light.v-btn.v-btn--disabled {
  background-color: #cfd2d4 !important;
}
</style>

<style lang="scss">
.mostrando-teclado--dialog {
  &:not(.v-dialog--fullscreen) {
    max-height: calc(100vh - 210px);
    align-self: flex-start;
  }
}
</style>