import state from "../directorio/state"

export default {
    getUsuarios({ state }) {

    },
    setUsuarioActual({ state, commit }, id) { //da eel id de usuario parra gesetsión y busca el objeto
        let f = state.usuarios.find(u => u.id == id)
        if (f) {
            commit('setUsuarioActual', f)
        } else {
            commit('setUsuarioActual', null)
        }
    }
}