var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"mb-4 mt-2 display-1 text-center"},[_vm._v("Pedidos Internos")]),_c('v-data-table',{staticClass:"elevation-1 rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.pedidos,"item-key":"name","loading":_vm.loading,"search":_vm.search,"sort-by":"numPedidoInternos","sort-desc":true},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.estado.text))]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [(item.estado.value == 'realizando')?_c('td',[_c('v-row',{staticClass:"d-flex flex-row-reverse"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-2 ml-1",attrs:{"color":"primary","min-width":"0","icon":""},on:{"click":function($event){return _vm.goAdd(item)}}},on),[_c('v-icon',{attrs:{"big":""}},[_vm._v("mdi-arrow-expand-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Continuar")])])],1)],1):_c('td',[_c('v-row',{staticClass:"d-flex flex-row-reverse"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-2 ml-1",attrs:{"color":"black","min-width":"0","icon":""},on:{"click":function($event){return _vm.goView(item)}}},on),[_c('v-icon',{attrs:{"big":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }