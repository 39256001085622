<template>
  <v-dialog
    v-model="accionesDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    max-width="600"
    scrollable
    :content-class="
      $vuetify.breakpoint.mdAndUp
        ? 'mt-n4 dialogo--custom-fs elevation-0'
        : 'dialogo--custom-fs'
    "
  >
    <v-card
      v-if="pedidoEditandoObj"
      class="transparent"
      :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
    >
      <v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="py-6 transparent">
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
          color="primary"
          @click="accionesDialog = false"
          absolute
          right
          :class="$vuetify.breakpoint.smAndDown ? 'mt-16' : 'mt-10 mr-n4'"
          ><v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 54" color="black"
            >mdi-close</v-icon
          ></v-btn
        ></v-card-title
      >
      <v-card-title
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'caption'
            : $vuetify.breakpoint.mdAndDown
            ? 'body-2'
            : 'body-1'
        "
        class="text--primary rounded-t-lg blue-grey lighten-5"
        style="height: 78px"
      >
        <v-icon color="info" class="mr-4"> mdi-information-outline </v-icon>
        <div>
          <span class="font-weight-bold">
            ACCIONES PEDIDO - {{ tituloPidiendo }} {{ numeroPidiendo }}
          </span>
        </div>
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          fab
          height="48"
          width="48"
          color="primary"
          @click="accionesDialog = false"
          absolute
          right
          class="mr-n2 mt-n1"
          ><v-icon size="36" color="black">mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="pt-4 blue-grey lighten-5"
        :class="
          pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
            ? 'rounded-0'
            : 'rounded-b-lg'
        "
      >
        <v-btn icon x-large @click="pararEnCocina()">
          <v-icon color="yellow darken-4" big>mdi-move</v-icon>
        </v-btn>
        <v-btn
          v-if="pedidoEditandoObj.estadoCocina != 'parado'"
          icon
          x-large
          @click="pararEnCocina()"
        >
          <v-icon color="yellow darken-4" big>mdi-pause</v-icon>
        </v-btn>

        <v-btn
          v-if="pedidoEditandoObj.estadoCocina == 'parado'"
          icon
          x-large
          @click="reanudarEnCocina()"
        >
          <v-icon color="green darken-4" big>mdi-play</v-icon>
        </v-btn>
      </v-card-text>
      <template
        v-if="
          pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
        "
      >
        <v-divider></v-divider>
        <v-card-actions
          class="py-3 blue-grey lighten-5"
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : 'rounded-0'"
        >
          <v-row>
            <v-col
              v-if="pedidoEditandoObj.fechaMostrar"
              cols="12"
              sm="6"
              class="pb-0 pb-sm-3"
            >
              <v-icon size="18" class="mr-2 mt-n1"> mdi-clock-outline </v-icon
              >Registrado a las
              <span class="font-weight-bold">
                &nbsp;{{ pedidoEditandoObj.fechaMostrar }}
              </span>
            </v-col>
            <v-col
              v-if="
                pedidoEditandoObj.copyUsuarioTpv &&
                pedidoEditandoObj.copyUsuarioTpv.nombre
              "
              cols="12"
              sm="6"
              :offset-sm="!pedidoEditandoObj.fechaMostrar ? 6 : 0"
              class="text-sm-right pt-0 pt-sm-3"
            >
              <v-icon
                v-if="$vuetify.breakpoint.xsOnly"
                size="18"
                class="mr-2 mt-n1"
              >
                mdi-account-box </v-icon
              >Atendido por
              <span class="font-weight-bold">
                &nbsp;{{ pedidoEditandoObj.copyUsuarioTpv.nombre }}
              </span>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    tituloPidiendo() {
      var res = "";
      if (!!this.pedidoEditandoObj) {
        if (this.pedidoEditandoObj.modoCopy)
          return this.pedidoEditandoObj.modoCopy.nombre;
        switch (this.pedidoEditandoObj.modo) {
          case "domicilio":
            res = "DOMICILIO";
            break;
          case "recoger":
            res = "RECOGER";
            break;
          case "mesa":
            res = "MESA " + this.pedidoEditandoObj.copyMesa.nombre;
            break;
          case "mostrador":
            res = "MOSTRADOR ";
            break;
        }
      }
      return res;
    },
    numeroPidiendo() {
      return this.pedidoEditandoObj
        ? this.pedidoEditandoObj.numPedidoRestDia
        : "-";
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      /* set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      }, */
    },
    accionesDialog: {
      get() {
        return this.$store.state.pedidos.accionesDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setAccionesDialog", v);
      },
    },
  },
  methods: {
    pararEnCocina() {
      this.$store.dispatch("pedidos/pararEnCocina", this.pedidoEditandoObj);
    },
    reanudarEnCocina() {
      this.$store.dispatch("pedidos/reanudarEnCocina", this.pedidoEditandoObj);
    },
  },
};
</script>
