export default {
    SET_CASHGUARD_DIALOG(state, b) {
        state.cashguardDialog = b;
    },
    SET_FICHERO_CASHGUARD(state, s) {
        state.ficheroCashguard = s;
    },
    SET_FICHERO_CASHGUARD_RESULTADO(state, s) {
        state.ficheroCashguardResultado = s;
    },
    SET_CASHGUARD_BOTTOM(state, b) {
        state.cashguardBottom = b;
    },
    SET_ACCION_SIGUE(state, s) {
        state.accionSigue = s;
    },
    SET_FICHERO_CASHGUARD_CONTENIDO_FICHERO(state, s) {
        state.ficheroCashguardContenidoFichero = s; //el conetenido del fichero con al respuesta de CG
    }

}