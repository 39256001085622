<template>
  <v-snackbar
    centered
    right
    v-model="cargandoLazySnack"
    color="primary"
    :timeout="0"
  >
    <v-card color="primary" flat light>
      <v-card-title>CARGANDO</v-card-title>
      <v-card-text>
        <v-list dense color="transparent" light>
          <v-list-item v-for="(c, i) in cargaLazy" :key="i">
            <v-list-item-title>{{ c.nombre }} </v-list-item-title>
            <v-list-item-action>
              <v-icon v-if="!c.loading" color="green">mdi-check</v-icon>
              <v-progress-circular
                v-else
                size="20"
                width="2"
                indeterminate
                color="grey darken-4"
              ></v-progress-circular>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      cargaLazy: "cargaLazy",
    }),
    cargandoLazySnack: {
      get() {
        return this.$store.state.cargandoLazySnack;
      },
      set(v) {
        this.$store.commit("setCargandoLazySnack", v);
      },
    },
  },
};
</script>

<style>
</style>