export default {
  setClientes(state, c) {
    state.clientesArray = c;
  },
  setClientesFiltrados(state, c) {
    state.clientesFiltrados = c;
  },
  setFiltroKey(state, payload) {
    console.lg;
    state.filtro[payload.key] = payload.value;
  },
  resetFiltros(state) {
    state.filtro = {
      fid: "",
      ftelefono: "",
      fnif: "",
      fapellidos: "",
    };
  },
  setDialogoClientes(state, b) {
    //dpreciar
    state.dialogoClientes = b;
  },
  setEditarCliente(state, c) {
    state.editarCliente = c;
  },
  setClienteDialog(state, b) {
    state.clienteDialog = b;
  },
  setDireccionesDialog(state, b) {
    state.direccionesDialog = b;
  },
  setPasoCliente(state, s) {
    state.pasoCliente = s;
  },
  setFullscreenClienteDialog(state, b) {
    state.clienteDialogFullscreen = b;
  },
  setHistorialPedidosDialog(state, b) {
    state.historialPedidosDialog = b;
  },
  setHistorialPedidos(state, a) {
    state.historialPedidos = a;
  },
};
