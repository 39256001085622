<template>
  <!--datos de cliente que está realizando el pedido-->
  <v-card flat>
    <v-card-title>DIRECCIÓN</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field label="Calle" readonly v-model="direccion.calle" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Adicional"
            readonly
            v-model="direccion.adicional"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Municipio"
            readonly
            v-model="direccion.municipio"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field label="Código Postal" readonly v-model="direccion.cp" />
        </v-col>
        <v-col cols="12">
          <div class="mx-n4">
            <GmapMap
              v-if="!!coordenadas"
              :center="coordenadas"
              :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                gestureHandling: 'none',
              }"
              :zoom="16"
              map-type-id="terrain"
              style="width: 100%; height: 180px"
            >
              <GmapMarker
                :position="coordenadas"
                @click="center = coordenadas"
              />
            </GmapMap>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    coordenadas() {
      console.log(this.direccion.coordenadas);
      return this.direccion.coordenadas &&
        this.direccion.coordenadas.length == 2
        ? {
            lat: this.direccion.coordenadas[0],
            lng: this.direccion.coordenadas[1],
          }
        : false;
    },
    pedido() {
      return this.$store.state.pedidos.pedidoEditandoObj;
    },
    direccion() {
      console.log(this.pedido.direccion);
      return this.pedido && this.pedido.direccion ? this.pedido.direccion : {};
    },
  },
};
</script>

<style>
</style>