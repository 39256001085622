import { db } from "@/firebase";
import { restSinNegocio } from "@/utils/restaurantes.utils.js";

export default {
  // getClientes({ commit, rootState }) {
  //   var f = functions.httpsCallable('httpTpvGetClientes');
  //   return f().then((r) => {
  //     commit('setClientes', r.data);
  //   })
  // },
  getClientes({ commit, rootState, state, rootGetters }) {
    return new Promise((resolve, reject) => {
      // resolve("hola antes")
      var resolveOnce = (doc) => {
        // resolveOnce = () => void
        resolve(doc);
      };
      var ref = db
        .collection("negocios")
        .doc(rootState.negocioObj.id)
        .collection("clientes");
      if (rootState.configuracionTpv.clientePorRestaurante) {
        let rest = rootState.restauranteObj.id;
        ref = ref.where("restaurantes", "array-contains", rest);
      }
      ref.onSnapshot((r) => {
        commit(
          "setClientes",
          r.docs.map((c) => {
            return {
              ...c.data(),
              id: c.id,
            };
          })
        );
        resolveOnce(true);
      }, reject);
    });
  },
  getCliente({ state }, id) {
    return new Promise((res, rej) => {
      let f = state.clientesArray.find((c) => c.id === id);
      res(f);
    });
  },
  filtrarClientes({ state, commit }) {
    commit(
      "setClientesFiltrados",
      state.clientesArray.filter((c) => {
        return (
          (state.filtro.ftelefono &&
            state.filtro.ftelefono.trim().length > 0 &&
            c &&
            c.phoneNumber &&
            c.phoneNumber.trim().length > 0 &&
            c.phoneNumber.includes(state.filtro.ftelefono)) ||
          (state.filtro.ftelefono &&
            state.filtro.ftelefono.trim().length > 0 &&
            c &&
            c.localTelefono &&
            c.localTelefono.trim().length > 0 &&
            c.localTelefono.includes(state.filtro.ftelefono)) ||
          (state.filtro.fapellidos &&
            state.filtro.fapellidos.trim().length > 0 &&
            c &&
            c.localApellido1 &&
            c.localApellido1.trim().length > 0 &&
            c.localApellido1.includes(state.filtro.fapellidos)) ||
          (state.filtro.fapellidos &&
            state.filtro.fapellidos.trim().length > 0 &&
            c &&
            c.localApellido2 &&
            c.localApellido2.trim().length > 0 &&
            c.localApellido2.includes(state.filtro.fapellidos)) ||
          (state.filtro.fid &&
            state.filtro.fid.trim().length > 0 &&
            c &&
            c.id &&
            c.id.trim().length > 0 &&
            c.id.includes(state.filtro.fid)) ||
          (state.filtro.fnif &&
            state.filtro.fnif.trim().length > 0 &&
            c &&
            c.nif &&
            c.nif.trim().length > 0 &&
            c.nif.includes(state.filtro.fnif))
        );
      })
    );
    return;
  },
  saveCliente({ rootState }, payload) {
    var res;
    if (payload.id) {
      res = db
        .collection("negocios")
        .doc(rootState.negocioObj.id)
        .collection("clientes")
        .doc(payload.id)
        .set(payload, { merge: true })
        .then(() => payload);
    } else {
      res = db
        .collection("negocios")
        .doc(rootState.negocioObj.id)
        .collection("clientes")
        .add(payload);
    }
    if (rootState.onlineComputadora) return res;
  },
  saveDireccionesLocal({ rootState }, payload) {
    var res = db
      .collection("negocios")
      .doc(rootState.negocioObj.id)
      .collection("clientes")
      .doc(payload.idCliente)
      .set({ direccionesLocal: payload.direccionesLocal }, { merge: true });
    if (rootState.onlineComputadora) return res;
  },
  buscarClienteEditar({ state, commit }, uid) {
    commit(
      "setEditarCliente",
      state.clientesArray.filter((c) => c.id == uid)[0]
    );
  },
  getHistorialPedidos({ state, rootState, commit }, uid) {
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    return (
      db
        .collection("pedidos")
        .where("uid", "==", uid)
        .where("restaurante", "==", restSin)
        .where("negocio", "==", negocio)
        // .where("estado", "==", "tpv-realizando")
        .get()
        .then((r) => {
          return r.docs
            .map((p) => {
              return {
                id: p.id,
                ...p.data(),
              };
            })
            .filter(
              (p) => p.estado == "finalizado" || p.estado == "tpv-realizando"
            )
            .sort((a, b) => b.fechaServidor.seconds - a.fechaServidor.seconds);
        })
        .then((r) => {
          commit("setHistorialPedidos", r);
        })
    );
  },
};
