const qz = require("qz-tray");
import { db, functions } from "@/firebase";

qz.security.setCertificatePromise(function (resolve, reject) {
  //Preferred method - from server
  //        fetch("assets/signing/digital-certificate.txt", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
  //          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });

  //Alternate method 1 - anonymous
  //        resolve();  // remove this line in live environment

  //Alternate method 2 - direct

  resolve(
    "-----BEGIN CERTIFICATE-----\n" +
      "MIIEGzCCAwOgAwIBAgIUByEF+BmzkoSrTBOJKa5vgyy2lkgwDQYJKoZIhvcNAQEL\n" +
      "BQAwgZsxCzAJBgNVBAYTAkVTMRAwDgYDVQQIDAdFU1BBwqVBMQ8wDQYDVQQHDAZN\n" +
      "QUxBR0ExGDAWBgNVBAoMD01FV08gU1lTVEVNUyBTTDEQMA4GA1UECwwHRlVORUFU\n" +
      "UzEYMBYGA1UEAwwPbWV3b3N5c3RlbXMuY29tMSMwIQYJKoZIhvcNAQkBFhRpbmZv\n" +
      "QG1ld29zeXN0ZW1zLmNvbTAgFw0yMzAzMDEwNTMwMTBaGA8yMDU0MDgyNDA1MzAx\n" +
      "MFowgZsxCzAJBgNVBAYTAkVTMRAwDgYDVQQIDAdFU1BBwqVBMQ8wDQYDVQQHDAZN\n" +
      "QUxBR0ExGDAWBgNVBAoMD01FV08gU1lTVEVNUyBTTDEQMA4GA1UECwwHRlVORUFU\n" +
      "UzEYMBYGA1UEAwwPbWV3b3N5c3RlbXMuY29tMSMwIQYJKoZIhvcNAQkBFhRpbmZv\n" +
      "QG1ld29zeXN0ZW1zLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEB\n" +
      "AM3cRa+WrQL438e1BV2TuVl+GE59FS1iyhPkG8zv4GnmzyWjXnijyRnlhir/fE+H\n" +
      "DTyFk7NRRvuIoXXKrLkcdcb8pSXP1bXzTYqrBwIH9qDNBwcG/VUpCU6y9xe7uy9V\n" +
      "GvtT4VQOlGrEJCIFb7mq4JVz3vEMXwcZs3sMm4VlSOIsFqEuhxvA6YgMDh2vV85J\n" +
      "lB00evsAHt9JxNyOnrXxmzp9RytCCU5BxMv0ZiZAgFmKH4pZLwaUFhqxDR7Uh4bZ\n" +
      "lagygzaX7+8p/vPKURntfcl3U9AaIkgOKEA9yLptQp18j+R28P/kJRYXzd542oNG\n" +
      "yjIrOys7F+MM7aMq7AXqygsCAwEAAaNTMFEwHQYDVR0OBBYEFOHsQzDYY3TMzTgh\n" +
      "bLdfFdJ7ijDlMB8GA1UdIwQYMBaAFOHsQzDYY3TMzTghbLdfFdJ7ijDlMA8GA1Ud\n" +
      "EwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBAA2cmXz2gpdD+r5aD4TvLsKj\n" +
      "ZvIQhRUoImUuLKLIUqXzVuqrq7zQnc9GR2KXutUYvJU9SuAW5XXArvT9GYeTOQvE\n" +
      "jAg2/IiVY3zp/V5Ee7RSk9Bwru6pd3m1BhzhY6o9i2f578Tgy0+LZ8dGmAroTCEC\n" +
      "9ond7afJMlsHSYkdoSTYn3umIvr4nnwFN6TVdPGY0Xq1uDKUJYaZAmWI9dbe6Jsp\n" +
      "z1t3RXufLaIT1elDFJRCNl5m+tkapUXCnY56a2U4jYlHD6b2hizx1G01BDwOOhcW\n" +
      "TdmtA7CH2DIIL7yb3ISbN6oBfO+vsNIuIk6gKK2ActJsJFV6cxr+0sOO1Vrp/Y4=\n" +
      "-----END CERTIFICATE-----"
  );
});

qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1

function launchQZ() {
  if (!qz.websocket.isActive()) {
    window.location.assign("qz:launch");
    //Retry 5 times, pausing 1 second between each attempt
    startConnection({ retries: 5, delay: 1 });
  }
}

function startConnection(config) {
  let host = "";
  let usingSecure = true;
  // Connect to a print-server instance, if specified
  if (host != "" && host != "localhost") {
    if (config) {
      config.host = host;
      config.usingSecure = usingSecure;
    } else {
      config = { host: host, usingSecure: usingSecure };
    }
  }
  if (!qz.websocket.isActive()) {
    // updateState('Waiting', 'default');

    return qz.websocket
      .connect(config)
      .then(function () {
        // updateState('Active', 'success');
        // findVersion();
      })
      .catch(handleConnectionError);
  } else {
    // displayMessage('An active connection with QZ already exists.', 'alert-warning');
  }
}

function endConnection() {
  if (qz.websocket.isActive()) {
    qz.websocket
      .disconnect()
      .then(function () {
        // updateState('Inactive', 'default');
      })
      .catch(handleConnectionError);
  } else {
    // displayMessage('No active connection with QZ exists.', 'alert-warning');
  }
}

function handleConnectionError(err) {
  console.log(err);
  // updateState('Error', 'danger');

  // if (err.target != undefined) {
  //     if (err.target.readyState >= 2) { //if CLOSING or CLOSED
  //         displayError("Connection to QZ Tray was closed");
  //     } else {
  //         displayError("A connection error occurred, check log for details");
  //         console.error(err);
  //     }
  // } else {
  //     displayError(err);
  // }
}

export default {
  conectar({ rootState, dispatch, commit }) {
    qz.file.setFileCallbacks(async function (streamEvent) {
      if (streamEvent.type !== "ERROR") {
        console.log(streamEvent);
        console.log("Type: " + streamEvent.eventType);
        console.log("File: " + streamEvent.file);
        let arrayAux = streamEvent.file.split("\\");
        let fichero = arrayAux[arrayAux.length - 1];
        console.log(
          "Fichero: " +
            fichero +
            " Fichero en estado: " +
            rootState.cashguard.ficheroCashguard
        );
        let ficheroPartido = fichero.split(".");
        let ficheroEstadoPartido =
          rootState.cashguard.ficheroCashguard.split(".");
        if (ficheroPartido[0] == ficheroEstadoPartido[0]) {
          let contenidoFichero = await dispatch("leerFichero", fichero);
          console.log(contenidoFichero);
          commit(
            "cashguard/SET_FICHERO_CASHGUARD_RESULTADO",
            ficheroPartido[1],
            {
              root: true,
            }
          );
          commit(
            "cashguard/SET_FICHERO_CASHGUARD_CONTENIDO_FICHERO",
            contenidoFichero,
            {
              root: true,
            }
          );
        }
        // Data is optional, toggled off by setting params to null
        console.log(
          "Data: " + streamEvent.fileData ? streamEvent.fileData : "<none>"
        );
      } else {
        console.error(streamEvent.message);
      }
    });
    return launchQZ();
  },
  getPrinters({ rootState, rootGetters }) {
    console.log(rootGetters.cuenta);
    return qz.printers.find().then((r) => {
      console.log(r);
      let id = rootGetters.cuenta.id;
      let obj = {};
      obj[id] = r;
      return db
        .collection("restaurantes")
        .doc(rootState.auth.user.restauranteTpv)
        .collection("tpv")
        .doc("secreto")
        .collection("dispositivosqz")
        .doc("impresoras")
        .set(obj, {
          merge: true,
        });
    });
  },
  async imprimirTicket({ dispatch }, { data, impresora, logo, factura }) {
    // var config = qz.configs.create("Xprinter USB Printer P");
    if (!qz.websocket.isActive()) {
      await dispatch("conectar");
    }
    console.log(impresora);
    var config = qz.configs.create(impresora);
    console.log(config);
    // let r = data.map(d => {
    //     return new TextDecoder().decode([d])
    // })
    // let r = new TextDecoder()
    //   .decode(data)
    //   .split("\n")
    //   .map((r) => {
    //     return r + "\n";
    //   });
    // console.log(r);
    var imprimir = data;
    console.log(data);

    return qz.print(config, imprimir).catch(function (e) {
      console.error(e);
    });
  },
  devices() {
    return qz.networking.devices().then((r) => {
      console.log(r);
    });
  },
  escribirFichero({}, { fichero, ruta }) {
    return qz.file
      .write("C:/ImaCG_Logic/Integration/" + ruta, {
        data: fichero,
        sandbox: false,
        shared: true,
      })
      .then((r) => {
        console.log(r);
        return r;
      });
  },
  leerFichero({}, ruta) {
    return qz.file
      .read("C:/ImaCG_Logic/Integration/" + ruta, {
        sandbox: false,
        shared: true,
      })
      .then((r) => {
        console.log(r);
        return r;
      });
  },
  listarFicheros({}) {
    return qz.file
      .list("C:/ImaCG_Logic/Integration", { sandbox: false, shared: true })
      .then((r) => {
        console.log(r);
        return r;
      });
  },
  escucharCambios({ rootState }) {
    return qz.file
      .startListening("C:/ImaCG_Logic/Integration")
      .then((r) => {
        console.log(r);
        console.log(rootState.cashguard.ficheroCashguard);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  pararTodasEscucha() {
    return qz.file.stopListening().then((r) => {
      console.log(r);
    });
  },
};
