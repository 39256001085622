<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    content-class="mt-n4 dialogo--custom-fs"
    v-model="salidaDineroDialog"
    scrollable
    max-width="912"
  >
    <v-card class="transparent">
      <v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="py-6 transparent">
      </v-card-title>
      <v-card-title
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'caption'
            : $vuetify.breakpoint.mdAndDown
            ? 'title'
            : 'display-1'
        "
        class="text--primary rounded-t-lg blue-grey lighten-5"
        >SALIDA DE DINERO
      </v-card-title>
      <v-card-subtitle
        class="blue-grey lighten-5 body-1 font-weight-light py-2"
      >
        <!-- Inicio del día de curro -->
        Hora: <strong>{{ inicioMostrar }}</strong>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="pt-4 blue-grey lighten-5 border">
        <!--         <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field readonly label="Inicio" :value="inicioMostrar" />
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="8" class="flex-grow-1">
            <v-list class="rounded-lg" style="min-height: 100%">
              <v-list-item class="my-4">
                <v-row class="ma-0">
                  <v-col cols="6">
                    <v-list-item-title>
                      {{ cuenta.nombre }}
                    </v-list-item-title>
                  </v-col>
                  <v-col
                    cols="3"
                    class="text-center rounded-lg"
                    :class="
                      campoCambiando == 'efectivo_' + cuenta.id
                        ? 'green lighten-3'
                        : 'grey lighten-3'
                    "
                    @click="cambiarSeleccion(cuenta.id)"
                  >
                    <div class="font-weight-bold">EFECTIVO</div>
                    <div class="font-weight-bold text-h5 success--text">
                      {{ cantidadMostrar }}
                    </div>
                  </v-col>
                  <!--<v-col
                    cols="3"
                    class="text-center rounded-lg"
                    :class="
                      campoCambiando == 'tarjeta' + cuenta.id
                        ? 'green lighten-3'
                        : 'grey lighten-3'
                    "
                    @click="cambiarSeleccion(c.id)"
                  >
                    <div class="font-weight-bold">TARJETA</div>
                    <div class="font-weight-bold text-h5 success--text">
                      {{ cuenta.tarjetaMostrar }}
                    </div>
                  </v-col>-->
                </v-row>
              </v-list-item>
              <v-list-item
                ><v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="COMENTARIO"
                      v-model="salidaDinero.comentario"
                  /></v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            class="flex-grow-1"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <KeyboardNumerico
              keyboardClass="keyboard-salida-dinero"
              noDividir
              ref="keyboardRefNumerico"
              @onChange="onChange"
              @onKeyPress="onKeyPress"
            />
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col cols="6" md="3" @click="$refs.subirImagen.$refs.input.click()">
            <v-container style="height: 100%" v-if="ficheroLoading">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  Subiendo fichero
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="primary accent-4"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <v-hover v-slot:default="{ hover }" v-else>
              <v-card
                elevation="4"
                outlined
                class="rounded-lg"
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'grey lighten-4' : 'white'"
              >
                <v-responsive aspect-ratio="1.618" class="align-center">
                  <v-list-item-content>
                    <div class="text-center pb-6">
                      <v-icon
                        class="my-4"
                        color="teal darken-4"
                        :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                        >mdi-camera</v-icon
                      >
                    </div>
                  </v-list-item-content>
                  <v-card-actions
                    class="grey lighten-4 acciones-footer acciones--border-top"
                  >
                    <span
                      class="
                        text-uppercase
                        mx-auto
                        font-weight-bold
                        teal--text
                        text--darken-4
                        subtitle-2
                      "
                      >Foto</span
                    >
                  </v-card-actions>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <v-col
            cols="6"
            md="3"
            v-for="(f, i) in salidaDinero.ficheros"
            :key="f.nombre + '_' + i"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                elevation="4"
                outlined
                class="rounded-lg"
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'grey lighten-4' : 'white'"
              >
                <v-responsive aspect-ratio="1.618" class="align-center">
                  <v-list-item-content>
                    <div class="text-center pb-6">
                      <v-icon
                        class="my-4"
                        color="teal darken-4"
                        :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                        >mdi-file-pdf</v-icon
                      >
                    </div>
                  </v-list-item-content>
                  <v-card-actions
                    class="grey lighten-4 acciones-footer acciones--border-top"
                  >
                    <span
                      class="
                        text-uppercase
                        mx-auto
                        font-weight-bold
                        teal--text
                        text--darken-4
                        subtitle-2
                      "
                      >{{ f.nombre }}</span
                    >
                  </v-card-actions>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>

          <v-file-input
            v-model="fichero"
            ref="subirImagen"
            dense
            class="d-none"
            hide-details
            outlined
            label="Subir imágenes"
            append-icon="mdi-map-marker"
            @change="cambioImagen"
          />
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="rounded-b-lg blue-grey lighten-5">
        <v-checkbox
          v-if="false"
          label="Generar factura gasto"
          x-large
          v-model="salidaDinero.generarFactura"
        />
        <v-spacer />
        <v-btn
          @click="addSalida"
          large
          :loading="salidaDineroLoading"
          color="primary"
          class="black--text rounded-lg"
          >AÑADIR SALIDA</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyboardNumerico from "./KeyboardNumerico";
import { mostrarMoneda, isFloat } from "@/utils/helper.utils.js";

import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
  components: { KeyboardNumerico },
  data() {
    return {
      fichero: null,
      ficheroLoading: false,
      salidaDineroLoading: false,
      campoCambiando: null,
      salidaDinero: {
        generarFactura: false,
        cantidad: 0,
        concepto: "",
        fecha: null,
        metodo: "",
        ficheros: [],
      },
    };
  },
  watch: {
    salidaDineroDialog(v) {
      if (v) {
        this.salidaDinero = {
          cantidad: 0,
          concepto: "",
          fecha: null,
          metodo: "efectivo",
          ficheros: [],
          generarFactura: true,
        };
        this.campoCambiando = "efectivo_" + this.cuenta.id;
      }
    },
  },
  computed: {
    cantidadMostrar() {
      return mostrarMoneda(
        this.salidaDinero.cantidad && this.salidaDinero.cantidad > 0
          ? this.salidaDinero.cantidad
          : 0,
        true
      );
    },
    salidaDineroDialog: {
      get() {
        return this.$store.state.salidaDineroDialog;
      },
      set(v) {
        this.$store.commit("setSalidaDineroDialog", v);
      },
    },
    ...mapState(["datosTpv", "horaActual", "zcierre", "configuracionTpv"]),
    ...mapGetters({
      cuentaActual: "cuenta",
    }),

    cuenta() {
      let efectivoAux = parseFloat(this.cuentaActual.efectivo);
      return {
        ...this.cuentaActual,
        efectivo: 0,
        efectivoMostrar: mostrarMoneda(
          efectivoAux && efectivoAux > 0 ? efectivoAux : 0,
          true
        ),
      };
    },
    inicioMostrar() {
      return moment(this.horaActual).format("DD-MM-YYYY HH:mm:ss");
    },
  },
  methods: {
    cambioImagen(f) {
      if (!!f) {
        console.log(f);
        this.ficheroLoading = true;
        this.$store
          .dispatch("subirFichero", {
            archivo: f,
            ruta: this.ruta,
          })
          .then((url) => {
            console.log(url);
            this.salidaDinero.ficheros.push({
              url: url,
              nombre: f.name,
              tipo: f.type,
            });
          })
          .finally(() => {
            this.ficheroLoading = false;
            this.fichero = null;
          });
      }
    },
    addSalida() {
      this.salidaDineroLoading = true;
      this.$store
        .dispatch("guardarSalidaDinero", {
          ...this.salidaDinero,
          fecha: moment().unix(),
        })
        .then(() => {
          this.salidaDineroDialog = false;
        })
        .finally(() => (this.salidaDineroLoading = false));
    },
    cambiarSeleccion(id) {
      this.campoCambiando = "efectivo_" + id;
      this.salidaDinero.metodo = "efectivo";

      this.$refs.keyboardRefNumerico.reset(); //pone a 0
    },
    onKeyPress(button) {
      if (button == "C") {
        setTimeout(() => {
          this.$refs.keyboardRefNumerico.reset(); //pone a 0
          this.onChange(0);
        }, 100);
      }
    },
    onChange(input) {
      if (!!this.campoCambiando) {
        let metodo = this.campoCambiando.substring(
          0,
          this.campoCambiando.indexOf("_") - 1
        );
        let id = this.campoCambiando.substring(
          this.campoCambiando.indexOf("_") + 1,
          this.campoCambiando.length
        );
        console.log(id);
        console.log(metodo);
        this.salidaDinero.cantidad = parseFloat(parseInt(input) / 100);
      }
    },
  },
};
</script>

<style>
</style>