/*
 * JavaScript client-side example using jsrsasign
 */

// #########################################################
// #             WARNING   WARNING   WARNING               #
// #########################################################
// #                                                       #
// # This file is intended for demonstration purposes      #
// # only.                                                 #
// #                                                       #
// # It is the SOLE responsibility of YOU, the programmer  #
// # to prevent against unauthorized access to any signing #
// # functions.                                            #
// #                                                       #
// # Organizations that do not protect against un-         #
// # authorized signing will be black-listed to prevent    #
// # software piracy.                                      #
// #                                                       #
// # -QZ Industries, LLC                                   #
// #                                                       #
// #########################################################

/**
 * Depends:
 *     - jsrsasign-latest-all-min.js
 *     - qz-tray.js
 *
 * Steps:
 *
 *     1. Include jsrsasign 8.0.4 into your web page
 *        <script src="https://cdn.rawgit.com/kjur/jsrsasign/c057d3447b194fa0a3fdcea110579454898e093d/jsrsasign-all-min.js"></script>
 *
 *     2. Update the privateKey below with contents from private-key.pem
 *
 *     3. Include this script into your web page
 *        <script src="path/to/sign-message.js"></script>
 *
 *     4. Remove or comment out any other references to "setSignaturePromise"
 */

const qz = require("qz-tray");
console.log("OK");
var privateKey =
  "-----BEGIN PRIVATE KEY-----\n" +
  "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDN3EWvlq0C+N/H\n" +
  "tQVdk7lZfhhOfRUtYsoT5BvM7+Bp5s8lo154o8kZ5YYq/3xPhw08hZOzUUb7iKF1\n" +
  "yqy5HHXG/KUlz9W1802KqwcCB/agzQcHBv1VKQlOsvcXu7svVRr7U+FUDpRqxCQi\n" +
  "BW+5quCVc97xDF8HGbN7DJuFZUjiLBahLocbwOmIDA4dr1fOSZQdNHr7AB7fScTc\n" +
  "jp618Zs6fUcrQglOQcTL9GYmQIBZih+KWS8GlBYasQ0e1IeG2ZWoMoM2l+/vKf7z\n" +
  "ylEZ7X3Jd1PQGiJIDihAPci6bUKdfI/kdvD/5CUWF83eeNqDRsoyKzsrOxfjDO2j\n" +
  "KuwF6soLAgMBAAECggEARQJV4/5VQj1OuyJOSafXRw5TtKAOxNesmU1rGmDBcI7c\n" +
  "myfwZ+2z4PgF6Y0azYge4k5/DGovKuwLADMwgp9tRgb0j7/jyupkcJtTrpUf0pjY\n" +
  "hb0MmIs4oQayeWTH456afG0KiMSYcVAOMs9yvCFxxvgAYPr+MiGl/pZj3ixtac+2\n" +
  "y/bDQI8U4U97Km/dBis14/p7rxbizsIK8xACL59xc1hEumtPdP0n8vzJgdJJbbiz\n" +
  "lI6NCIdqKuYEaUaejhQi+OmVcf4vx1Vu3xZrBH3vd/1Xm0jOGRArCMPooqUmh42P\n" +
  "EAOrnwOT6wRW/XMBiYWfPQ/X/e/hAOSBXkJ2+Pm6dQKBgQDeoueLoDNOIonktdy9\n" +
  "teyt9agVSwX3qQNW9lAVl5j8W4D0jJ6M6aJtXrGavf2KqJ8GNzWjPoU41RthJIVi\n" +
  "4z/vYdUPjdOoiOFLvLLLXqoXewEr9tWBfQU7NGQOUY/HIdQj3+adtzcNWzOM+1ev\n" +
  "/QLDm1K42g2f18njQI1usPSvZQKBgQDstclIvC4RmFgbqw6uyid+rwogQUoA1TAl\n" +
  "svw/U9LrMo+OqbVDZah+ywydwdw0dRBNSUGIYMVPtSHOo/4mAAaWm4PiKj38fbh2\n" +
  "eejttrsisw9YKJf34TZbpZ4n4+aZMdCas+n6bOtCeeiJIqLFerXcqss8d/ho7XFn\n" +
  "JFoWtXcUrwKBgF2yF1o2sIDYnknHk7+vWY0YjoVvjnJdCc/GV4vOwg6qf8nriCpr\n" +
  "hPUcXghuns6FCm/2OjeabzysyxoF4bDtTJH1DVK/cG1ENfTG1EQscc7x8FtNRSt6\n" +
  "rwHsmZNBFg5LGVmDuC111tBjKn3RpWSmSUyDCLxM4GD90rQ2DY+hH0jVAoGBAJ84\n" +
  "rDtLsSVOtz1tM4QpIKXbMdF+eTvUKv3AxmIGjWJ9cQkCaJX8JHmdNVBMXUW/1IZY\n" +
  "F0eX6ED0Utg/gS2oaMYXt3+C8I0NN+4hlKeJeZkentyq+YXYLIs6/QZSCwPS+UGG\n" +
  "cYVsjFb7z4mnDZwOLZRQIkL7Y9GYneZGEn8h8warAoGABUaLVWtcpGGtsATSMFuL\n" +
  "jdzFhRgwzplw9m7H0YGPsbBOc+dmZfMq5ya4g+Z3tSAYKqj+XoGSBl0lC+l3hHF2\n" +
  "hfC6sKI5a1MDvjt94Fp6eX5eWDIaBvDnS1g56o12NM4t9AUf1Uuh02czRQ1a7cs7\n" +
  "Avt1EmArClAuUlxzZmC6Us0=\n" +
  "-----END PRIVATE KEY-----";

qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    try {
      var pk = KEYUTIL.getKey(privateKey);
      var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
      sig.init(pk);
      sig.updateString(toSign);
      var hex = sig.sign();
      console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
      resolve(stob64(hextorstr(hex)));
    } catch (err) {
      console.error(err);
      reject(err);
    }
  };
});
