<template>
  <v-dialog
    v-model="cashguardDialog"
    max-width="1200px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="
      $vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'
    "
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title class="white py-3 pr-3" height="78">
        <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          USO CASHGUARD
        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="cashguardDialog = false"
          top
          right
        >
          <v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 44" color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-title class="white py-3 pr-3" height="78">
        Subtítulo
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4 blue-grey lighten-5">
        <v-row class="pa-2 pa-md-0"> </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  watch: {
    cashguardDialog(v) {
      if (v) {
        this.$store.dispatch("cashguard/preguntarInventario");
      }
    },
  },
  computed: {
    cashguardDialog: {
      get() {
        return this.$store.state.cashguard.cashguardDialog;
      },
      set(v) {
        this.$store.commit("cashguard/SET_CASHGUARD_DIALOG", v);
      },
    },
  },
};
</script>

<style>
</style>