import moment from "moment";
export default {
  setMostrarTeclado(state, b) {
    state.mostrarTeclado = b;
  },
  setZIndex(state, i) {
    state.zindex = i;
  },
  setCampoEditarObj(state, obj) {
    state.campoEditarObj = obj;
  },
  setCampoEditarPropiedad(state, prop) {
    state.campoEditarPropiedad = prop;
  },
  setInput(state, prop) {
    state.input = prop;
  },
  setActualValor(state, s) {
    state.actualValor = s;
  },
  setLimpiarInput(state, b) {
    state.limpiarInput = b;
  },
  setVaDividir(state, b) {
    state.vaDividir = b;
  },
  setVariasFormasPago(state, b) {
    state.variasFormasPago = b;
  },
  setCobroPorProductos(state, b) {
    state.cobroPorProductos = b;
  },
  setLanzarNumerico(state) {
    state.lanzarNumerico = moment().unix();
  },
};
