import moment from "moment";
export default {
  getProductosListado(state, getters, rootState) {
    const cats = state.categorias;
    const imps = rootState.impuestos.impuestosArray;
    var res = state.productos.map((e) => {
      let cFound = cats.find((c) => c.id == e.categoria);
      let iFound = imps.find((c) => c.id == e.impuesto);

      e.categoriaNombre = cFound ? cFound.nombre : "Categoría sin definir";
      e.impuestoNombre = iFound ? iFound.nombre : "Impuesto sin definir";
      return e;
    });
    return res;
  },
  getPedidosListado(state, getters, rootState) {
    const p = state.pedidos;
    const estados = state.estados;
    var res = [];
    p.forEach((e) => {
      let auxImg = "/img/pedidosInternos/imgPedido";
      let clase = "pedido-";
      switch (e.estado) {
        case "realizando":
          auxImg = auxImg + "Open.svg";
          clase = clase + "open";
          break;
        case "espera":
          auxImg = auxImg + "Wait.svg";
          clase = clase + "wait";
          break;
        case "preparando":
          auxImg = auxImg + "Ready.svg";
          clase = clase + "ready";
          break;
        case "enviado":
          auxImg = auxImg + "Close.svg";
          clase = clase + "close";
          break;
      }
      res.push({
        ...e,
        estado: estados.find((es) => es.value == e.estado)
          ? estados.find((es) => es.value == e.estado)
          : "Sin estado",
        fechaInicio: e.fechaInicio
          ? moment(e.fechaInicio.toDate()).format("DD-MM-YY ")
          : "-",
        fechaEspera: e.fechaEspera
          ? moment(e.fechaEspera.toDate()).format("DD-MM-YY ")
          : "-",
        fechaEnviado: e.fechaEnviado
          ? moment(e.fechaEnviado.toDate()).format("DD-MM-YY ")
          : "-",
        img: auxImg,
        clase: clase,
      });
    });
    return res.sort((a, b) => {
      if (a.estado.value == "realizando") {
        return -1;
      }

      return moment(b.fechaEspera) - moment(a.fechaEspera);
    });
  },
  enviarButtonDisabled(state) {
    return (
      !state.pedidoGuardado ||
      state.pedidoEnCurso.carritoInterno.length == 0 ||
      state.pedidoEnCurso.estado != "realizando"
      // ||      state.pedidoEnviado
    );
  },
  lanzarModalComprobarPedido(state) {
    return (
      state.pedidoEnCursoComprobar.id &&
      state.pedidoEnCurso.id != state.pedidoEnCursoComprobar.id &&
      state.pedidoEnCursoComprobar.estado == "realizando"
    );
  },
};
