<template>
  <v-bottom-sheet
    v-if="keyboardClass == 'keyboard-completo'"
    id="tecladoBottom"
    ref="tecladoBottom"
    content-class="tecladoBottom"
    persistent
    no-click-animation
    hide-overlay
    v-model="mostrarTeclado"
  >
    <v-sheet
      color="blue-grey lighten-3"
      class="pb-4"
      style="position: relative"
    >
      <v-btn
        @click="mostrarTeclado = false"
        color="primary"
        class="black--text"
        fab
        absolute
        right
        top
        large
        ><v-icon large>mdi-close</v-icon></v-btn
      >
      <v-row class="pb-4 pt-8">
        <v-spacer />
        <v-col cols="10">
          <!-- <v-row>
            <v-btn @click="mostrarTeclado = false" text color="primary"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row> -->
          <v-row>
            <div :class="keyboardClass"></div>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import { mapState } from "vuex";
import AnadirDialogo from "./AnadirDialogo.vue";
import ProductosCarrito from "./ProductosCarrito.vue";
import Vue from "vue";

export default {
  components: { AnadirDialogo, ProductosCarrito },
  name: "keyboardCompletoBottom",
  props: {
    keyboardClass: {
      default: "keyboard-completo",
      type: String,
    },
    input: {
      type: String,
    },
    inputName: {
      type: String,
    },
  },
  data: () => ({
    keyboard: null,
  }),
  watch: {
    limpiarInput(v) {
      if (v) {
        this.reset();
        this.$store.commit("teclado/setInput", ""); //pone en el estado el valor
        this.$store.commit("teclado/setLimpiarInput", false);
      }
    },

    input(input) {
      this.keyboard.setInput(input);
    },
    campoEditarObj(v) {
      //this.reset();
      if (this.keyboard) {
        setTimeout(() => {
          this.keyboard.setInput(
            this.$store.state.teclado.campoEditarObj[
              this.$store.state.teclado.campoEditarPropiedad
            ]
          );
        }, 1000);
      }
    },
    campoEditarPropiedad(v) {
      if (this.keyboard) this.keyboard.setInput(this.actualValor);
      this.$store.commit("teclado/setInput", this.actualValor); //pone en el estado el valor
    },
    mostrarTeclado(v) {
      this.$store.dispatch("teclado/mostrarTeclado");
      if (!!v && !this.keyboard) {
        console.log("dentro");
        var _vm = this;
        setTimeout(() => {
          _vm.keyboard = new Keyboard(this.keyboardClass, {
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            inputName: "inputName",
            /**
             * layout, display, theme ... aquí *
             **/
          });
        }, 200);

        // console.log("hola");
        // this.keyboard = new Keyboard("keyboard-completo", {
        //   onChange: this.onChange,
        //   onKeyPress: this.onKeyPress,
        //   /**
        //    * layout, display, theme ... aquí *
        //    **/
        // });
      }
    },
  },
  computed: {
    limpiarInput() {
      return this.$store.state.teclado.limpiarInput;
    },
    ...mapState({
      campoEditarObj: (state) => state.teclado.campoEditarObj,
      campoEditarPropiedad: (state) => state.teclado.campoEditarPropiedad,
      actualValor: (state) => state.teclado.actualValor,
    }),
    mostrarTeclado: {
      get() {
        return this.$store.state.teclado.mostrarTeclado;
      },
      set(v) {
        if (v) {
          this.$store.dispatch("teclado/mostrarTeclado");
        } else {
          this.$store.dispatch("teclado/esconderTeclado");
        }
      },
    },
  },
  methods: {
    reset() {
      if (this.keyboard) this.keyboard.setInput("");
    },
    onChange(input) {
      /*this.$store.state.teclado.campoEditarObj[
        this.$store.state.teclado.campoEditarPropiedad
      ] = input;*/
      Vue.set(
        this.$store.state.teclado.campoEditarObj,
        this.$store.state.teclado.campoEditarPropiedad,
        input
      );

      this.$store.commit("teclado/setInput", input); //pone en el estado el valor
      // let copia = {
      //   ...this.$store.state.teclado.campoEditarObj,
      // };
      // copia[this.$store.state.teclado.campoEditarPropiedad] = input;
      // // this.$store.commit("clientes/setEditarCliente", copia);
      // this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  mounted() {
    // this.mostrarTeclado = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.keyboard-completo {
  padding: 0;
  background-color: transparent;
}
.hg-theme-default {
  .hg-button {
    border-radius: 8px;
    color: black;
    font-weight: 600;
  }
}
</style>
