<template>
  <div>
    <v-dialog
      :transition="$store.state.bajaCalidad ? 'none' : 'scale-transition'"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      overlay-opacity="0.90"
      content-class="dialogo--custom-fs"
      v-model="mostrar"
      scrollable
    >
      <v-card
        :disabled="$store.state.pedidos.grabandoPedidos"
        class="transparent"
        :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
      >
        <v-toolbar
          :color="
            busquedaMostrar === false
              ? 'white'
              : 'blue-grey darken-1 text--white'
          "
          :class="$vuetify.breakpoint.smAndDown ? 'toolbar--busqueda' : ''"
          class="rounded-t-lg"
          dense
          flat
          :height="$vuetify.breakpoint.smAndDown ? 56 : 92"
          :style="`max-height: ${
            $vuetify.breakpoint.smAndDown ? '56' : '92'
          }px; ${
            $vuetify.breakpoint.smAndDown
              ? 'border-radius: 0 !important;'
              : null
          }`"
        >
          <!-- <v-overlay absolute :value="editandoProductos" /> -->

          <!-- botón atrás -->
          <v-btn
            fab
            v-if="
              modoPantallaAnterior.length != 0 &&
              (!pedidoEditando ||
                (!!pedidoEditando && modoPantalla == 'seleccionCliente_old')) &&
              !busquedaMostrar
            "
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="
              modoPantalla =
                modoPantallaAnterior[modoPantallaAnterior.length - 1];
              modoPantallaAnterior.pop();
            "
            ><v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-arrow-left</v-icon
            ></v-btn
          >

          <!-- botón menú para comandero -->

          <v-menu
            v-if="
              $vuetify.breakpoint.smAndDown &&
              modoPantalla == 'pedidoCarrito' &&
              !busquedaMostrar
            "
            bottom
            right
            transition="slide-x-transition"
            :close-on-content-click="false"
            max-width="90%"
            width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                outlined
                height="48"
                width="48"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-text-field
                  hide-details
                  dense
                  outlined
                  label="Nombre interno"
                  v-model="nombreInterno"
                  ref="nombreInterno"
                  @focus="setNuevoProductoCampoCambiando('nombreInterno')"
                />
                <v-btn
                  outlined
                  fab
                  @click="guardarNombreInterno"
                  :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : 'ml-4'"
                  :x-small="$vuetify.breakpoint.smAndDown"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-title>
                  <v-text-field
                    ref="busqueda"
                    hide-details
                    v-model="buscar"
                    label="Buscar producto"
                    dense
                    outlined
                    :loading="loadingBuscar"
                    @click="focusBuscar"
                    @click:clear="clearBuscar"
                    clearable
                  />
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item>
                <v-btn
                  @click="$store.commit('pedidos/setHistorialDialog', true)"
                  :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                  block
                  outlined
                >
                  <v-icon left>mdi-history</v-icon>
                  Historial
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn
                  @click="
                    $store.commit('pedidos/setMoverPedidoModoDialog', true)
                  "
                  :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                  block
                  outlined
                >
                  <v-icon left>mdi-swap-horizontal-bold</v-icon>
                  Mover pedido/mesaa
                </v-btn>
              </v-list-item>

              <v-list-item>
                <v-btn
                  :disabled="!pedidoEditandoObj.id"
                  @click="abrirCliente"
                  :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                  block
                  outlined
                >
                  <template v-if="pedidoEditandoObj.uid == '0'">
                    <v-icon left>mdi-account</v-icon>
                    Añadir usuario
                  </template>
                  <template v-else>
                    <v-icon left>mdi-account-check</v-icon>
                    Info de usuario
                  </template>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- título -->
          <v-card-title
            v-if="busquedaMostrar === false"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'caption'
                : $vuetify.breakpoint.mdAndDown
                ? 'body-2'
                : 'body-1'
            "
            class="text--primary"
          >
            <span
              v-if="modoPantalla == 'seleccionModo'"
              class="font-weight-black text-uppercase"
              >Selecciona modo</span
            >
            <span
              v-else-if="modoPantalla == 'seleccionMesa'"
              class="font-weight-black text-uppercase"
              >Selecciona una mesa</span
            >
            <span
              v-else-if="modoPantalla == 'seleccionComensales'"
              class="font-weight-black text-uppercase"
              >Selecciona nº de comensales</span
            >
            <v-list
              two-line
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'max-width:210px'
                  : 'max-width: 480px;'
              "
              class="transparent"
              v-else-if="modoPantalla == 'pedidoCarrito'"
            >
              <v-list-item class="pa-0" v-if="nombreInternoActivo == false">
                <v-list-item-content class="pa-0">
                  <!-- nombre de mesa auto y personalizado -->
                  <v-list-item-title
                    class="text-uppercase font-weight-black"
                    :class="$vuetify.breakpoint.mdAndUp ? 'headline' : 'body-2'"
                    v-html="
                      !!pedidoEditandoObj.nombrePersonalizadoTpv
                        ? tituloPidiendo +
                          ($vuetify.breakpoint.mdAndUp ? ' • ' : '<br/>') +
                          pedidoEditandoObj.nombrePersonalizadoTpv
                        : tituloPidiendo
                    "
                  ></v-list-item-title>
                  <!-- nombre de categoría -->
                  <v-list-item-subtitle
                    class="black--text font-weight-light"
                    :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'caption'"
                    v-if="!!cabeceraNombre && nombreInternoActivo == false"
                    >{{ cabeceraNombre }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <span
              v-else-if="modoPantalla == 'seleccionCliente_old'"
              class="font-weight-black text-uppercase"
              >Introduce el teléfono del cliente</span
            >
            <template v-if="modoPantalla == 'pedidoCarrito'">
              <v-text-field
                hide-details
                dense
                outlined
                label="Nombre interno"
                v-model="nombreInterno"
                ref="nombreInterno"
                v-if="nombreInternoActivo == true"
                @focus="setNuevoProductoCampoCambiando('nombreInterno')"
              />
              <!-- botón editar nombre personalizado -->
              <v-btn
                outlined
                fab
                @click="
                  nombreInternoActivo = !nombreInternoActivo;
                  nombreInterno = pedidoEditandoObj.nombrePersonalizadoTpv;
                  setNuevoProductoCampoCambiando('nombreInterno');
                "
                v-if="
                  $vuetify.breakpoint.mdAndUp && nombreInternoActivo == false
                "
                :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : 'ml-4'"
                :x-small="$vuetify.breakpoint.smAndDown"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <!-- botones guardar y cerrar nombre personalizado -->
              <template v-else-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                  outlined
                  fab
                  @click="guardarNombreInterno"
                  :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : 'ml-4'"
                  :x-small="$vuetify.breakpoint.smAndDown"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <v-btn
                  outlined
                  fab
                  @click="cerrarNombreInterno"
                  :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : 'ml-4'"
                  :x-small="$vuetify.breakpoint.smAndDown"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </template>
          </v-card-title>

          <!-- barra de búsqueda -->
          <v-scale-transition>
            <v-row
              v-if="busquedaMostrar === true"
              align="center"
              style="width: 100%"
              :class="
                busquedaMostrar === true && $vuetify.breakpoint.mdAndUp
                  ? 'ml-15'
                  : ''
              "
            >
              <v-col cols="11">
                <v-text-field
                  ref="busqueda"
                  autofocus
                  hide-details
                  v-model="buscar"
                  label="Buscar producto"
                  dense
                  solo
                  class="elevation-0"
                  background-color="white"
                  color="secondary"
                  :loading="loadingBuscar"
                  @click="focusBuscar"
                  @click:clear="clearBuscar"
                  clearable
                />
              </v-col>
            </v-row>
          </v-scale-transition>

          <!-- botones MOVER, USUARIO y BUSCAR a la derecha del header -->
          <template
            v-if="
              $vuetify.breakpoint.mdAndUp &&
              !busquedaMostrar &&
              modoPantalla == 'pedidoCarrito'
            "
          >
            <v-spacer></v-spacer>
            <div
              v-if="
                nombreInternoActivo == !true &&
                modoPantalla == 'pedidoCarrito' &&
                busquedaMostrar === false
              "
            >
              <!-- <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                @click="$store.commit('pedidos/setAbonoDialog', true)"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                fab
                outlined
                :x-small="$vuetify.breakpoint.smAndDown"
              >
                <v-icon>mdi-cash-refund</v-icon>
              </v-btn> -->
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                @click="$store.commit('pedidos/setHistorialDialog', true)"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                fab
                outlined
                :x-small="$vuetify.breakpoint.smAndDown"
              >
                <v-icon>mdi-history</v-icon>
              </v-btn>
              <v-btn
                @click="$store.commit('pedidos/setMoverPedidoModoDialog', true)"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                fab
                outlined
                :x-small="$vuetify.breakpoint.smAndDown"
              >
                <v-icon>mdi-swap-horizontal-bold</v-icon>
              </v-btn>
              <!-- <v-btn
                @click="$store.commit('pedidos/setDescuentosDialog', true)"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                fab
                outlined
                :x-small="$vuetify.breakpoint.smAndDown"
              >
                <v-icon>mdi-sale-outline</v-icon>
              </v-btn> -->
              <v-btn
                :disabled="!pedidoEditandoObj.id"
                @click="abrirCliente"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
                fab
                outlined
                :x-small="$vuetify.breakpoint.smAndDown"
              >
                <v-icon v-if="pedidoEditandoObj.uid == '0'">mdi-account</v-icon>
                <v-icon v-else>mdi-account-check</v-icon>
              </v-btn>
            </div>
          </template>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-btn
            @click="clickBuscar"
            :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
            fab
            outlined
            :width="$vuetify.breakpoint.smAndDown ? 48 : ''"
            :height="$vuetify.breakpoint.smAndDown ? 48 : ''"
            v-if="!busquedaMostrar"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <!-- botón cerrar búsqueda -->
          <v-btn
            v-if="modoPantalla == 'pedidoCarrito' && busquedaMostrar === true"
            icon
            class="mr-4"
            @click="clickBuscar"
            :large="$vuetify.breakpoint.mdAndUp"
            :x-small="$vuetify.breakpoint.smAndDown"
          >
            <v-icon color="white">mdi-magnify-close</v-icon>
          </v-btn>
          <!-- botón cerrar diálogo -->
          <v-spacer v-if="modoPantalla != 'pedidoCarrito'"></v-spacer>
          <v-btn
            fab
            v-if="!busquedaMostrar"
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            style="z-index: 999; position: relative"
            @click="cerrarDialog"
            ><v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-close</v-icon
            ></v-btn
          >
        </v-toolbar>
        <!-- botones MOVER, USUARIO y BUSCAR como subheader -->

        <v-card-text
          class="pa-4 blue-grey lighten-5 border"
          :class="
            modoPantalla == 'pedidoCarrito'
              ? 'pb-1'
              : '' ||
                (modoPantalla == 'seleccionCliente_old' &&
                  (seleccionClientePedidoEnCurso || modo == 'recoger'))
              ? ''
              : 'rounded-b-lg'
          "
        >
          <!-- Pantalla Modos -->
          <v-row no-gutters class="" v-if="modoPantalla == 'seleccionModo'">
            <v-col
              v-for="(modo, i) in modos"
              :key="i"
              cols="4"
              md="3"
              class="pa-2 pa-md-3"
            >
              <v-card
                class="rounded-lg ficha-generica"
                elevation="4"
                @click="goModo(modo)"
              >
                <v-responsive :aspect-ratio="1.618" class="align-center">
                  <v-img :src="modo.imagen">
                    <v-card-text
                      class="text-uppercase text-center font-weight-black py-2 px-1"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'subtitle-2'
                          : $vuetify.breakpoint.mdAndDown
                          ? 'headline'
                          : 'display-1'
                      "
                      style="line-height: 1.1"
                      >{{ modo.nombre }}
                    </v-card-text>
                  </v-img>
                </v-responsive>
              </v-card>
            </v-col>
          </v-row>
          <!-- Pantalla Mesas  -->
          <v-row v-else-if="modoPantalla == 'seleccionMesa'">
            <v-col
              v-for="mesa in mesasComputado"
              :key="mesa.id"
              cols="6"
              sm="4"
              md="3"
            >
              <v-card
                :disabled="!!mesa.pedido"
                class="rounded-lg ficha-generica"
                :class="!!mesa.pedido ? 'mesa-ocupada' : ''"
                elevation="4"
                @click="goNuevoPedido(mesa)"
              >
                <v-responsive :aspect-ratio="1.618" class="align-center">
                  <v-img>
                    <v-card-title
                      class="text-uppercase font-weight-black justify-center text-center flex-column"
                      :class="
                        $vuetify.breakpoint.mdAndDown ? 'body-2' : 'title'
                      "
                    >
                      <div class="text-no-wrap">
                        {{ mesa.nombre }}
                      </div>
                      <div class="text-no-wrap" v-if="!!mesa.pedido">
                        OCUPADA
                      </div>
                    </v-card-title>
                  </v-img>
                </v-responsive>
              </v-card></v-col
            >
          </v-row>
          <!-- Pantalla Comensales -->
          <v-row v-else-if="modoPantalla == 'seleccionComensales'">
            <v-col
              class="pa-2"
              cols="4"
              md="2"
              v-for="comensal in 18"
              :key="comensal"
            >
              <v-card
                class="rounded-lg ficha-generica"
                elevation="4"
                @click="goCarrito(comensal)"
              >
                <v-responsive :aspect-ratio="1.618" class="align-center">
                  <v-img>
                    <v-card-title
                      class="text-uppercase font-weight-bold text-center"
                      :class="
                        $vuetify.breakpoint.smAndDown ? 'text-h5' : 'display-2'
                      "
                    >
                      <v-spacer />
                      {{ comensal }}
                      <v-spacer />
                    </v-card-title>
                  </v-img>
                </v-responsive>
              </v-card>
            </v-col>
          </v-row>
          <!-- Pantalla carrito -->
          <div
            v-else-if="modoPantalla == 'pedidoCarrito'"
            style="height: calc(100% + 16px)"
            class="d-flex my-n3"
          >
            <pedido-dialog />
          </div>
          <!-- Pantalla Seleccionar Cliente -->
          <v-row v-else-if="modoPantalla == 'seleccionCliente_old'">
            <v-col cols="12" md="8">
              <v-text-field
                v-model="filtroTelefono"
                readonly
                solo
                hide-details
                :height="
                  $vuetify.breakpoint.smAndDown
                    ? '50'
                    : $vuetify.breakpoint.mdAndDown
                    ? '70'
                    : '78'
                "
                elevation="2"
                class="rounded-lg mt-1 font-weight-light"
                :class="$vuetify.breakpoint.smAndDown ? 'title' : 'display-1'"
                type="tel"
              >
              </v-text-field>
              <clientes-component :hideTextField="true" />
            </v-col>
            <v-col cols="12" md="4" v-if="$vuetify.breakpoint.mdAndUp">
              <v-card elevation="0" class="transparent">
                <v-row no-gutters class="mb-2">
                  <v-col cols="12">
                    <KeyboardNumMini
                      ref="keyboardRefMini"
                      @onChange="onChange"
                      @onKeyPress="onKeyPress"
                      :input="filtroTelefono"
                      :maxLength="9"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider
          v-if="
            modoPantalla == 'seleccionCliente_old' &&
            (seleccionClientePedidoEnCurso || modo == 'recoger')
          "
        ></v-divider>
        <v-card-actions
          class="rounded-b-lg pt-4 blue-grey py-4 justify-end"
          v-if="
            modoPantalla == 'seleccionCliente_old' &&
            (seleccionClientePedidoEnCurso || modo == 'recoger')
          "
        >
          <v-btn
            v-if="cambiandoCliente"
            @click="
              modoPantalla = 'pedidoCarrito';
              $store.commit('pedidos/setCobrarDialogFullscreen', true);
              $store.commit('pedidos/setCambiandoCliente', false);
            "
            class="rounded-lg ml-4"
            color="primary black--text"
            large
          >
            Volver al pedido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo info cliente -->
    <info-cliente-dialogo />
    <acciones-pedido-dialogo />
    <mover-pedido-modo-dialog />
    <descuentos-dialog />
    <facturacion-dialogo />
    <historial-dialog />
    <abono-dialog />
    <actualizacion-dialog />

    <v-dialog v-model="alertaDialogo" persistent max-width="480">
      <v-alert
        dense
        border="left"
        type="warning"
        class="mb-0 py-8 rounded-lg"
        icon="mdi-alert"
        v-model="alertaDialogo"
        dismissible
        @input="alertaDialogo = false"
        @click="alertaDialogo = false"
      >
        {{ alertaMensaje }}
      </v-alert>
    </v-dialog>
  </div>
</template>
  
<script>
import ClientesComponent from "./ClientesComponent.vue";
import { mapState, mapGetters } from "vuex";
import PedidoDialog from "./PedidoDialog.vue";
import InfoClienteDialogo from "./InfoClienteDialogo.vue";
import HistorialDialog from "./HistorialDialog.vue";
import AbonoDialog from "./AbonoDialog.vue";

import { mostrarIdioma, mostrarMoneda } from "@/utils/helper.utils.js";
import KeyboardNumMini from "./KeyboardNumMini";
import AccionesPedidoDialogo from "./AccionesPedidoDialogo.vue";
import MoverPedidoModoDialog from "./MoverPedidoModoDialog.vue";
import DescuentosDialog from "./DescuentosDialog.vue";
import FacturacionDialogo from "./FacturacionDialogo.vue";
import ActualizacionDialog from "./ActualizacionDialog.vue";

export default {
  components: {
    ClientesComponent,
    PedidoDialog,
    InfoClienteDialogo,
    KeyboardNumMini,
    AccionesPedidoDialogo,
    MoverPedidoModoDialog,
    DescuentosDialog,
    FacturacionDialogo,
    HistorialDialog,
    AbonoDialog,
    ActualizacionDialog,
  },
  data() {
    return {
      modoPantallaAnterior: [],
      abrirNuevaMesaDialogo: false,
      numComensales: "0",
      alertaDialogo: false,
      modoSeleccionado: null,
      alertaMensaje: "",
      busquedaMostrar: false,
      buscar: "",
      loadingBuscar: false,
      nombreInterno: "",
      nuevoNombre: "",
      nombreInternoActivo: false,
      // mesaEleccion: null,
    };
  },
  props: {
    value: Boolean,
  },
  watch: {
    inputTeclado(v) {
      //console.log(v);
    },
    mostrarTeclado(v) {
      if (!v) {
        this.busquedaMostrar = false;
      }
    },
    buscar(v) {
      if (v && v.length > 2) {
        var f = new Promise((resolve) => {
          let res = this.$store.getters["productos/PRODUCTOS_CATALOGO"]
            .filter((p) =>
              p.nombreMostrar.toUpperCase().includes(this.buscar.toUpperCase())
            )
            .map((p) => {
              console.log(p);
              return {
                ...p,
                nombre: p.nombreMostrar,
                precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
              };
            });
          resolve(res);
        });
        this.loadingBuscar = true;
        f.then((r) => {
          this.resultadosBusqueda = r;
        }).finally(() => (this.loadingBuscar = false));
      } else {
        this.resultadosBusqueda = [];
      }
    },
    mostrar(v) {
      if (!v) {
        setTimeout(() => {
          this.busquedaMostrar = false;
          this.buscar = "";
          this.$store.commit("teclado/setLimpiarInput", true);
          this.modoPantallaAnterior = [];
          this.abrirNuevaMesaDialogo = false;
          this.modoPantalla = "seleccionModo";
          this.$store.commit("productos/setPasoPidiendoProducto", 0);
          this.numComensales = "0";
          this.filtroTelefono = "";

          this.$store.commit("setPaginacionManejando", "pedidos");
          this.$store.commit("setPaginaPaginacionPedidos", 0);
          this.$store.dispatch("teclado/esconderTeclado");
          this.nombreInternoActivo = false;
          if (!this.$store.state.pedidos.cobrarDialog) {
            this.pedidoEditando = null;
            this.pedidoEditandoObj = {};
          }
          this.$store.commit("pedidos/setCambiandoCliente", false);
          this.$store.commit("pedidos/setCobrarDialogFullscreen", true);
          this.$store.commit("pedidos/setEditarProductoObj", null);
          this.$store.commit("pedidos/setEditarProductoMenuObj", null);
          this.$store.commit("productos/setProductoPidiendoMenu", null);
        }, 300);
      } else {
        this.$store.commit("setPaginacionManejando", "categorias");
        //this.$store.dispatch("configuracion/conectarLectorCodigo");
      }
    },
    modoPantalla(v) {
      if (v == "seleccionCliente_old") {
        // this.filtroTelefono = "";
        this.$store.commit("clientes/resetFiltros");
        this.$store.commit("clientes/setClientesFiltrados", []);
      }
    },
  },
  computed: {
    moverPedidoModoDialog: {
      get() {
        return this.$store.state.pedidos.moverPedidoModoDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setMoverPedidoModoDialog", v);
      },
    },
    valoresSeleccionar() {
      return [
        ...this.$store.state.datosTpv.modos
          .filter((m) => {
            return (
              (!!this.pedidoEditandoObj &&
                m.id != this.pedidoEditandoObj.modoId &&
                (m.tipoModo === "mostrador" ||
                  m.tipoModo === "domicilio" ||
                  m.tipoModo === "recoger")) ||
              m.tipoModo === "mesas"
            );
          })
          .map((m) => {
            if (m.tipoModo === "mesas") {
              let mesasModo = this.$store.getters["mesasFiltradas"].filter(
                (mMesa) =>
                  this.pedidoEditandoObj &&
                  mMesa.modo == m.id &&
                  mMesa.id != this.pedidoEditandoObj.mesa &&
                  !mMesa.pedido
              );

              return mesasModo.map((mesa) => {
                return {
                  modo: m,
                  mesa: mesa,
                  nombre: m.nombre + " MESA " + mesa.nombre,
                };
              });
            } else {
              return { modo: m, nombre: m.nombre };
            }
          })
          .flat(),
      ];
    },
    editandoProductos() {
      return (
        !!this.$store.state.pedidos.editarProductoObj ||
        !!this.$store.state.pedidos.editarProductoMenuObj
      );
    },
    cambiandoCliente: {
      get() {
        return this.$store.state.pedidos.cambiandoCliente;
      },
      set(v) {
        this.$store.commit("pedidos/setCambiandoCliente", v);
      },
    },
    seleccionClientePedidoEnCurso() {
      return !!this.cambiandoCliente;
    },
    nombrePersonalizadoTpv: {
      get() {
        return this.pedidoEditandoObj.nombrePersonalizadoTpv
          ? this.pedidoEditandoObj.nombrePersonalizadoTpv
          : "";
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "nombrePersonalizadoTpv",
          value: v,
        });
      },
    },
    modoObj() {
      let f = this.$store.state.datosTpv.modos.find((m) => m.id == this.modoid);
      return f ? f : "";
    },
    modo() {
      return this.$route.params.modotipo;
    },
    modoid() {
      return this.$route.params.modoid;
    },
    resultadosBusqueda: {
      get() {
        return this.$store.state.productos.resultadosBusqueda;
      },
      set(v) {
        this.$store.commit("productos/setResultadosBusqueda", v);
      },
    },
    ...mapGetters({
      cuenta: "cuenta",
    }),
    cabeceraNombre() {
      return this.$store.state.productos.pasoPidiendoProducto &&
        this.$store.state.productos.aPidiendo
        ? this.$store.state.productos.aPidiendo.nombre
        : false;
      if (this.$store.state.productos.pasoPidiendoProducto) {
      }
    },
    tituloPidiendo() {
      var res = "";
      console.log(this.pedidoEditandoObj);
      if (!!this.pedidoEditandoObj) {
        if (
          this.pedidoEditandoObj.copyMesa &&
          this.pedidoEditandoObj.modoCopy &&
          this.pedidoEditandoObj.modo === "mesa"
        ) {
          return (
            "MESA " +
            this.pedidoEditandoObj.copyMesa.nombre +
            " " +
            this.pedidoEditandoObj.modoCopy.nombre
          );
        } else if (this.pedidoEditandoObj.modoCopy) {
          let s = this.pedidoEditandoObj.numPedidoRestDiaModoMostrar
            ? " " + this.pedidoEditandoObj.numPedidoRestDiaModoMostrar
            : this.pedidoEditandoObj.numPedidoRestDia
            ? " " + this.pedidoEditandoObj.numPedidoRestDia
            : "";
          return this.pedidoEditandoObj.modoCopy.nombre + s;
        }

        switch (this.pedidoEditandoObj.modo) {
          case "domicilio":
            res = "DOMICILIO";
            break;
          case "recoger":
            res = "RECOGER";
            break;
          case "mesa":
            res = "MESA " + this.pedidoEditandoObj.copyMesa.nombre;
            break;
          case "mostrador":
            res = "MOSTRADOR ";
            break;
        }
      }
      return res;
    },
    numeroPidiendo() {
      return this.pedidoEditandoObj && this.numPedidoRestDiaModoMostrar
        ? this.pedidoEditandoObj.numPedidoRestDiaModoMostrar
        : this.pedidoEditandoObj.numPedidoRestDia
        ? this.pedidoEditandoObj.numPedidoRestDia
        : "-";
    },
    pedidoEditando: {
      get() {
        return this.$store.state.pedidos.pedidoEditando;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditando", v);
      },
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      },
    },
    modoPantalla: {
      get() {
        return this.$store.state.pedidos.modoPantalla;
      },
      set(v) {
        this.$store.commit("pedidos/setModoPantalla", v);
      },
    },
    ...mapState({
      filtro: (state) => state.clientes.filtro,
      mostrarTeclado: (state) => state.teclado.mostrarTeclado,
      inputTeclado: (state) => state.teclado.input,
    }),
    filtroTelefono: {
      get() {
        return this.$store.state.clientes.filtro.ftelefono;
      },
      set(v) {
        this.$store.commit("clientes/setFiltroKey", {
          key: "ftelefono",
          value: v,
        });
      },
    },
    mesasComputado() {
      return this.$store.getters["mesasFiltradas"]
        .filter((m) => m.modo == this.modoSeleccionado)
        .map((m) => {
          return m;
        });
    },
    modos() {
      return [
        ...this.$store.state.datosTpv.modos.map((m) => {
          return m;
        }),
      ];
    },
    mostrar: {
      get() {
        return this.$store.state.pedidos.pedidoDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoDialog", v);
      },
    },
    abrirInfoCliente: {
      get() {
        return this.$store.state.abrirInfoCliente;
      },
      set(v) {
        this.$store.commit("setAbrirInfoCliente", v);
      },
    },
    mesaEleccion: {
      get() {
        return this.pedidoEditandoObj.copyMesa;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "copyMesa",
          value: v,
        });
      },
    },
    editarProductoObj: {
      get() {
        return this.$store.state.pedidos.editarProductoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoObj", v);
      },
    },
  },
  methods: {
    cerrarDialog() {
      this.editarProductoObj = null;
      if (!!this.editarProductoObj && this.$vuetify.breakpoint.mdAndUp) {
        this.editarProductoObj = null;
      } else {
        this.mostrar = false;
        this.limpiarNombreInterno();
      }
    },
    abrirCliente() {
      if (this.pedidoEditandoObj.uid == "0") {
        // this.$store.commit("setAbrirInfoCliente", true);
        this.$store.commit("pedidos/setCambiandoCliente", true);
        // this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
        // this.$store.commit("pedidos/setCobrarDialogFullscreen", false);
        this.$store.commit("clientes/setClienteDialog", true);
        this.$store.commit("clientes/setPasoCliente", "buscar");
      } else {
        this.$store.commit("clientes/setClienteDialog", true);
        this.$store.commit("clientes/setPasoCliente", "formDatos");
      }
      this.$store.commit("pedidos/setFacturandoPedido", false);
    },
    setNuevoProductoCampoCambiando(campo) {
      if (this.$store.state.necesarioTeclado) {
        setTimeout(() => {
          let pos = this[campo].length ? this[campo].length : 0;
          this.$refs[campo].$el
            .querySelector("input")
            .setSelectionRange(pos, pos);
        }, 200);
        var _vm = this;
        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit("teclado/setActualValor", this[campo]);
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this);
        this.$store.commit("teclado/setInput", campo);
        this.$store.commit("teclado/setCampoEditarPropiedad", campo);
      }
    },

    clickBuscar() {
      // this.busquedaMostrar = !this.busquedaMostrar;
      this.buscar = "";
      this.busquedaMostrar = !this.busquedaMostrar;
      if (this.busquedaMostrar) this.focusBuscar();
    },
    clearBuscar() {
      this.$store.commit("teclado/setLimpiarInput", true);
    },
    focusBuscar() {
      if (this.$store.state.necesarioTeclado) {
        setTimeout(() => {
          let pos = this.buscar.length ? this.buscar.length : 0;
          this.$refs.busqueda.$el
            .querySelector("input")
            .setSelectionRange(pos, pos);
        }, 200);

        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit("teclado/setActualValor", this.buscar);
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this);
        this.$store.commit("teclado/setInput", "busqueda");
        this.$store.commit("teclado/setCampoEditarPropiedad", "buscar");
      }
    },
    guardarNombreInterno() {
      this.nombrePersonalizadoTpv = this.nombreInterno;
      this.nombreInternoActivo = false;
      this.$store.commit("teclado/setLimpiarInput", true);
      this.$store.commit("teclado/setMostrarTeclado", false);
      // console.log(this.pedidoEditandoObj)
    },
    cerrarNombreInterno() {
      this.nombreInterno = this.nuevoNombre;
      this.nombreInternoActivo = false;
      this.$store.commit("teclado/setLimpiarInput", true);
      this.$store.commit("teclado/setMostrarTeclado", false);
    },
    limpiarNombreInterno() {
      if (this.nuevoNombre != "") {
        // guardar el nombre en BBDD
        this.nuevoNombre = "";
        this.nombreInterno = "";
      } else {
        this.nombreInterno = "";
      }
    },
    goModo(modo) {
      // this.modoPantallaAnterior.push(this.modoPantalla);
      // this.modoSeleccionado = modo.id;
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modo",
        value: modo.tipoModo,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoId",
        value: modo.id,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoTexto",
        value: modo.nombre,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoCopy",
        value: modo,
      });

      // this.$store.commit("pedidos/setPedidoEditandoObjValor", {
      //   key: "modo",
      //   value: modo.tipoModo,
      // });
      // this.$store.commit("pedidos/setPedidoEditandoObjValor", {
      //   key: "modoId",
      //   value: modo.id,
      // });
      // this.$store.commit("pedidos/setPedidoEditandoObjValor", {
      //   key: "modoCopy",
      //   value: modo,
      // });
      if (modo.tipoModo == "domicilio" || modo.tipoModo == "recoger") {
        this.$store.commit("clientes/setClienteDialog", true);

        // this.mostrar = false;

        // this.modoPantalla = "seleccionCliente";
      } else if (modo.tipoModo == "mesas") {
        this.modoPantalla = "seleccionMesa";
      } else if (modo.tipoModo == "mostrador") {
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "uid",
          value: "0",
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "copyUser",
          value: {
            displayName: "Contado",
          },
        });
        this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
        this.$store.commit("setPaginaPaginacionPedidos", 0);
      }
    },
    goNuevoPedido(mesa) {
      this.mesaEleccion = mesa;
      this.modoPantallaAnterior.push(this.modoPantalla);
      if (mesa.preguntarComensales) {
        this.modoPantalla = "seleccionComensales";
      } else {
        this.$store.dispatch("pedidos/crearPedidoEnMesa", {
          mesa: this.mesaEleccion,
          comensales: null,
        });
      }
    },
    goCarrito(comensales) {
      // this.modoPantallaAnterior.push(this.modoPantalla);
      this.$store.dispatch("pedidos/crearPedidoEnMesa", {
        mesa: this.mesaEleccion,
        comensales: comensales,
      });
    },
    /*     pulsarNumeroTel(n) {
      if (this.filtroTelefono.length >= 9) {
        this.alertaMensaje =
          "El número de teléfono no debe tener más de 6 dígitos.";
        this.alertaDialogo = true;
      } else {
        this.filtroTelefono = this.filtroTelefono + "" + n;
      }
    }, */
    onChange(input) {
      if (input.length >= 10) {
        this.alertaMensaje =
          "El número de teléfono no debe tener más de 9 dígitos.";
        this.alertaDialogo = true;
      } else {
        this.filtroTelefono = input;
      }
    },
    onKeyPress(button) {
      if (button == "C") {
        setTimeout(() => {
          this.filtroTelefono = "";
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss">
.icono-texto {
  .v-btn__content {
    flex-direction: column;
    .v-icon {
      margin-bottom: 12px;
    }
  }
}
</style>
<style lang="scss" scoped>
/* .n-telefono {
  border: solid 1px lightgrey;
} */

.toolbar--busqueda {
  &::v-deep {
    .v-toolbar__content {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
  }
}
.ficha-generica {
  border: 2px solid #00f4d9;
  &.mesa-ocupada {
    border: 2px solid #bdbdbd;
    * {
      color: #bdbdbd;
    }
  }
}
.busqueda-container {
  position: absolute;
  right: 92px;
  top: 4px;
}
</style>