<template>
  <v-dialog
    v-model="cashguardBottom"
    persistent
    width="100%"
    content-class="rounded-0 mx-n3"
  >
    <v-card
      color="white"
      elevation="0"
      rounded="0"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-card-title class="py-3 pr-3 justify-center" height="78">
              <h2
                :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
                class="font-weight-black text-center"
              >
                ESPERANDO CASHGUARD
              </h2>
              <!-- <v-btn
                fab
                :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                color="primary"
                @click="cashguardBottom = false"
                top
                right
              >
                <v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 44" color="black"
                  >mdi-close</v-icon
                >
              </v-btn> -->
              
            </v-card-title>
            <!-- <v-divider></v-divider> -->
            <v-card-text>
              <div class="d-flex py-8 justify-center">
                <v-progress-circular
                  color="primary"
                  class="mx-auto"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-card-text>
            <!-- <v-divider></v-divider> -->
            <v-card-actions class="justify-center">
              <v-btn
                large
                color="primary black--text"
                @click="cashguardBottom = false"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>

      </v-container>
<!--       <v-divider />
      <v-card-title class="white py-3 pr-3" height="78">
        <v-progress-linear indeterminate />
      </v-card-title> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  watch: {
    // ficheroCashguardResultado(v) {
    //   if (v) {
    //     this.$store.dispatch("cashguard/preguntarInventario");
    //   }
    // },
  },
  computed: {
    ficheroCashguardResultado: {
      get() {
        return this.$store.state.cashguard.ficheroCashguardResultado;
      },
      set(v) {
        this.$store.commit("cashguard/SET_FICHERO_CASHGUARD_RESULTADO", v);
      },
    },
    cashguardBottom: {
      get() {
        return this.$store.state.cashguard.cashguardBottom;
      },
      set(v) {
        this.$store.commit("cashguard/SET_CASHGUARD_BOTTOM", v);
      },
    },
  },
};
</script>

<style>
</style>