<template>
  <v-content>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title> MODO </v-card-title>
          <v-card-text>
            <v-select
              :items="modos"
              label="Modo"
              v-model="pedido.modo"
              :disabled="pedido && pedido.modo == 'plano'"
            />
          </v-card-text>
        </v-card>
        <v-card :disabled="loading">
          <v-card-title> CLIENTE </v-card-title>
          <v-card-text>
            <v-btn @click="goSeleccionarCliente">Seleccionar</v-btn>
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-if="pedido && pedido.modo == 'plano'"
              disabled
              label="Mesa"
              v-model="pedido.copyMesa.nombre"
            />
          </v-card-text>
        </v-card>
        <productos-carrito :productos="pedido.productos" />

        <v-card-title>TOTAL: {{ precioTotal }} €</v-card-title>
        <v-card-subtitle>Subtotal: {{ subtotal }}</v-card-subtitle>
        <v-card-subtitle>Impuestos: {{ impuestos }}</v-card-subtitle>

        <cobrar />
      </v-col>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title> PRODUCTOS </v-card-title>
          <v-card-text>
            <producto-pedir :disabled="loading" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="cliente.dialog" max-width="800">
      <v-card>
        <v-card-title>Elija cliente</v-card-title>
        <v-card-text> <clientes-component :acciones="false" /></v-card-text>
      </v-card>
    </v-dialog>
  </v-content>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";
import ProductoPedir from "../components/ProductoPedir.vue";
import { restSinNegocio } from "@/utils/restaurantes.utils.js";

import Cobrar from "../components/Cobrar.vue";
import ProductosCarrito from "../components/ProductosCarrito.vue";
import { mostrarMoneda } from "@/utils/helper.utils.js";
import ClientesComponent from "../components/ClientesComponent.vue";

export default {
  components: { ProductoPedir, Cobrar, ProductosCarrito, ClientesComponent },

  data() {
    return {
      pedido: { modo: null, productos: [] },
      loading: true,
      timeoutGrabar: null,
      cliente: {
        dialog: false,
      },
    };
  },
  watch: {
    pedido: {
      handler(nVal, oVal) {
        let _vm = this;
        if (this.pedido && this.pedido.id) {
          if (!!this.timeoutGrabar) clearTimeout(_vm.timeoutGrabar);
          _vm.timeoutGrabar = setTimeout(() => {
            this.grabarPedido();
          }, 3000);
        }
      },
      deep: true,
    },
  },
  computed: {
    modos() {
      return this.$store.state.pedidos.modos;
    },
    precioTotal() {
      return mostrarMoneda(this.pedido.precioTotal);
    },
    subtotal() {
      return mostrarMoneda(
        this.pedido.precioTotal - this.pedido.impuestosTotal
      );
    },
    impuestos() {
      return mostrarMoneda(this.pedido.impuestosTotal);
    },
  },
  methods: {
    grabarPedido() {
      this.$store.dispatch("pedidos/savePedido", this.pedido);
    },
    goSeleccionarCliente() {
      this.cliente.dialog = true;
    },
  },
  beforeDestroy() {
    this.$store.commit("setMostrarFabAdd", true);
    if (!!this.timeoutGrabar) clearTimeout(this.timeoutGrabar);
    return this.pedido && this.pedido.id ? this.grabarPedido() : true;
  },
  // destroyed() {
  // 	this.$store
  // 		.dispatch("firestoreConexion", false) //activamos conexión bbdd firesetoree, grabamos offline
  // 		.then((r) => {
  // 			console.log("con conexión");
  // 		});
  // },
  mounted() {
    this.$store.commit("setMostrarFabAdd", false);
    let idpedido = this.$route.params.idpedido;
    // this.$store
    // 	.dispatch("firestoreConexion") //desactivamos conexión bbdd firesetoree, grabamos offline
    // 	.then((r) => {
    // 		console.log("sin conexión");
    // 	});
    if (idpedido) {
      this.loading = false;
      this.$store.dispatch("pedidos/setPedidoObj", idpedido).then(() => {
        this.pedido = this.$store.state.pedidos.pedidoEditandoObj;
      });

      // this.pedido = this.$store.getters["pedidos/pedidoEditandoObj"];
    } else if (this.$route.params.idmesa) {
      let f = this.$store.getters["mesas"].find(
        (m) => m.id == this.$route.params.idmesa
      );
      if (f) {
        if (!!f.pedido) {
          this.$router.push("/pedido/" + f.pedido.toString());
        } else {
          this.pedido = {
            id: null,
            fechaInicio: new Date(),
            fechaServidor: new Date(),
            usuarioTpv: this.$store.state.usuarios.usuarioActual,
            copyUsuarioTpv: {
              nombre: this.$store.state.usuarios.usuarioActualObj.nombre,
            },
            historial: [
              {
                fecha: moment().unix(),
                mensaje: "comienzo pedido",
                usuario: this.$store.state.usuarios.usuarioActual,
              },
            ],
            modo: "plano",
            mesa: this.$route.params.idmesa,
            copyMesa: { nombre: f.nombre ? f.nombre : "Sin nombre" },
            estado: "tpv-realizando",
            canalVenta: "tpv",
            negocio: this.$store.state.restauranteObj.negocio,
            restaurante: restSinNegocio(this.$store.state.restauranteObj),
            copyRest: {
              nombre: this.$store.state.restauranteObj.nombre,
              cif: this.$store.state.restauranteObj.cif,
              telefono: this.$store.state.restauranteObj.telefono,
              telefono: this.$store.state.restauranteObj.telefono,
              direccion: this.$store.state.restauranteObj.direccion,
              confGeneral: {
                zonaHoraria:
                  this.$store.state.restauranteObj.confGeneral &&
                  this.$store.state.restauranteObj.confGeneral.zonaHoraria
                    ? this.$store.state.restauranteObj.confGeneral.zonaHoraria
                    : null,
              },
            },
            copyNegocio: {
              nombre: this.$store.state.negocioObj.nombre,
            },
            productos: [],
          };
        }
      }
    } else {
      //nuevo
      this.pedido = {
        modo: null,
        id: null,
        fechaInicio: new Date(),
        fechaServidor: new Date(),
        usuarioTpv: this.$store.state.usuarios.usuarioActual,
        copyUsuarioTpv: {
          nombre: this.$store.state.usuarios.usuarioActualObj.nombre,
        },
        historial: [
          {
            fecha: moment().unix(),
            mensaje: "comienzo pedido",
            usuario: this.$store.state.usuarios.usuarioActual,
          },
        ],
        estado: "tpv-realizando",
        canalVenta: "tpv",
        negocio: this.$store.state.restauranteObj.negocio,
        restaurante: restSinNegocio(this.$store.state.restauranteObj),
        copyRest: {
          nombre: this.$store.state.restauranteObj.nombre,
          cif: this.$store.state.restauranteObj.cif,
          telefono: this.$store.state.restauranteObj.telefono,
          telefono: this.$store.state.restauranteObj.telefono,
          direccion: this.$store.state.restauranteObj.direccion,
          confGeneral: {
            zonaHoraria:
              this.$store.state.restauranteObj.confGeneral &&
              this.$store.state.restauranteObj.confGeneral.zonaHoraria
                ? this.$store.state.restauranteObj.confGeneral.zonaHoraria
                : null,
          },
        },
        copyNegocio: {
          nombre: this.$store.state.negocioObj.nombre,
        },
        productos: [],
      };
      this.$store.commit("pedidos/setPedidoEditandoObj", this.pedido);
    }
  },
};
</script>