<template>
  <!-- Menu lateral -->
  <v-card elevation="4" outlined class="grey lighten-4 pa-4 border">
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-text-field
            class="
              cyan
              accent-1
              headline
              font-weight-bold
              centered-input
              rounded-lg
            "
            readonly
            label="TIEMPO DOMICILIO"
            :value="tiempoDomicilio + ' min'"
            outlined
            hide-details
          ></v-text-field>
          <v-row no-gutters class="mt-3">
            <v-col cols="6" class="pr-2">
              <v-btn
                dark
                x-large
                block
                @click="sumarRestar('resta', 'reparto')"
                class="rounded-lg"
              >
                <v-icon size="34">mdi-minus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-btn
                dark
                x-large
                block
                @click="sumarRestar('suma', 'reparto')"
                class="rounded-lg"
              >
                <v-icon size="34">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-text-field
            class="
              cyan
              accent-1
              headline
              font-weight-bold
              centered-input
              rounded-lg
            "
            readonly
            label="TIEMPO RECOGIDA"
            :value="tiempoRecogida + ' min'"
            outlined
            hide-details
          ></v-text-field>
          <v-row no-gutters class="mt-3">
            <v-col cols="6" class="pr-2">
              <v-btn
                dark
                x-large
                block
                @click="sumarRestar('resta', 'recogida')"
                class="rounded-lg"
              >
                <v-icon size="34">mdi-minus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-btn
                dark
                x-large
                block
                @click="sumarRestar('suma', 'recogida')"
                class="rounded-lg"
              >
                <v-icon size="34">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <!--         <v-col class="pa-0 pl-4 d-none d-md-flex" cols="4">
          <v-card
            flat
            color="transparent"
            class="d-flex align-center justify-center flex-column"
            width="100%"
            height="100%"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="d-flex align-center justify-center mb-2"
                width="100%"
                height="100%"
                :color="hover ? 'grey lighten-4' : 'white'"
                @mouseup="mouseDownToScroll = false"
                @mousedown="scrollTo('up')"
              >
                <v-icon size="40">mdi-chevron-up</v-icon>
              </v-card>
            </v-hover>
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="d-flex align-center justify-center mt-2"
                width="100%"
                height="100%"
                :color="hover ? 'grey lighten-4' : 'white'"
                @mouseup="mouseDownToScroll = false"
                @mousedown="scrollTo('down')"
              >
                <v-icon size="40">mdi-chevron-down</v-icon>
              </v-card>
            </v-hover>
          </v-card>
        </v-col> -->
        <v-col class="pt-1"
          ><!--novale-->
          <v-divider class="mb-4"></v-divider>
          <v-btn
            @click="imprimirz"
            :loading="loadingZ"
            dark
            x-large
            block
            class="rounded-lg"
            >Imprimir Z</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      loadingZ: false,
      mouseDownToScroll: false,
      cargandoRecogidaActivada: false,
      cargandoDomicilioActivado: false,
      cargandoTiempoDomicilio: false,
      cargandoTiempoRecogida: false,
      tiempoBloqueo: 2000,
    };
  },
  computed: {
    tiempoDomicilio: {
      get() {
        return this.$store.state.tiempoDomicilio;
      },
      set(v) {
        this.$store.commit("setTiempoDomicilio", v);
      },
    },
    tiempoRecogida: {
      get() {
        return this.$store.state.tiempoRecogida;
      },
      set(v) {
        this.$store.commit("setTiempoRecogida", v);
      },
    },
    ...mapState({
      isLoaded: (state) => state.loaded,
    }),
  },
  methods: {
    ...mapActions({
      setRecogidaActivada: "setRecogidaActivada",
      setDomicilioActivado: "setDomicilioActivado",
      setTiempoDomicilio: "setTiempoDomicilio",
      setTiempoRecogida: "setTiempoRecogida",
    }),
    imprimirz() {
      let _vm = this;
      _vm.loadingZ = true;
      this.$store
        .dispatch("calcularCierre")
        .then(() => {
          return dispatch("imprimirz");
        })

        .then((r) => {
          console.log(r);
          if (!r.ok) {
            return Promise.reject(r);
          }
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Se ha mandado a imprimir el Z",
          });
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al imprimir el Z: " + e,
          });
        })
        .finally(() => {
          _vm.loadingZ = false;
        });
    },
    sumarRestar(operacion, tipo) {
      let minutos = 5;
      switch (tipo) {
        case "reparto":
          operacion == "suma"
            ? (this.tiempoDomicilio += minutos)
            : this.tiempoDomicilio - minutos >= 0
            ? (this.tiempoDomicilio -= minutos)
            : null;

          break;
        default:
          operacion == "suma"
            ? (this.tiempoRecogida += minutos)
            : this.tiempoRecogida - minutos >= 0
            ? (this.tiempoRecogida -= minutos)
            : null;

          break;
      }
    },
    scrollTo(move) {
      let _vue = this;
      this.mouseDownToScroll = true;
      let duration = 50;

      let interval = setInterval(function () {
        let pos = window.pageYOffset;
        let to = move == "up" ? pos - 50 : pos + 100;
        _vue.$vuetify.goTo(Number(to), {
          duration: duration,
          easing: "easeOutQuad",
        });

        if (!_vue.mouseDownToScroll) {
          clearInterval(interval);
        }
      }, duration);

      // let duration = 500;
      // let pos = window.pageYOffset;
      // let to = move == "up" ? pos-200 : pos+200;

      // this.$vuetify.goTo(Number(to), {
      // 	duration: duration,
      // 	easing: "easeOutQuad"
      // });
    },
  },
  mounted() {},
  watch: {
    /*recogidaActivada: function(newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoRecogidaActivada) {
        this.cargandoRecogidaActivada = true;

        setTimeout(function() {
          _vue.setRecogidaActivada(_vue.recogidaActivada);
          _vue.cargandoRecogidaActivada = false;
        }, this.tiempoBloqueo);
      }
    },
    domicilioActivado: function(newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoDomicilioActivado) {
        this.cargandoDomicilioActivado = true;

        setTimeout(function() {
          _vue.setDomicilioActivado(_vue.domicilioActivado);
          _vue.cargandoDomicilioActivado = false;
        }, this.tiempoBloqueo);
      }
    },*/
    tiempoDomicilio: function (newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoTiempoDomicilio) {
        this.cargandoTiempoDomicilio = true;

        setTimeout(function () {
          _vue.setTiempoRecogida(_vue.tiempoRecogida);
          _vue.setTiempoDomicilio(_vue.tiempoDomicilio);
          _vue.cargandoTiempoDomicilio = false;
        }, this.tiempoBloqueo);
      }
    },
    tiempoRecogida: function (newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoTiempoRecogida) {
        this.cargandoTiempoRecogida = true;
        setTimeout(function () {
          _vue.setTiempoRecogida(_vue.tiempoRecogida);
          _vue.setTiempoDomicilio(_vue.tiempoDomicilio);
          _vue.cargandoTiempoRecogida = false;
        }, this.tiempoBloqueo);
      }
    },
  },
};
</script>
	
<style>
.centered-input input {
  text-align: center;
}
</style>