
//actualiza los precios de carrito
export const actualizarCarrito = (pedido) => {
  const filtroVacios = pedido.productos ? pedido.productos.filter(e => e.cantidad >= 1) : [];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  pedido.productos = filtroVacios;
  /*********************************** Construye el precio y d totales ***********************************/
  pedido.precioTotal = 0;
  pedido.numeroProductos = 0;

  pedido.productos.forEach(function (e) {
    let sumaOpciones = 0;
    if (e.opcionesPrecio.length > 0) sumaOpciones = e.opcionesPrecio.reduce(reducer);

    pedido.precioTotal += e.cantidad * (e.precio + sumaOpciones)
    pedido.numeroProductos += e.cantidad
  })
  if (
    pedido.modo == 'domicilio' &&
    pedido.recargo &&
    pedido.recargo > 0 &&
    pedido.precioTotal > 0
  ) {
    pedido.precioTotal += pedido.recargo;
  }

  return pedido
}