<template>
  <v-row>
    <v-col cols="12">
      <h1 class="mb-4 mt-2 display-1 text-center">Configuración</h1>
      <v-row>
        <v-col
          cols="6"
          md="4"
          lg="3"
          @click="$router.push('/configuracion/dispositivos')"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg"
              :style="hover ? 'cursor: pointer' : ''"
              :color="hover ? 'grey lighten-4' : 'white'"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <v-list-item-content>
                  <div class="text-center">
                    <!-- <v-btn absolute outlined dark x-small fab right color="pink">
                    <h3>{{ $store.getters["pedidos/numPedidos"].toString() }}</h3>
                  </v-btn> -->

                    <v-icon
                      class="my-4"
                      color="teal darken-4"
                      :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                      >mdi-printer</v-icon
                    >
                  </div>
                </v-list-item-content>
                <v-card-actions
                  class="grey lighten-4 acciones-footer acciones--border-top"
                >
                  <span
                    class="
                      text-uppercase
                      mx-auto
                      font-weight-bold
                      teal--text
                      text--darken-4
                      subtitle-2
                    "
                    >Dispositivos</span
                  >
                </v-card-actions>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>

        <!-- <v-btn @click="verUsb">Ver usb</v-btn>
        <v-btn @click="enviarDatosDispositivo">Abrir usb</v-btn> -->
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loadingDispositivos: false,
    };
  },
  methods: {
    ...mapActions({
      volcarDispositivos: "configuracion/volcarDispositivosAlServidor",
      vincularUsb: "configuracion/vincularUsb",
      verUsb: "configuracion/verUsb",
      enviarDatosDispositivo: "configuracion/enviarDatosDispositivo",
    }),
  },
  mounted() {
    this.loadingDispositivos = true;

    // this.dispositivosUsb();
    // this.volcarDispositivos().finally(() => (this.loadingDispositivos = false));

    // this.$store.dispatch("teclado/mostrarTeclado");
  },
};
</script>

<style>
</style>