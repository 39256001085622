<template>
  <div>
    <v-dialog
      v-model="abrirCalendario"
      overlay-opacity="0.90"
      content-class="mt-n4 rounded-lg elevation-0"
      scrollable
    >
      <v-card class="transparent" elevation="0">
        <!-- <v-card-title class="py-6 transparent">
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
            color="primary"
            @click="
              $store.commit('setAbrirCalendario', false);
              tipoCal = 'month';
            "
            absolute
            right
            class="mt-10 mr-n4"
            ><v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 54"
              color="black"
              >mdi-close</v-icon
            ></v-btn
          ></v-card-title
        > -->
        <v-toolbar flat color="white rounded-t-lg" height="92">
          <v-btn outlined absolute left @click="setToday"> Hoy </v-btn>
          <v-btn
            absolute
            :outlined="$vuetify.breakpoint.mdAndUp"
            :text="$vuetify.breakpoint.smAndDown"
            :left="$vuetify.breakpoint.mdAndUp"
            :right="$vuetify.breakpoint.smAndDown"
            class="volver--tipo-mes"
            v-if="tipoCal == 'day'"
            @click="tipoCal = 'month'"
          >
            <v-icon> mdi-calendar-arrow-left </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text large @click="prev" class="ml-16">
            <v-icon large> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn text large @click="next">
            <v-icon large> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="
              $store.commit('setAbrirCalendario', false);
              tipoCal = 'month';
            "
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0 grey lighten-5">
          <v-sheet :height="tipoCal == 'month' ? 524 : undefined">
            <v-calendar
              locale="es"
              v-model="calendario"
              ref="calendar"
              :weekdays="weekdays"
              :type="tipoCal"
              color="primary black--text"
              :events="reservasComputed"
              event-overlap-mode="stack"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              :event-height="tipoCal == 'day' ? 50 : undefined"
              @click:event="showEvent"
              @click:date="addEvent"
              @click:more="viewDay"
              @change="cambioCalendario"
              ><!-- <template v-slot:event="{ event, timed, eventSummary }">
                <div
                  class="v-event-draggable"
                  v-html="eventSummary()"
                ></div></template
            > --></v-calendar
            >
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      overlay-opacity="0.90"
      content-class="elevation-0"
      scrollable
      v-model="nuevaReservaDialog"
      max-width="800px"
    >
      <v-card class="transparent">
        <v-card-title
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'rounded-0'
              : 'rounded-t-lg'
          "
          class="py-3 pr-3 white"
        >
          <h2
            :class="$vuetify.breakpoint.mdAndDown ? 'title' : 'display-1'"
            class="font-weight-black"
          >
            {{
              !nuevaReserva.id
                ? "Nueva reserva"
                : nuevaReserva.nombre
                ? "Editando reserva de " + nuevaReserva.nombre
                : "Editando reserva"
            }}
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="nuevaReservaDialog = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 blue-grey lighten-5">
          <!-- <v-col cols="12" md="8">
              <v-select
                label="Seleccione horario"
                :items="rangosSeleccionHorarioItems"
                v-model="nuevaReserva.seleccionRango"
              />
            </v-col> -->
          <v-row>
            <v-col class="text-center" cols="12" md="5">
              <v-time-picker
                v-model="nuevaReserva.inicio"
                class="rounded-lg"
                :allowed-minutes="minutosPermitidos"
                format="24hr"
                full-width
              />
            </v-col>
            <!-- <v-col class="text-center" cols="12" md="6">
              <v-time-picker
                v-model="nuevaReserva.fin"
                readonly
                class="rounded-lg"
                format="24hr"
              />
            </v-col> -->
            <v-col cols="12" md="7">
              <v-form ref="form" lazy-validation>
                <v-row class="mt-2">
                  <v-col cols="12" sm="8" class="py-0">
                    <v-menu
                      v-model="abrirDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :transition="
                        $store.state.bajaCalidad ? 'none' : 'scale-transition'
                      "
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Día"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          v-model="calendario"
                        />
                      </template>
                      <v-date-picker
                        v-model="calendario"
                        @input="abrirDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      :items="selectComensales"
                      v-model="nuevaReserva.comensales"
                      label="Comensales"
                      type="Number"
                      :error-messages="comensalesErrors"
                      @input="$v.nuevaReserva.comensales.$touch()"
                      @change="inputComensales"
                      @blur="$v.nuevaReserva.comensales.$touch()"
                      @click="focusCampo(nuevaReserva, 'comensales')"
                    />
                  </v-col>
                  <!-- <v-col cols="12" md="6" class="py-0">
                    <v-select
                      :items="selectModo"
                      v-model="nuevaReserva.modo"
                      label="Sala/Terraza"
                      :error-messages="modoErrors"
                      @input="$v.nuevaReserva.modo.$touch()"
                      @blur="$v.nuevaReserva.modo.$touch()"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-select
                      :items="selectMesa"
                      v-model="nuevaReserva.mesa"
                      label="Mesa"
                      :error-messages="mesaErrors"
                      @input="$v.nuevaReserva.mesa.$touch()"
                      @blur="$v.nuevaReserva.mesa.$touch()"
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="nuevaReserva.nombre"
                      label="Nombre"
                      ref="nombre"
                      :error-messages="nombreErrors"
                      @input="$v.nuevaReserva.nombre.$touch()"
                      @blur="$v.nuevaReserva.nombre.$touch()"
                      @click="focusCampo(nuevaReserva, 'nombre')"
                    />
                  </v-col>
                  <v-col cols="12" md="8" class="py-0">
                    <v-text-field
                      v-model="nuevaReserva.email"
                      label="Email"
                      ref="email"
                      :error-messages="emailErrors"
                      @input="$v.nuevaReserva.email.$touch()"
                      @blur="$v.nuevaReserva.email.$touch()"
                      @click="focusCampo(nuevaReserva, 'email')"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-text-field
                      v-model="nuevaReserva.telefono"
                      label="Teléfono"
                      type="Tel"
                      ref="telefono"
                      :error-messages="telefonoErrors"
                      @input="$v.nuevaReserva.telefono.$touch()"
                      @blur="$v.nuevaReserva.telefono.$touch()"
                      @click="focusCampo(nuevaReserva, 'telefono')"
                    />
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      v-model="nuevaReserva.comentarios"
                      label="Comentarios"
                      ref="comentarios"
                      rows="3"
                      @click="focusCampo(nuevaReserva, 'comentarios')"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="rounded-b-lg py-4 blue-grey lighten-5">
          <v-btn
            color="red"
            class="mx-2 rounded-lg white--text"
            :loading="saveReservaLoading"
            @click="eliminarReserva"
            >Eliminar</v-btn
          >
          <v-spacer />
          <v-btn
            v-if="!nuevaReserva.confirmado"
            :disabled="
              $v.nuevaReserva.$invalid || nuevaReserva.email.length == 0
            "
            color="info"
            @click="confirmarReserva"
            class="mx-2 rounded-lg"
            :loading="saveReservaLoading"
            >Confirmar y enviar email</v-btn
          >
          <v-btn
            v-if="!nuevaReserva.confirmado"
            :disabled="$v.nuevaReserva.$invalid"
            color="light-blue lighten-3"
            @click="confirmarReservaManual"
            class="mx-2 rounded-lg"
            :loading="saveReservaLoading"
            >Confirmar</v-btn
          >
          <!-- <v-spacer /> -->

          <v-btn
            color="primary"
            @click="saveReserva"
            class="rounded-lg black--text"
            :loading="saveReservaLoading"
            :disabled="$v.nuevaReserva.$invalid"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email, minValue } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import moment from "moment-timezone";
export default {
  mixins: [validationMixin],
  validations: {
    nuevaReserva: {
      email: { email },
      nombre: { required, minLength: minLength(3) },
      telefono: { required, minLength: minLength(9) },
      comensales: { required },
      // modo: { required },
      // mesa: { required },
    },
  },
  data() {
    return {
      saveReservaLoading: false,
      config: {
        franjas: null,
      },
      tipoCal: "month",
      calendario: "",
      abrirDatePicker: false,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      mode: "stack",
      colors: ["blue", "indigo", "deep-purple"],
      nuevaReservaDialog: false,
      nuevaReserva: {
        id: null,
        dia: "",
        inicio: "",
        fin: "",
        nombre: "",
        comensales: 0,
        email: "",
        telefono: "",
        reservaInicioUnix: "",
        comentarios: "",
        modo: "",
        mesa: "",
      },
    };
  },
  watch: {
    nuevaReservaDialog(v) {
      if (!v) {
        this.nuevaReserva = {
          telefono: "",
          id: null,
          inicio: "",
          fin: "",
          nombre: "",
          comensales: "",
          email: "",
          dia: "",
          reservaInicioUnix: "",
        };
        this.$v.$reset();
        this.$store.dispatch("teclado/esconderTeclado");
      } else if (!this.nuevaReserva.id) {
        // this.nuevaReserva.dia = moment(this.calendario, "YYYY-MM-DD").format(
        //   "DD/MM/YYYY"
        // );
      }
    },
    calendario(v) {
      if (this.nuevaReserva && this.nuevaReserva.dia) this.nuevaReserva.dia = v;
    },
  },
  computed: {
    restGest() {
      return this.$store.state.restauranteObj;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.nuevaReserva.email.$dirty) return errors;
      !this.$v.nuevaReserva.email.email && errors.push("Email no válido");
      // !this.$v.nuevaReserva.email.required && errors.push("E-mail requerido");
      return errors;
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.nuevaReserva.nombre.$dirty) return errors;
      !this.$v.nuevaReserva.nombre.required && errors.push("Nombre requerido.");
      !this.$v.nuevaReserva.nombre.minLength &&
        errors.push("El nombre debe tener al menos 3 caracteres.");
      return errors;
    },
    telefonoErrors() {
      const errors = [];
      if (!this.$v.nuevaReserva.telefono.$dirty) return errors;
      !this.$v.nuevaReserva.telefono.required &&
        errors.push("Teléfono requerido.");
      !this.$v.nuevaReserva.telefono.minLength &&
        errors.push("El teléfono debe tener al menos 9 caracteres.");
      return errors;
    },
    comensalesErrors() {
      const errors = [];
      if (!this.$v.nuevaReserva.comensales.$dirty) return errors;
      !this.$v.nuevaReserva.comensales.required &&
        errors.push("Número de comensales requerido.");
      return errors;
    },
    modoErrors() {
      const errors = [];
      if (!this.$v.nuevaReserva.modo.$dirty) return errors;
      !this.$v.nuevaReserva.modo.required &&
        errors.push("Seleccíón de sala requerida.");
      return errors;
    },
    mesaErrors() {
      const errors = [];
      if (!this.$v.nuevaReserva.mesa.$dirty) return errors;
      !this.$v.nuevaReserva.mesa.required &&
        errors.push("Selección de mesa requerido.");
      return errors;
    },
    abrirCalendario: {
      get() {
        return this.$store.state.abrirCalendario;
      },
      set(v) {
        this.$store.commit("setAbrirCalendario", v);
      },
    },
    selectComensales() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    },
    selectModo() {
      return ["Salón 1", "Salón 2 (Grande)", "Terraza"];
    },
    selectMesa() {
      return [
        "Mesa 1",
        "Mesa 2",
        "Mesa 3",
        "Mesa 4",
        "Mesa 5",
        "Mesa 6",
        "Mesa 7",
        "Mesa 8",
        "Mesa 9",
      ];
    },
    // rangosSeleccionHorarioItems() {
    //   var res = [];

    //   //   console.log(this.restGest);
    //   let diaSemana = moment(this.calendario, "DD/MM/YYYY").isoWeekday() - 1;
    //   let horariosSemana = this.restGest.horarios.semana;
    //   let tipos = this.restGest.horarios.tipos;

    //   if (
    //     horariosSemana &&
    //     horariosSemana[diaSemana] &&
    //     horariosSemana[diaSemana].reserva
    //   ) {
    //     let fHorario = tipos.find(
    //       (t) => t.nombre == horariosSemana[diaSemana].reserva
    //     );
    //     if (fHorario) {
    //       fHorario.rangos.forEach((h) => {
    //         var finRango = moment
    //           .tz(
    //             this.calendario + " " + h.fin,
    //             "DD/MM/YYYY HH:mm",
    //             "Europe/Madrid"
    //           )
    //           .unix();
    //         let currentHorario = moment
    //           .tz(
    //             this.calendario + " " + h.inicio,
    //             "DD/MM/YYYY HH:mm",
    //             "Europe/Madrid"
    //           )
    //           .unix();
    //         let currentHorarioFin = moment
    //           .tz(
    //             this.calendario + " " + h.inicio,
    //             "DD/MM/YYYY HH:mm",
    //             "Europe/Madrid"
    //           )
    //           .add(this.config.franjas, "minutes")
    //           .unix();
    //         while (currentHorario < finRango) {
    //           res.push({
    //             text:
    //               moment
    //                 .unix(currentHorario)
    //                 .tz("Europe/Madrid")
    //                 .format("HH:mm") +
    //               " - " +
    //               moment
    //                 .unix(currentHorarioFin)
    //                 .tz("Europe/Madrid")
    //                 .format("HH:mm"),
    //             value: [currentHorario, currentHorarioFin],
    //           });
    //           currentHorario = currentHorarioFin;
    //           currentHorarioFin = moment
    //             .unix(currentHorarioFin)
    //             .add(this.config.franjas, "minutes")
    //             .unix();
    //         }
    //       });
    //     }
    //   }
    //   return res;
    // },
    ...mapState({
      reservas: (state) => state.calendario.reservas,
    }),
    reservasComputed() {
      // return [];
      return this.reservas.map((res) => {
        return {
          name:
            moment.unix(res.reservaInicioUnix).format("HH:mm") +
            " " +
            (res.nombre
              ? res.nombre
              : res.telefono
              ? res.telefono
              : res.email
              ? res.email
              : "Sin más info"),
          start: moment
            .unix(res.reservaInicioUnix)
            .tz("Europe/Madrid")
            .toDate(),
          color: !!res.confirmado ? "primary" : "warning",
          datos: res,
        };
      });
    },
  },
  methods: {
    inputComensales(v) {
      this.$v.nuevaReserva.comensales.$touch();
      if (isNaN(this.nuevaReserva.comensales)) this.nuevaReserva.comensales = 1;
    },
    focusCampo(obj, prop) {
      if (this.$store.state.necesarioTeclado) {
        this.$store.commit("teclado/setMostrarTeclado", true);
        //this.$store.commit("teclado/setLimpiarInput", true);
        console.log(obj);
        console.log(prop);
        this.$store.commit("teclado/setActualValor", obj[prop]);
        this.$store.commit("teclado/setCampoEditarObj", obj);
        this.$store.commit("teclado/setCampoEditarPropiedad", prop);
        setTimeout(() => {
          let pos = obj[prop].length ? obj[prop].length : 0;
          this.$refs[prop].$el
            .querySelector("input")
            .setSelectionRange(pos, pos);
        }, 200);
      }
    },
    minutosPermitidos(v) {
      return v % 5 == 0;
    },
    getEventColor(event) {
      return event.color;
    },
    showEvent(item) {
      this.calendario = moment
        .unix(item.event.datos.reservaInicioUnix)
        .format("YYYY-MM-DD");
      this.nuevaReserva = { ...item.event.datos };
      this.nuevaReservaDialog = true;
    },
    addEvent() {
      this.nuevaReservaDialog = true;
    },
    viewDay({ date }) {
      this.calendario = date;
      this.tipoCal = "day";
    },
    setToday() {
      this.calendario = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    confirmarReservaManual() {
      this.saveReservaLoading = true;
      this.saveReserva(false)
        .then((r) => {
          if (!this.nuevaReserva.id) this.nuevaReserva.id = r.id;
          return this.$store.dispatch("calendario/confirmarReservaManual", {
            ...this.nuevaReserva,
          });
        })
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Reserva confirmada",
          });
          this.nuevaReservaDialog = false;
          this.getReservas();
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al confirmar la reserva: " + e,
          });
        })
        .finally(() => (this.saveReservaLoading = false));
    },
    confirmarReserva() {
      this.saveReservaLoading = true;
      this.saveReserva(false)
        .then((r) => {
          if (!this.nuevaReserva.id) this.nuevaReserva.id = r.id;
          return this.$store.dispatch("calendario/confirmarReserva", {
            ...this.nuevaReserva,
          });
        })
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Reserva confirmada",
          });
          this.nuevaReservaDialog = false;
          this.getReservas();
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al confirmar la reserva: " + e,
          });
        })
        .finally(() => (this.saveReservaLoading = false));
    },
    eliminarReserva() {
      this.$confirm("¿Desea eliminar la reserva? Esta acción es irreversible", {
        buttonTrueText: "Aceptar",
        buttonFalseText: "No",
        color: "warning",
        icon: "warning",
        width: 350,
      }).then((r) => {
        if (r) {
          this.saveReservaLoading = true;
          return this.$store
            .dispatch("calendario/eliminarReserva", this.nuevaReserva.id)
            .then((r) => {
              this.$store.commit("snackbar/setMensajeSuccess", {
                msj: "Reserva eliminada",
              });
              this.nuevaReservaDialog = false;
              return this.getReservas();
            })
            .catch((e) => {
              this.$store.commit("snackbar/setMensajeDanger", {
                msj: "Error al eliminar la reserva: " + e,
              });
            })
            .finally(() => {
              this.saveReservaLoading = false;
            });
        }
      });
    },
    saveReserva(cerrar = true) {
      console.log(this.nuevaReserva);
      if (!this.nuevaReserva.id) this.nuevaReserva.dia = this.calendario;
      this.nuevaReserva.reservaInicioUnix = moment
        .tz(
          this.nuevaReserva.dia + " " + this.nuevaReserva.inicio,
          "YYYY-MM-DD HH:mm",
          "Europe/Madrid"
        )
        .unix();
      console.log(this.nuevaReserva);

      if (cerrar) this.saveReservaLoading = true;
      return this.$store
        .dispatch("calendario/saveReserva", this.nuevaReserva)
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Reserva guardada",
          });
          if (cerrar) {
            this.nuevaReservaDialog = false;
          } else {
            return r;
          }
          this.getReservas();
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al guardar la reserva: " + e,
          });
        })
        .finally(() => {
          if (cerrar) this.saveReservaLoading = false;
        });
    },
    cambioCalendario() {
      this.getReservas();
    },
    getReservas() {
      var fechasBuscar;
      if (!this.calendario || this.calendario.trim().length === 0) {
        fechasBuscar = {
          inicio: moment.tz("Europe/Madrid").startOf("month").unix(),
          fin: moment.tz("Europe/Madrid").endOf("month").unix(),
        };
      } else {
        fechasBuscar = {
          inicio: moment
            .tz(this.calendario, "YYYY/MM/DD", "Europe/Madrid")
            .startOf("month")
            .unix(),
          fin: moment
            .tz(this.calendario, "YYYY/MM/DD", "Europe/Madrid")
            .endOf("month")
            .unix(),
        };
      }

      this.$store
        .dispatch("calendario/getReservasMesViendo", fechasBuscar)
        .then((r) => {
          // this.$store.commit("snackbar/setMensajeSuccess", {
          //   msj: "Reserva guardada",
          // });
          // this.nuevaReservaDialog = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al guardar la reserva: " + e,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.volver--tipo-mes {
  &.v-btn--right {
    right: 30px !important;
  }
  &.v-btn--left {
    left: 90px !important;
  }
}
</style>