var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"overlay-opacity":"0.90","content-class":_vm.$vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs',"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"transparent",attrs:{"rounded":_vm.$vuetify.breakpoint.smAndDown ? '0' : 'lg'}},[_c('v-card-title',{staticClass:"white px-0 py-2",attrs:{"height":"78"}},[_c('v-list-item',{staticStyle:{"max-width":"100%"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-black",class:_vm.$vuetify.breakpoint.smAndDown ? 'body-1' : 'text-h5'},[_vm._v(_vm._s(_vm.mensaje))])],1),_c('v-btn',{attrs:{"fab":"","height":_vm.$vuetify.breakpoint.smAndDown ? 48 : 68,"width":_vm.$vuetify.breakpoint.smAndDown ? 48 : 68,"color":"primary","top":"","right":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? 36 : 44,"color":"black"}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-2 pa-sm-4 blue-grey lighten-5",staticStyle:{"min-height":"400px"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"9","order":"2","order-md":"1"}},[_c('v-row',{staticClass:"pa-1"},_vm._l((_vm.usuarios),function(usuario,i){return _c('v-col',{key:usuario.id,staticClass:"pa-2",attrs:{"cols":"6","sm":"3","value":usuario.id}},[_c('v-card',{staticClass:"text-center",class:_vm.eleccion && _vm.eleccion.id == usuario.id
                      ? 'white--text'
                      : 'black--text',attrs:{"elevation":"4","color":_vm.eleccion && _vm.eleccion.id == usuario.id
                      ? 'success'
                      : 'primary',"rounded":"lg"},on:{"click":function($event){return _vm.elegirUsuario(usuario)}}},[_c('v-responsive',{staticClass:"align-center justify-center body-1 font-weight-black text-uppercase",attrs:{"aspect-ratio":1.618}},[_vm._v(" "+_vm._s(usuario.nombre)+" ")])],1)],1)}),1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(this.eleccion),expression:"this.eleccion"}],attrs:{"cols":"12","md":"4","lg":"3","order":"1","order-md":"3"}},[(_vm.dialog == true)?_c('v-text-field',{ref:"passUsuarioInput",staticClass:"rounded-lg mb-0 mb-sm-4 title",attrs:{"disabled":!_vm.eleccion,"readonly":_vm.$vuetify.breakpoint.mdAndUp,"outlined":"","hide-details":"","solo":"","label":"Contraseña","type":"password","pattern":"[0-9]*","inputmode":"numeric","maxlength":"4","height":"64","error-messages":_vm.respuesta == 'error' ? 'Contraseña incorrecta' : '',"success-messages":_vm.respuesta == 'ok' ? 'Inicio correcto' : '',"append-icon":_vm.respuesta == 'ok'
                  ? 'mdi-check-bold'
                  : _vm.respuesta == 'error'
                  ? 'mdi-close'
                  : ''},on:{"input":function($event){return _vm.$v.passwordUsuario.$touch()},"blur":function($event){return _vm.$v.passwordUsuario.$touch()}},nativeOn:{"keypress":function($event){return _vm.inputOnKey.apply(null, arguments)}},model:{value:(_vm.passwordUsuario),callback:function ($$v) {_vm.passwordUsuario=$$v},expression:"passwordUsuario"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('KeyboardNumMini',{ref:"keyboardRefMini",attrs:{"input":_vm.passwordUsuario,"maxLength":4},on:{"onChange":_vm.onChange,"onKeyPress":_vm.onKeyPress}})],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }