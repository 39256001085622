<template>
  <fullscreen-dialog
    name="productos/productos"
    titulo="Productos"
    :loading="loading"
  >
    <v-row>
      <v-col class="pa-0 elevation-2 productos-tabs" cols="12" height="100px">
        <v-tabs
          v-model="paso"
          center-active
          color="primary"
          centered
          class="pb-4"
        >
          <v-tab active-class="activeTab rounded-0" value="productos"
            >Productos</v-tab
          >

          <v-tab active-class="activeTab rounded-0" value="productos"
            >Opciones</v-tab
          >
        </v-tabs>
      </v-col>
    </v-row>
    <v-row id="productos" v-if="paso == 0">
      <!--<v-subheader>PEDIDO ID: {{pedidoEnCursoComprobar.numPedidoInternos}}</v-subheader>-->
      <v-col class="pa-0 elevation-2 productos-tabs" cols="12">
        <v-tabs
          v-model="eleccionCategoria"
          center-active
          color="dark"
          show-arrows
        >
          <v-tab
            active-class="activeTab rounded-0"
            v-for="(item, i) in categorias"
            :value="item.id"
            :key="item.id"
            >{{ item.nombre }}</v-tab
          >
        </v-tabs>
      </v-col>
      <v-col cols="12" class="pb-0">
        <h1 class="mb-4 mt-2 display-1 text-center">Productos</h1>
      </v-col>
      <v-col cols="12" md="8" xl="6" class="mx-auto pt-0">
        <v-list v-if="!modoPaginacion" elevation="1" class="rounded-lg">
          <v-list-item
            v-for="(p, i) in productos"
            :key="p.id"
            :class="p.global ? 'yellow lighten-4' : ''"
          >
            <v-list-item-title>{{ p.nombre }} </v-list-item-title>
            <v-list-item-action>
              <v-checkbox
                @change="clickCheckbox(p, $event)"
                v-model="p.activo"
              />
              <!-- <v-btn
                icon
                large
                text
                color="primary"
                @click="openDialogTarifa(p)"
                ><v-icon large>mdi-information</v-icon></v-btn
              > -->
              <!-- <v-btn
                rounded
                large
                :color="colorBarcode(p)"
                class="black--text"
                @click="openBarcode(p)"
                ><v-icon large>mdi-barcode</v-icon></v-btn
              >-->
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list v-else elevation="1" class="rounded-lg">
          <v-list-item
            v-for="(p, i) in productosPaginados"
            :key="p.id"
            :class="i !== 0 ? 'border--list-item' : ''"
          >
            <v-list-item-title>{{ p.nombre }}</v-list-item-title>
            <v-list-item-action>
              <v-checkbox
                @change="clickCheckbox(p, $event)"
                v-model="p.activo"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-dialog
        v-if="barcodeDialog"
        v-model="barcodeDialog"
        max-width="480px"
        persistent
        overlay-opacity="0.90"
      >
        <v-card class="transparent">
          <v-card-title
            :class="
              $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-t-lg'
            "
            class="py-3 pr-3 white"
          >
            <h2
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'caption'
                  : $vuetify.breakpoint.mdAndDown
                  ? 'title'
                  : 'display-1'
              "
            >
              {{ barcodeProducto.nombre }}
            </h2>
            <v-spacer />
            <v-btn
              fab
              :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
              :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
              color="primary"
              @click="barcodeDialog = false"
              top
              right
            >
              <v-icon
                :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                color="black"
                >mdi-close</v-icon
              >
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="blue-grey lighten-5 pt-4">
            <v-text-field
              ref="codigoRef"
              v-model="codigoLector"
              label="Código Lector"
              clear-icon="mdi-close-circle"
              clearable
            />
          </v-card-text>
          <v-card-actions class="blue-grey lighten-5 rounded-b-lg">
            <v-spacer />
            <v-btn color="primary black--text" @click="saveCodigo"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="viendoProductoDialog"
        v-model="viendoProductoDialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            {{ viendoProductoItemComputed.nombreMostrar }}
          </v-card-title>
          <v-card-text>
            <v-tabs
              v-model="catalogoViendo"
              show-arrows
              background-color="primary"
              center-active
              vertical
            >
              <v-tab
                v-for="t in viendoProductoItemComputed.catalogo"
                :key="t.catalogo"
              >
                {{ t.catalogo }}
              </v-tab>
              <v-tab-item
                v-for="t in viendoProductoItemComputed.catalogo"
                :key="`cuerpo_${t.catalogo}`"
              >
                <v-card flat>
                  <v-card-title> {{ t.catalogo }}</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-switch v-model="t.activo" label="¿Activo?"
                      /></v-col>
                      <v-col cols="6">
                        <v-text-field v-model="t.precio" label="Precio"
                      /></v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
            <v-tabs-items v-model="catalogoViendo"> </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row id="modificadores" v-if="paso == 1">
      <v-col class="pa-0 elevation-2 productos-tabs" cols="12">
        <v-tabs
          v-model="eleccionModificador"
          center-active
          color="dark"
          show-arrows
        >
          <v-tab
            active-class="activeTab rounded-0"
            v-for="(item, i) in modificadores"
            :value="item.id"
            :key="item.id"
            >{{ item.nombreMostrar }}</v-tab
          >
        </v-tabs>
      </v-col>
      <v-col cols="12" class="pb-0">
        <h1 class="mb-4 mt-2 display-1 text-center">Opciones</h1>
      </v-col>
      <v-col cols="12" md="8" xl="6" class="mx-auto pt-0">
        <v-list v-if="!modoPaginacion" elevation="1" class="rounded-lg">
          <v-list-item
            v-for="(p, i) in modificadoresOpciones"
            :key="p.id"
            :class="p.global ? 'yellow lighten-4' : ''"
          >
            <v-list-item-title>{{ p.nombreMostrar }} </v-list-item-title>
            <v-list-item-action>
              <v-checkbox
                @change="clickCheckboxModificador(p, $event)"
                v-model="p.activo"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list v-else elevation="1" class="rounded-lg">
          <v-list-item
            v-for="(p, i) in productosPaginados"
            :key="p.id"
            :class="i !== 0 ? 'border--list-item' : ''"
          >
            <v-list-item-title>{{ p.nombre }}</v-list-item-title>
            <v-list-item-action>
              <v-checkbox
                @change="clickCheckbox(p, $event)"
                v-model="p.activo"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </fullscreen-dialog>
</template>

<script>
import FullscreenDialog from "./FullscreenDialog.vue";
import { mapGetters, mapState } from "vuex";
import { mostrarIdioma } from "@/utils/helper.utils.js";
import { chunkArray } from "@/utils/helper.utils.js";
export default {
  components: { FullscreenDialog },
  data() {
    return {
      paso: "productos",
      loading: false,
      catalogoViendo: 0,
      viendoProductoDialog: false,
      viendoProductoItem: null,
      eleccionCategoria: 0,
      eleccionModificador: 0,
      barcodeProducto: null,
      barcodeDialog: false,
      codigoLector: "",
    };
  },
  watch: {
    eleccionCategoria(v) {
      this.$store.commit("setPaginaPaginacionProductosListado", 0);
    },
    barcodeDialog(v) {
      if (!v) {
        this.barcodeProducto = null;
        this.codigoLector = "";
      } else {
        setTimeout(() => {
          this.$refs.codigoRef.focus();
        }, 100);
      }
    },
  },
  computed: {
    modificadores() {
      return this.modificadoresBruto.map((m) => {
        console.log(m);
        return {
          ...m,
          nombreMostrar:
            Array.isArray(m.nombre) && m.nombre.length > 0
              ? mostrarIdioma(m.nombre)
              : "",
        };
      });
    },
    modificadoresOpciones() {
      return this.modificadores[this.eleccionModificador] &&
        this.modificadores[this.eleccionModificador].opciones
        ? this.modificadores[this.eleccionModificador].opciones.map((o) => {
            return {
              ...o,
              nombreMostrar: mostrarIdioma(o.nombre),
            };
          })
        : [];
    },
    productosPaginados() {
      let productos = chunkArray(
        this.productos,
        this.productosPaginacionProductosListado
      );
      let res = productos[this.paginaPaginacionProductosListado];
      this.$store.commit(
        "setMaximoPaginacionProductosListado",
        productos.length
      );
      this.$store.commit(
        "setMostrandoPaginacionProductosListado",
        res ? res.length : 0
      );
      return res;
    },
    eleccionCategoriaComputed() {
      return this.categoriasBruto &&
        this.categoriasBruto[this.eleccionCategoria]
        ? this.categoriasBruto[this.eleccionCategoria].id
        : "";
    },
    eleccionModificadoraComputed() {
      return this.modificadores && this.modificadores[this.eleccionModificador]
        ? this.modificadores[this.eleccionModificador].id
        : "";
    },
    ...mapState([
      "modoPaginacion",
      "productosPaginacionProductosListado",
      "paginaPaginacionProductosListado",
    ]),
    ...mapState({
      restauranteObj: "restauranteObj",
      categoriasBruto: (state) => state.productos.categorias,
      productosBruto: (state) => state.productos.productosArray,
    }),
    ...mapGetters({
      productosRest: "productos/PRODUCTOS_RESTAURANTE_LISTADO",
      modificadoresBruto: "productos/MODIFICADORES",
    }),
    categorias() {
      return this.categoriasBruto.map((c) => {
        return {
          ...c,
          nombre: mostrarIdioma(c.nombre),
        };
      });
    },
    productos() {
      return this.productosRest
        .filter((p) => p.categoria == this.eleccionCategoriaComputed)
        .map((p) => {
          return {
            ...p,
            nombre: mostrarIdioma(p.nombre),
          };
        })
        .sort(function (a, b) {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        });
    },
    viendoProductoItemComputed() {
      return {
        ...this.viendoProductoItem,
        nombreMostrar:
          "Viendo las tarifas de " + this.viendoProductoItem.nombre,
      };
    },
  },
  methods: {
    clickCheckboxModificador(p, valorBool) {
      console.log(p);
      console.log(valorBool);
      const aux = JSON.parse(
        JSON.stringify(this.$store.state.productos.productosCatalogos)
      );
      console.log(aux);
      aux.map((cat) => {
        let fI = cat.productos
          ? cat.productos.findIndex((pCat) => pCat.id == p.id)
          : -1;
        if (fI > -1) {
          cat.productos[fI].activo = !!valorBool;
        } else {
          if (Array.isArray(cat.productos)) {
            cat.productos.push({
              activo: valorBool,
              global: false,
              id: p.id,
              precio: p.precioCobrar
                ? p.precioCobrar
                : p.precio
                ? p.precio
                : null,
            });
          } else {
            cat.productos = [
              {
                activo: valorBool,
                global: false,
                id: p.id,
                precio: p.precioCobrar
                  ? p.precioCobrar
                  : p.precio
                  ? p.precio
                  : null,
              },
            ];
          }
        }
      });
      console.log(aux);
      this.$store.dispatch("productos/setTodosCatalogo", aux);
    },
    clickCheckbox(p, valorBool) {
      const aux = JSON.parse(
        JSON.stringify(this.$store.state.productos.productosCatalogos)
      );
      console.log(aux);
      aux.map((cat) => {
        let fI = cat.productos
          ? cat.productos.findIndex((pCat) => pCat.id == p.id)
          : -1;
        if (fI > -1) {
          cat.productos[fI].activo = !!valorBool;
        } else {
          if (Array.isArray(cat.productos)) {
            cat.productos.push({
              activo: valorBool,
              global: false,
              id: p.id,
              precio: p.precioCobrar ? p.precioCobrar : p.precio,
            });
          } else {
            cat.productos = [
              {
                activo: valorBool,
                global: false,
                id: p.id,
                precio: p.precioCobrar ? p.precioCobrar : p.precio,
              },
            ];
          }
        }
      });
      console.log(aux);
      this.$store.dispatch("productos/setTodosCatalogo", aux);
    },
    colorBarcode(p) {
      return !!p.codigoLector && p.codigoLector.length > 0
        ? "primary"
        : "red ligthen-2";
    },
    saveCodigo() {
      //
      this.$store
        .dispatch("productos/saveCodigo", {
          producto: this.barcodeProducto,
          codigo: this.codigoLector,
        })
        .then(() => {
          this.barcodeProducto.codigoLector = this.codigoLector;
          this.barcodeDialog = false;
          this.$store.dispatch("productos/getProductos");
        });
    },
    openBarcode(p) {
      this.codigoLector = p.codigoLector;
      this.barcodeProducto = p;
      this.barcodeDialog = true;
    },
    openDialogTarifa(p) {
      this.viendoProductoItem = p;
      this.viendoProductoDialog = true;
    },
  },
  mounted() {
    this.$store.commit("setPaginacionManejando", "productosListado");

    // this.$store.dispatch("productos/getProductos");
  },
};
</script>

<style>
</style>