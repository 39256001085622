<template>
  <v-snackbar
    bottom
    left
    v-if="trabajosContables.length > 0"
    color="primary"
    :timeout="0"
  >
    <v-list flat color="transparent" class="black--text">
      <span>Trabajos contables</span>
      <v-list-item
        v-for="(t, i) in trabajosContables"
        :key="i"
        class="black--text"
      >
        <span class="black--text">{{ t.nombre }}</span>
      </v-list-item>
    </v-list>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      trabajosContablesBruto: (state) => state.contable.trabajosContables,
    }),
    trabajosContables() {
      return this.trabajosContablesBruto;
    },
  },
  mounted() {
    this.$store.dispatch("contable/getNumerosContables");
  },
};
</script>

<style>
</style>