import { db, storage, functions } from "@/firebase";
import { redondeo } from "@/utils/helper.utils.js";

export default {
  getProductos({ commit, rootState }) {
    let negocio = rootState.auth.user.negocioTpv;
    return db
      .collection("negocios")
      .doc(negocio)
      .collection("productos")
      .onSnapshot((res) => {
        commit(
          "setProductosArray",
          res.docs.map((r) => {
            return {
              ...r.data(),
              id: r.id,
            };
          })
        );
      });
    /*.then((res) => {
      var actions = res.map(function (item) {
        if (item.imagen) {
          return item;
        } else {
          var storageRef = storage.ref('imagenes/productos/' + item.id + '.jpg');
          //var spaceRef = storageRef.child('imagenes/caracteristicas/' + element.id + '.jpg');
          return storageRef.getDownloadURL()
            .then((r) => {
              item.imagen = r;
              return item;
            })
            .catch((e) => {
              item.imagen = false;
              return item;
            })
        }
      });
      return Promise.all(actions)
        .then((r) => {
          commit('setProductosArray', r)
        })
        .catch((e) => {
          console.log(e);
        })
    })*/
  },
  getCategorias({ commit, rootState }) {
    let negocio = rootState.auth.user.negocioTpv;
    return db
      .collection("negocios")
      .doc(negocio)
      .collection("categorias")
      .get()
      .then((r) => {
        let res = [];
        let docs = r.docs;
        docs.forEach((e) => {
          let d = e.data();
          d.id = e.id;
          res.push(d);
        });
        commit("setCategorias", res);
      });
  },
  getTodosCatalogos({ state, dispatch, getters }) {
    return Promise.all(
      state.catalogos.map((c) => {
        return dispatch("getCatalogo", c.value);
      })
    )
      .then(() => {
        let tarifas = getters["TARIFAS_EFECTIVAS"];
        return Promise.all(
          tarifas.map((t) => {
            //return dispatch('getProductosTarifa', t)
          })
        );
      })
      .catch((e) => console.log(e));
  },
  getProductosTarifa({ rootState, commit }, tarifa) {
    let negocio = rootState.negocioObj;
    return new Promise((resolve, reject) => {
      var resolveOnce = (doc) => {
        resolveOnce = () => void resolve(doc);
      };
      let realtime = db
        .collection("negocios")
        .doc(negocio.id)
        .collection("tarifasPublicas")
        .doc(tarifa)
        .collection("productos")
        .onSnapshot((r) => {
          commit("setProductosTarifa", {
            productos: r.docs.map((p) => {
              return {
                ...p.data(),
                id: p.id,
              };
            }),
            tarifa: tarifa,
          });
          resolve();
        });
    });
  },
  getCatalogo({ commit, rootState }, catalogo) {
    let rest = rootState.auth.user.restauranteTpv;
    return new Promise((resolve, reject) => {
      var resolveOnce = (doc) => {
        resolveOnce = () => void resolve(doc);
      };
      return db
        .collection("restaurantes")
        .doc(rest)
        .collection("catalogos")
        .doc(catalogo)
        .onSnapshot((r) => {
          if (r.exists) {
            commit("setProductosCatalogo", {
              catalogo: catalogo,
              tarifa: r.data().tarifa,
              productos: r.exists ? r.data().productos : [],
            });
          }
          resolve();
        });
    });
  },
  setCatalogo({ rootState }, { catalogo, productos }) {
    let rest = rootState.auth.user.restauranteTpv;
    return db
      .collection("restaurantes")
      .doc(rest)
      .collection("catalogos")
      .doc(catalogo)
      .set(
        {
          productos: productos,
        },
        { merge: true }
      );
  },
  setTodosCatalogo({ state, dispatch }, data) {
    return Promise.all(
      data.map((c) => {
        return dispatch("setCatalogo", c);
      })
    );
  },
  //cambia la tarifa asociada al modo.
  setTarifa() {},
  saveCodigo({ rootState, dispatch }, { producto, codigo }) {
    let negocio = rootState.auth.user.negocioTpv;
    return db
      .collection("negocios")
      .doc(negocio)
      .collection("productos")
      .doc(producto.id)
      .set({ codigoLector: codigo }, { merge: true });
    //.finally(() => dispatch('iniciarCuentaGenerarCatalogo'))
  },
  iniciarCuentaGenerarCatalogo({ state, commit, dispatch }) {
    commit("setGenerarCatalogoSnackbar", true);

    if (!!state.generarCatalogoTimeout)
      clearTimeout(state.generarCatalogoTimeout);
    if (!!state.generarCatalogoTimeoutContador)
      clearTimeout(state.generarCatalogoTimeoutContador);
    dispatch("iniciarCuentaGenerarCatalogoContador");
    commit(
      "setGenerarCatalogoTimeout",
      setTimeout(() => {
        dispatch("generarCatalogo");
      }, 10000)
    );
  },
  iniciarCuentaGenerarCatalogoContador({ state, commit, dispatch }) {
    const funcContador = () => {
      commit("setGenerarCatalogoSegundos", state.generarCatalogoSegundos - 1);
      commit(
        "setGenerarCatalogoTimeoutContador",
        setTimeout(funcContador, 1000)
      );
    };
    funcContador();
  },
  generarCatalogoResetear({ state, commit }) {
    if (!!state.generarCatalogoTimeout)
      clearTimeout(state.generarCatalogoTimeout);
    if (!!state.generarCatalogoTimeoutContador)
      clearTimeout(state.generarCatalogoTimeoutContador);
    commit("setGenerarCatalogoSegundos", state.generarCatalogoSegundosInicial);
  },
  generarCatalogo({ rootState, dispatch, commit }) {
    let negocio = rootState.auth.user.negocioTpv;
    var f = functions.httpsCallable("httpNegociosGenerarCatalogos");

    return f({ negocio: negocio }).finally(() => {
      dispatch("generarCatalogoResetear");
      commit("setGenerarCatalogoSnackbar", false);
    });
  },
  //CONSTRUCCION PRODUCTOS V2.6
  generarProductosCatalogos({ rootState, dispatch, commit, state, getters }) {
    let negocio = rootState.auth.user.negocioTpv;
    var productosBruto;
    var modificadores;
    var grupoModificadores;
    var menus;
    var catalogos;
    var impuestos;
    var descuentos;
    var caracteristicas;
    var tarifas;
    var t0 = performance.now();
    return Promise.all([
      db
        .collection("caracteristicas")
        .get()
        .then((res) => {
          caracteristicas = res.docs.map((c) => {
            return {
              ...c.data(),
              id: c.id,
            };
          });
        }),
      db
        .collection("negocios")
        .doc(negocio)
        .collection("descuentos")
        .get()
        .then((res) => {
          descuentos = res.docs.map((d) => {
            return {
              ...d.data(),
              id: d.id,
            };
          });
        }),
      db
        .collection("impuestos")
        .get()
        .then((res) => {
          impuestos = res.docs.map((i) => {
            return {
              ...i.data(),
              id: i.id,
            };
          });
        }),

      db
        .collection("negocios")
        .doc(negocio)
        .collection("productos")
        .get()
        .then((res) => {
          productosBruto = res.docs.map((p) => {
            return {
              ...p.data(),
              id: p.id,
            };
          });
          commit(
            "SET_MODIFICADORES_OPCIONES",
            productosBruto.filter((p) => !p.individualPedible)
          );
        }),
      db
        .collection("negocios")
        .doc(negocio)
        .collection("productosMenus")
        .get()
        .then((res) => {
          menus = res.docs.map((g) => {
            return {
              ...g.data(),
              id: g.id,
            };
          });
        }),
      db
        .collection("negocios")
        .doc(negocio)
        .collection("productosGruposModificadores")
        .get()
        .then((res) => {
          grupoModificadores = res.docs.map((g) => {
            return {
              ...g.data(),
              id: g.id,
            };
          });
        }),
      db
        .collection("negocios")
        .doc(negocio)
        .collection("productosModificadores")
        .get()
        .then((res) => {
          modificadores = res.docs.map((m) => {
            return {
              ...m.data(),
              id: m.id,
            };
          });
          commit("SET_MODIFICADORES", modificadores);
        }),
      db
        .collection("negocios")
        .doc(negocio)
        .collection("productosTarifas")
        .get()
        .then((res) => {
          tarifas = res.docs.map((m) => {
            return {
              ...m.data(),
              id: m.id,
            };
          });
        }),
    ]).then(() => {
      const productosPedibles = [
        ...productosBruto.filter((pb) => pb.individualPedible),
        ...menus,
      ];
      var tarifasPublicasGuardar = tarifas.map((t) => {
        return {
          tarifa: t.id,
          productos: [],
        };
      });
      productosPedibles.map((prdGeneralInc) => {
        let cat = state.categorias.find((c) => c.id == prdGeneralInc.categoria);
        console.log(cat);
        var prodIncluir = {
          ...prdGeneralInc,
          ordenCat: cat ? cat.orden : null,
        };
        /************************** INICIO Bloque de CARACTERÍSTICAS **************************/
        let cs = prdGeneralInc.caractSeleccionadas;

        if (!cs) cs = [];
        let caracts = [];
        cs.forEach((c) => {
          let find_c = caracteristicas.find((e) => e.id == c);
          if (find_c) {
            let find_grupo = caracts.find((e) => e.grupo == find_c.grupo);
            if (find_grupo) {
              find_grupo.caracteristicas.push({
                nombre: find_c.nombre,
                id: find_c.id,
                img: find_c.imagen ? find_c.imagen : "",
              });
            } else {
              let aux = {
                grupo: find_c.grupo,
                caracteristicas: [
                  {
                    nombre: find_c.nombre,
                    id: find_c.id,
                    img: find_c.imagen ? find_c.imagen : "",
                  },
                ],
              };
              caracts.push(aux);
            }
          }
        });
        prodIncluir.caractSeleccionadas = caracts;
        /************************** INICIO Bloque de PRECIOS **************************/
        if (Array.isArray(prdGeneralInc.tarifas)) {
          // console.log(catalogos)
          tarifas.map((tGlobal) => {
            var foundTarifa = prdGeneralInc.tarifas.find(
              (tPrd) => tPrd.id == tGlobal.id
            );
            if (foundTarifa) {
              // console.log(foundTarifa);
              /************************** INICIO Bloque de IMPUESTOS **************************/
              let foundImp = impuestos.find(
                (i) => i.id == foundTarifa.impuesto
              );
              if (foundImp) {
                prodIncluir.impPorcentaje = parseFloat(foundImp.impuesto);
                prodIncluir.impuesto = foundTarifa.impuesto;
              }

              // var precioCobrar = 0; //será para dspués de aplicar descuentos, se calcula en cliente
              // var activo = false;
              prodIncluir.precio = redondeo(parseFloat(foundTarifa.precio));
              prodIncluir.activo = !!foundTarifa.activo;

              /**************************** OPCIONES ****************************/
              const grupoModificador = prodIncluir.grupoModificador;

              if (!!grupoModificador) {
                var foundGrupoModificador = grupoModificadores.find(
                  (g) => g.id == grupoModificador
                );
                if (!!foundGrupoModificador) {
                  prodIncluir.mostrarSiempreTpv =
                    !!foundGrupoModificador.mostrarSiempreTpv;
                  prodIncluir.opciones =
                    foundGrupoModificador.modificadores.map((mod) => {
                      var foundModificador = modificadores.find(
                        (m) => m.id == mod.id
                      );
                      if (!!foundModificador) {
                        // console.log(foundModificador)
                        // console.log(mod);
                        delete foundModificador.descripcionInterna;
                        return {
                          ...foundModificador,
                          ...mod,
                          opciones: foundModificador.opciones
                            .map((op) => {
                              var foundTarifa = op.tarifas.find(
                                (t) => t.id == tGlobal.id
                              );
                              var foundPrdOpcion = productosBruto.find(
                                (p) => p.id == op.id
                              ); //se busca el producto generall para sacar nombres y descripciíon
                              let foundOp = mod.opciones.find((opMod) =>
                                opMod.id.includes(op.id)
                              );
                              var opPrecioCobrar = 0; //será para dspués de aplicar descuentos, se calcula en cliente
                              var opActivo = false;
                              //buscamos el catálogo del restaurante
                              opPrecioCobrar = !!foundTarifa
                                ? redondeo(parseFloat(foundTarifa.precio))
                                : 0;
                              opActivo = !!foundTarifa && !!foundTarifa.activo;

                              //if (!!foundOp)
                              //delete foundOp.tarifas;
                              return {
                                nombre: foundPrdOpcion.nombre,
                                nombreTpv: !!foundPrdOpcion.nombreTpv
                                  ? foundPrdOpcion.nombreTpv
                                  : false,
                                descripcion: foundPrdOpcion.descripcion,
                                ...op,
                                ...(foundOp ? foundOp : {}),
                                precioCobrar: !!opPrecioCobrar
                                  ? opPrecioCobrar
                                  : 0,
                                activo: opActivo,
                              };
                              // }
                            })
                            .filter((op) => op && op.activo && !op.ocultar),
                        };
                      } else {
                        return false;
                      }
                    });
                }
              } else {
                prodIncluir.opciones = null;
              }
              //agregar el producto a la tarifa
              //delete prodIncluir.individualPedible;
              //delete prodIncluir.grupoModificador;
              //delete prodIncluir.modificador;
              //delete prodIncluir.tarifas;
              // console.log(prodIncluir)
              tarifasPublicasGuardar
                .find((tPub) => tPub.tarifa == tGlobal.id)
                .productos.push({ ...prodIncluir });
            }
          });

          // prdGeneral.tarifas.map(t => {
          //     var foundCatalogo = catalogos.find(cat => cat.tarifa == t.id);
          //     console.log(foundCatalogo.tarifa);
          // })
        }
      });
      // console.log(catalogosPublicosGuardar)
      var t2 = performance.now();

      commit("setProductosTarifaTodos", tarifasPublicasGuardar);
      //return dispatch('getTodosCatalogos')
      return Promise.all(
        state.catalogos.map((c) => {
          return dispatch("getCatalogo", c.value);
        })
      );
    });
  },
};
