export default {
    ficheroCashguardContenidoFicheroArray(state) {
        // state.ficheroCashguardContenidoFichero
        let prueba = "Importe_Pagado:500 Importe_Operacion:290 Importe_Devuelto:-210\nImporte_Pagado:500 Importe_Operacion:290 Importe_Devuelto:-210"
        let r = state.ficheroCashguardContenidoFichero.split('\n')
            .filter(l => l.length > 0)
            .map(l => {
                let lineaArray = l.split(' ')
                return [
                    parseFloat(lineaArray[0].replace('Importe_Pagado:', '')) / 100,
                    parseFloat(lineaArray[1].replace('Importe_Operacion:', '')) / 100,
                    parseFloat(lineaArray[2].replace('Importe_Devuelto:', '')) / 100
                ]
            })
        console.log(r)
        return r;
    },
    ficheroCashguardContenidoFicheroArrayUltimo(state, getters) {
        // state.ficheroCashguardContenidoFichero
        return getters.ficheroCashguardContenidoFicheroArray.length > 0 ? getters.ficheroCashguardContenidoFicheroArray[getters.ficheroCashguardContenidoFicheroArray.length - 1] : ['-', '-', '-']

    }
}