export default {
  clientesArray: [],
  clientesFiltrados: [],
  filtro: {
    fid: "",
    ftelefono: "",
    fnif: "",
    fapellidos: "",
  },
  clienteDialog: false,
  direccionesDialog: false,
  clienteDialogFullscreen: false,
  pasoCliente: "buscar", //buscar, formDatos, formFactura, direcciones
  dialogoClientes: false, //depreciar, info diialogo dialoogo de la sección clientes
  editarCliente: {},
  clienteNuevo: {
    id: null,
    fecha: "",
    phoneNumber: "",
    restaurantes: [],
    canalRegistro: "tpv",
    direcciones: [],
    displayName: "",
    email: "",
    role: "cliente",
    localNombre: null,
    localTelefono: "",
    localApellido1: "",
    localApellido2: "",
    localNif: "",
    facturacionRazonSocial: "",
    facturacionDireccion: "",
    facturacionNifCif: "",
    facturacionCp: "",
    facturacionDireccion: "",
    facturacionMunicipio: "",
    facturacionProvincia: "",
  },
  //historial pedidos
  historialPedidosDialog: false,
  historialPedidos: [],
};
