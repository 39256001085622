<template>
  <v-row v-if="modoPaginacion && mostrarPaginacion">
    <v-spacer />

    <v-btn
      :disabled="disabledIzq"
      x-large
      color="primary black--text"
      class="mr-2 rounded-lg"
      @click="cambiarPagina(-1)"
      ><v-icon large>mdi-arrow-left-bold</v-icon></v-btn
    >
    <!-- <span class="mx-3">{{ paginacionMostrar }}</span> -->
    <div class="mx-2 text-center">
      <div class="text-h5">
        {{ paginaActualPaginacion.actual + 1 }}/{{
          paginaActualPaginacion.maximo
        }}
      </div>
      <!-- <div class="caption">
            ({{ paginaActualPaginacion.mostrando }} mesas)
          </div> -->
    </div>
    <v-btn
      :disabled="disabledDer"
      x-large
      color="primary black--text"
      class="ml-2 rounded-lg"
      @click="cambiarPagina(1)"
      ><v-icon large>mdi-arrow-right-bold</v-icon></v-btn
    >
    <v-spacer />
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["modoPaginacion"]),
    ...mapGetters(["paginaActualPaginacion"]),

    disabledIzq() {
      return this.paginaActualPaginacion.actual == 0;
    },
    disabledDer() {
      return (
        this.paginaActualPaginacion.actual + 1 ==
          this.paginaActualPaginacion.maximo ||
        this.paginaActualPaginacion.maximo == 0
      );
    },
    mostrarPaginacion() {
      return (
        this.paginaActualPaginacion.actual + 1 <=
        this.paginaActualPaginacion.maximo
      );
    },
  },
  methods: {
    cambiarPagina(i) {
      this.$store.dispatch("cambiarPagina", i);
    },
  },
};
</script>

<style>
</style>