import firebase from 'firebase';
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC6PsmSMSnwgljnMEpzCaVRCTR-oQgT7T4",
  authDomain: "fun-eat-produccion.firebaseapp.com",
  databaseURL: "https://fun-eat-produccion.firebaseio.com",
  projectId: "fun-eat-produccion",
  storageBucket: "fun-eat-produccion.appspot.com",
  messagingSenderId: "199593138345",
  appId: "1:199593138345:web:ceacb4a244a43512c4b1b8",
  measurementId: "G-KK3M8BGV0R"
};
firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'development') {
  firebase.functions().useFunctionsEmulator('http://localhost:5000')
} else {

}

const projectId = "fun-eat-produccion";
firebase.firestore().enablePersistence()
  .catch((err) => {
    console.log(err);
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
// firebase.firestore().disableNetwork()
const db = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();
export { db, functions, storage, projectId };