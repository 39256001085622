var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"6","sm":"4","md":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg ficha-generica",attrs:{"disabled":_vm.disabledNuevo,"elevation":"4","color":hover ? 'primary lighten-4' : 'primary borde-blanco'},on:{"click":_vm.nuevoPedido}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-card-text',{staticClass:"text-uppercase font-weight-black text-center",class:_vm.$vuetify.breakpoint.mdAndDown ? '' : 'text-h4'},[_c('v-row',{staticStyle:{"flex-direction":"column"},attrs:{"justify":"center","align":"center"}},[(!_vm.disabledNuevo)?_c('v-btn',{attrs:{"outlined":"","fab":"","x-small":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('div',{staticClass:"font-weight-bold body-2 black--text"},[(!_vm.disabledNuevo)?_c('span',[_vm._v("Nuevo Pedido interno")]):_c('span',[_vm._v("(Pedido en curso)")])])],1)],1)],1)],1)]}}])})],1),_vm._l((_vm.pedidos),function(ped){return _c('v-col',{key:ped.id,staticClass:"pa-2",attrs:{"cols":"6","sm":"4","md":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",class:ped.clase,attrs:{"elevation":"4","color":hover ? 'secondary lighten-4' : 'borde-blanco'},on:{"click":function($event){return _vm.goPedido(ped)}}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-img',{attrs:{"src":ped.img}},[_c('v-card-text',{staticClass:"text-uppercase font-weight-black text-center pa-0",staticStyle:{"height":"100%"}},[_c('v-row',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"font-weight-bold body-2 my-auto"},[_c('span',[_vm._v(_vm._s(ped.fechaInicio))])])],1),_c('div',{staticClass:"\n                    card--numero-pedido\n                    mt-lg-2\n                    mr-lg-1\n                    pa-2\n                    font-weight-black\n                    cyan--text\n                    text--lighten-2\n                  ",style:(_vm.$vuetify.breakpoint.mdAndDown
                      ? 'font-size: 2rem !important;'
                      : _vm.$vuetify.breakpoint.lgAndUp
                      ? 'font-size: 3.2rem !important;'
                      : '')},[(ped.numPedidoInternos <= 9)?_c('span',[_vm._v("0")]):_vm._e(),_vm._v(_vm._s(ped.numPedidoInternos)+" ")])],1)],1)],1)],1)]}}],null,true)})],1)})],2),_c('v-footer',{staticClass:"pa-0",attrs:{"height":"78","app":""}},[_c('v-toolbar',{staticClass:"ma-0",attrs:{"dark":"","height":"78","flat":"","fluid":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }