<template>
  <v-dialog
    v-model="historialPedidosDialog"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="
      $vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'
    "
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title class="white py-3 pr-3" height="78">
        <!-- <v-icon color="info" class="mr-4"> mdi-swap-horizontal-bold </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          HISTORIAL DEL CLIENTE - {{ tituloPidiendo }}
        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="historialPedidosDialog = false"
          top
          right
        >
          <v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 44" color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4 blue-grey lighten-5">
        <pedidos-lista :pedidos="pedidos" /> </v-card-text></v-card
  ></v-dialog>
</template>

<script>
import { mapState } from "vuex";
import PedidosLista from "../views/PedidosLista.vue";
export default {
  components: { PedidosLista },
  watch: {
    historialPedidosDialog(v) {
      console.log(this.pedidoEditandoObj.uid);
      if (v && this.pedidoEditandoObj.uid) {
        this.$store.dispatch(
          "clientes/getHistorialPedidos",
          this.pedidoEditandoObj.uid
        );
      } else if (!v) {
        this.$store.commit("clientes/setHistorialPedidos", []);
      }
    },
  },
  computed: {
    ...mapState({
      pedidos: (state) => state.clientes.historialPedidos,
    }),
    tituloPidiendo() {
      var res = "";
      if (!!this.pedidoEditandoObj) {
        if (this.pedidoEditandoObj.copyUser)
          res = this.pedidoEditandoObj.copyUser.telefono;
      }
      return res;
    },
    numeroPidiendo() {
      return this.pedidoEditandoObj
        ? this.pedidoEditandoObj.numPedidoRestDia
        : "-";
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
    },
    historialPedidosDialog: {
      get() {
        return this.$store.state.clientes.historialPedidosDialog;
      },
      set(v) {
        this.$store.commit("clientes/setHistorialPedidosDialog", v);
      },
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style>
</style>