export default {
    setImpresoras(state, a) {
        state.impresoras = a
    },
    setBalanza(state, b) {
        state.balanza = b;
    },
    setReaderBalanza(state, r) {
        state.readerBalanza = r;
    },
    setLeerBalanza(state, b) {
        state.leerBalanza = b;
    },
    setPesoBalanza(state, i) {
        state.pesoBalanza = i;
    },
    setCodigoLector(state, s) {
        state.codigoLector = s;
    },
    setDispositivoEnUso(state, d) {
        state.dispositivosEnUso.indexOf(d) === -1 ? state.dispositivosEnUso.push(d) : true
    },
    setDispositivoLibre(state, d) {
        const index = state.dispositivosEnUso.indexOf(d);
        if (index > -1) {
            state.dispositivosEnUso.splice(index, 1);
        }
    }

}