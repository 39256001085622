<template>
  <v-app id="inspire">
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center" v-if="!logged">
          <v-col cols="12">
            <v-row dense justify="center">
              <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-4">
                  <v-toolbar color="black" dark flat>
                    <v-toolbar-title>
                      <v-img
                        class="pa-0"
                        src="/img/logoFunEats.svg"
                        lazy-src="/img/logoFunEats.svg"
                        contain
                        :height="isMobile ? 24 : 38"
                        width="150"
                      ></v-img>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        v-model="email"
                        label="Email"
                        name="login"
                        prepend-icon="mdi-account"
                        type="text"
                        :error-messages="emailErrors"
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                        @keyup.enter="submit"
                      />
                      <v-text-field
                        class="text-left"
                        v-model="password"
                        id="password"
                        label="Contraseña"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        :error-messages="passErrors"
                        name="password"
                        prepend-icon="mdi-lock"
                        @input="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        @keyup.enter="submit"
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="submit"
                      :loading="loadingForm"
                      >ENTRAR</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense justify="center" v-if="mostrarAlerta">
              <v-col cols="12" sm="8" md="6">
                <v-alert type="error"
                  >Los datos introducidos son incorrectos</v-alert
                >
              </v-col></v-row
            ></v-col
          ></v-row
        >

        <v-row v-else>
          <v-spacer />
          <v-col cols="6">
            <v-alert color="warning">
              Estás logueado pero no eres usuario TPV. Deslogueate o ve a la
              página de tu rol
              <v-btn text @click="logout" :loading="loadingLogout">SALIR</v-btn>
            </v-alert>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  props: {
    source: String,
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(4) },
  },
  data() {
    return {
      mostrarAlerta: false,
      show1: false,
      email: "",
      password: "",
      loadingForm: false,
      loadingLogout: false,
    };
  },
  computed: {
    ...mapState({
      logged: (state) => state.auth.isLogged,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    passErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Contraseña requerida.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Email no válido");
      !this.$v.email.required && errors.push("E-mail requerido");
      return errors;
    },
  },
  methods: {
    firebaseLogin(email, password) {
      this.mostrarAlerta = false;
      let obj = {
        email: email,
        password: password,
      };
      this.loadingForm = true;
      this.$store
        .dispatch("auth/firebaseLogin", obj)
        .then((r) => {
          let role = this.$store.state.auth.user.role;
          if (role == "tpv") {
            this.$router.push("/dashboard");
          } else if (role == "comandero") {
            this.$router.push("/comandero");
          }
        })
        .catch((e) => {
          console.log(e);
          this.mostrarAlerta = true;
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Usuario / contraseña incorrecto",
          });
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.firebaseLogin(this.email, this.password);
      }
    },
    logout() {
      let _vm = this;
      this.loadingLogout = true;
      this.$store
        .dispatch("auth/firebaseLogout")
        .then(() => {
          this.$router.push("/login");
        })
        .finally(() => (_vm.loadingLogout = false));
    },
  },
  mounted() {
    if (this.logged && this.$store.getters["auth/isTpv"]) {
      this.$router.push("/dashboard");
    } else if (this.logged && this.$store.getters["auth/isComandero"]) {
      this.$router.push("/comandero");
    }
  },
};
</script>