import { db, functions } from "@/firebase";
import { parDiasComparar } from "@/utils/dias.utils.js";
import { mostrarIdioma, redondeo } from "@/utils/helper.utils.js";
const { loadImage } = require("canvas");

import {
  imprimirTicketTipo1,
  imprimirTicketTipo2,
  imprimirTicketTipo2Qz,
  imprimirTicketCocina,
  imprimirTicketCocinaQz,
  imprimirPararCocina,
  imprimirReanudarCocina,
  impresionFacturaSimplificada,
  impresionFacturaSimplificadaQz,
} from "../../../utils/imprimir.utils";
import { restSinNegocio } from "@/utils/restaurantes.utils.js";
import { actualizarCarrito } from "@/utils/pedido.utils.js";
import moment from "moment";
import firebase from "firebase";
import { rsvgVersion } from "canvas";
import state from "../../state";

export default {
  quitarListenerPedidos({ state, commit }) {
    if (!!state.pedidosListener) state.pedidosListener();
    if (!!state.pedidosRealizandoTpvListener)
      state.pedidosRealizandoTpvListener();
    commit("setPedidos", []);
    commit("setPedidosRealizandoTpv", []);
  },
  getPedidosOtroDia({ rootState }, dias) {
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    return db
      .collection("pedidos")
      .where("fechaServidor", ">=", dias[0])
      .where("fechaServidor", "<=", dias[1])
      .where("restaurante", "==", restSin)
      .where("negocio", "==", negocio)
      .where("estado", "==", "tpv-realizando")
      .get()
      .then((r) => {
        return r.docs.map((p) => {
          return {
            id: p.id,
            ...p.data(),
          };
        });
      });
  },
  getPedidos({ state, commit, rootState, dispatch }) {
    //pedidos online
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    if (!!state.pedidosListener) state.pedidosListener();
    var ref = db
      .collection("pedidos")
      .where("fechaServidor", ">=", dias[0])
      .where("fechaServidor", "<=", dias[1])
      .where("restaurante", "==", restSin)
      .where("negocio", "==", negocio)
      .where("estado", "==", "finalizado");

    commit(
      "setPedidosListener",
      ref.onSnapshot((snapDoc) => {
        var res = [];
        snapDoc.forEach((e) => {
          res.push({
            ...e.data(),
            id: e.id,
          });
        });
        var ajustarCierre = Promise.resolve();
        if (!!rootState.zcierre) {
          // lee grabamos el zcierre, y el modoId y marcamos la cocina como impresa

          ajustarCierre = Promise.all(
            res
              .filter((p) => !p.zcierre || !p.modoId)
              .map((p) => {
                var modoAjustar = {};
                var siguienteNumPedidoRestModoMostrar = null;
                var siguienteNumPedidoRestModo = null;
                var datosPedidoFinal = {};
                if (!p.modoId) {
                  let f = rootState.datosTpv.modos.find(
                    (m) => m.tipoModo == p.modo
                  );
                  if (f) {
                    modoAjustar.modoId = f.id;
                    modoAjustar.modoCopy = f;
                  }
                  //número modo
                  const siguienteNumPedidoRestModoObj =
                    state.siguienteNumRestDiaModo;
                  const siguienteNumPedidoRestModo =
                    !!siguienteNumPedidoRestModoObj &&
                    !!siguienteNumPedidoRestModoObj[modoAjustar.modoId]
                      ? siguienteNumPedidoRestModoObj[modoAjustar.modoId]
                      : null;
                  dispatch("numPedidoRest", {
                    modo: modoAjustar.modoId,
                  });
                  //buscamos serie para el modo del pedido
                  let fModo = rootState.datosTpv.modos.find(
                    (m) => m.id === modoAjustar.modoId
                  );
                  siguienteNumPedidoRestModoMostrar =
                    !!fModo && !!fModo.numPedidoRestModoSerie
                      ? fModo.numPedidoRestModoSerie +
                        siguienteNumPedidoRestModo.toString().padStart(2, "0")
                      : siguienteNumPedidoRestModo;
                }
                // var impresionCocina = [...p.productos];
                /*p.productos.forEach(p => {
              let fI = impresionCocina.findIndex(impC => impC.id == p.id);
                impresionCocina.push(p)
            })*/
                console.log(siguienteNumPedidoRestModo);
                console.log(siguienteNumPedidoRestModoMostrar);
                datosPedidoFinal = {
                  zcierre: rootState.zcierre,
                  impresionCocina: [],
                  numPedidoRestDiaModo: siguienteNumPedidoRestModo,
                  numPedidoRestDiaModoMostrar:
                    siguienteNumPedidoRestModoMostrar,
                  ...modoAjustar,
                };
                return db
                  .collection("pedidos")
                  .doc(p.id)
                  .set(datosPedidoFinal, { merge: true })
                  .then(() => {
                    //no hace falta, viene desde servidor marcado
                    // if (rootGetters["impresionLocal"]) {
                    //   dispatch("impresionPedidoCajaLocal", datosPedidoFinal);
                    //   dispatch("impresionPedidoCocinaLocal", datosPedidoFinal);
                    // }
                  });
              })
          );
        }
        ajustarCierre.then(() => {
          if (!rootState.zcierre)
            commit(
              "setPedidos",
              res.filter((p) => !p.zcierre || p.zcierre != rootState.zcierre)
            ); //quitaamos los que vieenen con e lzcierre, veendrán en realizando tpv
        });
        return;
      })
    );
  },
  getPedidosRealizandoTpv({ state, rootState, commit, dispatch, rootGetters }) {
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    var ref = db.collection("pedidos");
    if (!!state.pedidosRealizandoTpvListener)
      state.pedidosRealizandoTpvListener();
    if (!!rootState.zcierre) {
      ref = ref
        .where("zcierre", "==", rootState.zcierre)
        .where("restaurante", "==", restSin)
        .where("negocio", "==", negocio);
      //.where('estado', '==', 'tpv-realizando')
    } else {
      ref = ref
        .where("fechaServidor", ">=", dias[0])
        .where("fechaServidor", "<=", dias[1])
        .where("restaurante", "==", restSin)
        .where("negocio", "==", negocio)
        .where("estado", "==", "tpv-realizando");
    }
    commit(
      "setPedidosRealizandoTpvListener",
      ref.onSnapshot((snapDoc) => {
        var res = [];
        snapDoc.forEach((e) => {
          res.push({
            ...e.data(),
            id: e.id,
          });
        });
        if (!!state.pedidoEditando) {
          //comprobar si se ha modificado el pedido que se está editando
          let f = res.find((p) => p.id == state.pedidoEditando);

          if (
            (f &&
              (!f.ultimoGuardadoCuenta ||
                f.ultimoGuardadoCuenta != rootGetters["cuenta"].id)) ||
            (!!f && !!f.cobrado)
          ) {
            //actualizamos el guardado

            commit("setAbriendoNoGrabar", true);
            commit("setPedidoEditandoObj", f);
          }
        }
        dispatch("calcularCierre", null, { root: true });
        commit("setPedidosRealizandoTpv", res);
        return;
      })
    );
    // return ref;
  },

  getPedidosDirectorioMatriz({ commit, rootState, state }) {
    // const restSin = restSinNegocio(rootState.restauranteObj);
    // const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    return (
      db
        .collection("pedidos")
        .where("fechaServidor", ">=", dias[0])
        .where("fechaServidor", "<=", dias[1])
        .where("restauranteDirectorioMatriz", "==", rootState.restauranteObj.id)
        // .where('negocioMatriz', '==', negocio)
        .where("estado", "==", "finalizado")
        .onSnapshot((snapDoc) => {
          var res = [];
          snapDoc.forEach((e) => {
            res.push({
              ...e.data(),
              id: e.id,
            });
          });
          commit("setPedidosDirectorioMatriz", res);
          return;
        })
    );
  },

  getPedidosPendienteImprimir({ state, rootState, commit, dispatch, getters }) {
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    return db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("impresion")
      .where("estado", "==", "esperandoImpresion")
      .onSnapshot((snapDoc) => {
        var res = [];
        console.log("ok");
        snapDoc[1] = { ...snapDoc[0] };
        snapDoc.forEach((e) => {
          let pedido = {
            ...e.data(),
            id: e.id,
          };

          let fI = state.pedidosImprimirGestionando.findIndex(
            (i) => i.id == pedido.id
          );
          if (fI == -1) {
            commit("addPedidoImprimirGestionando", pedido);

            res.push(pedido);
          }
        });
        console.log(res);
        return Promise.all(
          res.map(async (imp) => {
            let pedido = "se encuentra";
            pedido = getters["pedidos"].find((p) => p.id == imp.pedido);

            if (pedido) {
              //buscaamos ssi s eha mandado el id de imipresiíon
              console.log(pedido);
              await db
                .collection("restaurantes")
                .doc(rootState.auth.user.restauranteTpv)
                .collection("impresion")
                .doc(imp.id)
                .set({ estado: "impreso" }, { merge: true });
              if (imp.tickets.includes("caja")) {
                return dispatch("impresionPedidoCajaLocal", {
                  ...pedido,
                  obligatorio: !!imp.obligatorio,
                  idImpreso: imp.id,
                }).then(() => {
                  console.log("vamos a marcar");
                  setTimeout(() => {
                    return db.collection("pedidos").doc(pedido.id).set(
                      {
                        impresionCaja: moment().unix(),
                        impresionLocalEstado: "impreso",
                      },
                      { merge: true }
                    );
                  }, 2000);

                  //return dispatch('marcarImpresionNulo', imp.id)
                });
              } else if (imp.tickets.includes("cocina")) {
                return dispatch("impresionPedidoCocinaLocal", {
                  pedido: {
                    ...pedido,
                    obligatorio: !!imp.obligatorio,
                    idImpreso: imp.id,
                  },
                  filtrarImpresos: imp.filtrarImpresos,
                  imp: imp,
                }).then((r) => {
                  //return dispatch('marcarImpresionNulo', imp.id)
                });
              } else if (imp.tickets.includes("factSimplificada")) {
                return dispatch("impresionFacturaSimplificadaLocal", {
                  pedido: {
                    ...pedido,
                    obligatorio: !!imp.obligatorio,
                    idImpreso: imp.id,
                  },
                  factSimplificada: imp.factSimplificada,
                }).then(() => {
                  console.log("vamos a marcar");
                  // setTimeout(() => {
                  //   return db.collection("pedidos").doc(pedido.id).set(
                  //     {
                  //       impresionCaja: moment().unix(),
                  //       impresionLocalEstado: "impreso",
                  //     },
                  //     { merge: true }
                  //   );
                  // }, 2000);

                  //return dispatch('marcarImpresionNulo', imp.id)
                });
              }

              setTimeout(() => {
                return;
              }, 1000);
            } else {
              if (imp.tickets.includes("zcierre")) {
                return dispatch("imprimirz", {}, { root: true });
              } else if (imp.tickets.includes("abrirCajon")) {
                return dispatch("abrirCajon", {}, { root: true });
              }
              return dispatch("marcarImpresionNulo", imp.id);
            }
          })
        );
        return;
      });
  },

  imprimirPedido({}, pedido) {
    const app = firebase.app();
    const functions = app.functions("europe-west2");
    var imp = functions.httpsCallable("httpPedidosImprimirTpv");
    return imp(pedido);
  },

  imprimirPedidoCaja({ rootState, dispatch, rootGetters }, pedido) {
    let impresionLocal = rootGetters["impresionLocal"];
    if (impresionLocal || rootState.configuracionTpv.printnodeExclusivo) {
      return dispatch("impresionPedidoCajaLocal", pedido);
    } else {
      return dispatch("marcarImpresionCaja", pedido);
    }
  },
  imprimirFacturaSimplificada({ rootState, dispatch, rootGetters }, datos) {
    //{pedido, factSimplificada}
    let impresionLocal = rootGetters["impresionLocal"];
    if (impresionLocal || rootState.configuracionTpv.printnodeExclusivo) {
      return dispatch("impresionFacturaSimplificadaLocal", datos);
    } else {
      return dispatch("marcarImpresionFacturaSimplificada", datos);
    }
  },
  imprimirPedidoCocina({ rootState, dispatch, rootGetters }, datos) {
    //

    if (!datos.filtrarImpresos) datos.filtrarImpresos = false;
    let impresionLocal = rootGetters["impresionLocal"];

    if (impresionLocal || rootState.configuracionTpv.printnodeExclusivo) {
      if (rootState.onlineComputadora)
        return dispatch("impresionPedidoCocinaLocal", datos);
      else {
        dispatch("impresionPedidoCocinaLocal", datos);
        return;
      }
    } else {
      //no va bien
      return dispatch("marcarImpresionCocina", datos);
    }
  },
  marcarImpresionCaja({ rootState }, pedido) {
    return db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("impresion")
      .add({
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: "esperandoImpresion",
        tickets: ["caja"],
        obligatorio: !!pedido.obligatorio,
      });
  },
  marcarImpresionFacturaSimplificada({ rootState }, datos) {
    return db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("impresion")
      .add({
        pedido: datos.pedido.id,
        factSimplificada: datos.factSimplificada,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: "esperandoImpresion",
        tickets: ["factSimplificada"],
      });
  },
  marcarImpresionCocina(
    { rootState },
    { pedido, filtrarImpresos, cuentaDestino }
  ) {
    return db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("impresion")
      .add({
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: "esperandoImpresion",
        tickets: ["cocina"],
        obligatorio: !!pedido.obligatorio,
        filtrarImpresos: filtrarImpresos,
        cuentaDestino: !!cuentaDestino ? cuentaDestino : null,
      });
  },
  marcarImpresionNulo({ commit, rootState }, id) {
    return db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("impresion")
      .doc(id)
      .set(
        {
          estado: "impreso",
          momentImpreso: moment().unix(),
        },
        { merge: true }
      );
  },
  impresionPedidoCocinaLocal(
    { rootState, dispatch, commit },
    { pedido, filtrarImpresos }
  ) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == "cocina1";
    });
    var impresionCocina = Array.isArray(pedido.impresionCocina)
      ? pedido.impresionCocina
      : [];
    var impresionRealizada = false;
    return Promise.all(
      ticketsImp.map((t, i) => {
        if (!!t.impresionOtroRestCuenta) {
          //imprime en otro rest
          let datos = {
            pedido: pedido,
            filtrarImpresos: filtrarImpresos,
            cuentaDestino: t.impresionOtroRestCuenta,
          };
          return dispatch("marcarImpresionCocina", datos);
        } else {
          const f = !!t.qzDispositivo
            ? imprimirTicketCocinaQz
            : imprimirTicketCocina;

          var datosPedido = f(pedido, t, filtrarImpresos);

          impresionRealizada = impresionRealizada || !!datosPedido;
          if (!!datosPedido) {
            datosPedido.productos.forEach((p) => {
              let fI = impresionCocina.findIndex((impC) => impC.id == p.id);
              if (filtrarImpresos) {
                if (fI > -1) {
                  impresionCocina[fI].cantidad += p.cantidad;
                } else {
                  impresionCocina.push(p);
                }
              } else {
                impresionCocina.push(p);
              }
            }); //productos que se han imprimido en ticket cocina
            if (!!rootState.configuracionTpv && !!t.qzDispositivo) {
              dispatch(
                "qz/imprimirTicket",
                { data: datosPedido.datos, impresora: t.qzDispositivo },
                { root: true }
              );
            } else if (
              !!rootState.configuracionTpv &&
              !!rootState.configuracionTpv.printnodeExclusivo
            ) {
              //utiliza printnode exclusivo, no se usa el servidor de impresión de tpv, se manad a imprimir del tirón

              return dispatch(
                "impresion/ejecutarImpresionPN",
                {
                  id: "cocina_" + i + "_" + moment().unix(),
                  pedidoid: pedido.id,
                  fecha: moment().unix(),
                  nombre: "COCINA " + i + " " + pedido.id,
                  datos: datosPedido.datos,
                  impresionCocina: impresionCocina,
                  dispositivo: t,
                  obligatorio: pedido.obligatorio,
                  idImpresion: pedido.idImpreso,
                },
                { root: true }
              );
            } else {
              commit(
                "impresion/addTrabajoPorImprimir",
                {
                  id: "cocina_" + i + "_" + moment().unix(),
                  pedidoid: pedido.id,
                  fecha: moment().unix(),
                  nombre: "COCINA " + i + " " + pedido.id,
                  datos: datosPedido.datos,
                  impresionCocina: impresionCocina,
                  dispositivo: t,
                  obligatorio: pedido.obligatorio,
                  idImpresion: pedido.idImpreso,
                },
                { root: true }
              );
              return true;
            }
          }
        }
      })
    ).then((r) => {
      if (!!impresionRealizada) {
        return db.collection("pedidos").doc(pedido.id).set(
          {
            impresionCocina: impresionCocina,
          },
          { merge: true }
        );
      }
    });
  },
  async impresionFacturaSimplificadaLocal(
    { rootState, dispatch, commit },
    { pedido, factSimplificada }
  ) {
    function getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL();
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == "caja2" || t.tipo == "caja1";
    });
    return Promise.all(
      ticketsImp.map(async (t, i) => {
        var f = rootState.impresion.logosCaja.find((l) => l.ticket == t.id);
        let logo = f ? f.datos : false;
        const fTicket = !!t.qzDispositivo
          ? impresionFacturaSimplificadaQz
          : impresionFacturaSimplificada;
        var datosPedido = await fTicket(pedido, factSimplificada, logo);

        if (!!t.impresionOtroRestCuenta) {
          //imprime en otro rest
          let datos = {
            pedido: pedido,
            filtrarImpresos: filtrarImpresos,
            cuentaDestino: t.impresionOtroRestCuenta,
          };
          return dispatch("marcarImpresionCaja", pedido);
        } else if (!!rootState.configuracionTpv && !!t.qzDispositivo) {
          console.log(factSimplificada);
          var qrcode = false;
          if (factSimplificada.ficheroFactura) {
            const QRCode = require("qrcode");
            async function getQRCode(content) {
              return new Promise((resolve, reject) => {
                QRCode.toDataURL(content, (err, url) => {
                  if (err) return reject(err);

                  loadImage(url).then(resolve, reject);
                });
              });
            }
            qrcode = getBase64Image(
              await getQRCode(factSimplificada.ficheroFactura)
            );
          }
          dispatch(
            "qz/imprimirTicket",
            {
              data: datosPedido,
              impresora: t.qzDispositivo,
              logo,
              factura: qrcode,
            },
            { root: true }
          );
        } else if (
          !!rootState.configuracionTpv &&
          !!rootState.configuracionTpv.printnodeExclusivo
        ) {
          //utiliza printnode exclusivo, no se usa el servidor de impresión de tpv, se manad a imprimir del tirón

          return dispatch(
            "impresion/ejecutarImpresionPN",
            {
              id: "facturaSimplificada_" + i + "_" + moment().unix(),
              fecha: moment().unix(),
              pedidoid: pedido.id,
              nombre: "FACTURA_SIMPLIFICADA " + i + " " + pedido.id,
              datos: datosPedido,
              dispositivo: t,
              obligatorio: false,
              idImpresion: pedido.idImpreso,
            },
            { root: true }
          );
        } else {
          commit(
            "impresion/addTrabajoPorImprimir",
            {
              id: "caja_" + i + "_" + moment().unix(),
              fecha: moment().unix(),
              pedidoid: pedido.id,
              nombre: "CAJA " + i + " " + pedido.id,
              datos: datosPedido,
              dispositivo: t,
              obligatorio: pedido.obligatorio,
              idImpresion: pedido.idImpreso,
            },
            { root: true }
          );
          return;
        }
      })
    ).then((r) => {});
  },
  async impresionPedidoCajaLocal(
    { rootState, dispatch, commit, rootGetters },
    pedido
  ) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == "caja2" || t.tipo == "caja1";
    });

    return Promise.all(
      ticketsImp.map(async (t, i) => {
        console.log(t);
        var f = rootState.impresion.logosCaja.find((l) => l.ticket == t.id);
        let logo = f ? f.datos : false;
        var datosPedido =
          t.tipo == "caja2"
            ? !!t.qzDispositivo
              ? await imprimirTicketTipo2Qz(pedido, logo)
              : await imprimirTicketTipo2(pedido, logo)
            : await imprimirTicketTipo1(pedido, logo);
        //comprobamos si imprime esta cuenta en esa impresora
        if (
          !(
            t.cuentas &&
            Array.isArray(t.cuentas) &&
            !t.cuentas.includes(rootGetters["cuenta"].email)
          )
        ) {
          if (!!t.impresionOtroRestCuenta) {
            //imprime en otro rest
            let datos = {
              pedido: pedido,
              filtrarImpresos: filtrarImpresos,
              cuentaDestino: t.impresionOtroRestCuenta,
            };
            return dispatch("marcarImpresionCaja", pedido);
          } else if (!!rootState.configuracionTpv && !!t.qzDispositivo) {
            dispatch(
              "qz/imprimirTicket",
              { data: datosPedido, impresora: t.qzDispositivo },
              { root: true }
            );
          } else if (
            !!rootState.configuracionTpv &&
            !!rootState.configuracionTpv.printnodeExclusivo
          ) {
            //utiliza printnode exclusivo, no se usa el servidor de impresión de tpv, se manad a imprimir del tirón
            return dispatch(
              "impresion/ejecutarImpresionPN",
              {
                id: "caja_" + i + "_" + moment().unix(),
                fecha: moment().unix(),
                pedidoid: pedido.id,
                nombre: "CAJA " + i + " " + pedido.id,
                datos: datosPedido,
                dispositivo: t,
                obligatorio: pedido.obligatorio,
                idImpresion: pedido.idImpreso,
              },
              { root: true }
            );
          } else {
            commit(
              "impresion/addTrabajoPorImprimir",
              {
                id: "caja_" + i + "_" + moment().unix(),
                fecha: moment().unix(),
                pedidoid: pedido.id,
                nombre: "CAJA " + i + " " + pedido.id,
                datos: datosPedido,
                dispositivo: t,
                obligatorio: pedido.obligatorio,
                idImpresion: pedido.idImpreso,
              },
              { root: true }
            );
            return;
          }
        }
      })
    ).then((r) => {});
  },
  imprimirPararEnCocinaLocal({ rootState, commit }, { pedido }) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == "cocina1";
    });
    var datosParar = imprimirPararCocina(pedido);
    return Promise.all(
      ticketsImp.map((t, i) => {
        commit(
          "impresion/addTrabajoPorImprimir",
          {
            id: "parar_cocina_" + i + "_" + moment().unix(),
            pedidoid: pedido.id,
            fecha: moment().unix(),
            nombre: "PARAR COCINA " + i + " " + pedido.id,
            datos: datosParar,
            dispositivo: t,
            idImpresion: pedido.idImpreso,
          },
          { root: true }
        );
        return true;
      })
    );
  },
  imprimirReanudarEnCocinaLocal({ rootState, commit }, { pedido }) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == "cocina1";
    });
    var datosReanudar = imprimirReanudarCocina(pedido);
    return Promise.all(
      ticketsImp.map((t, i) => {
        commit(
          "impresion/addTrabajoPorImprimir",
          {
            id: "reanudar_cocina_" + i + "_" + moment().unix(),
            pedidoid: pedido.id,
            fecha: moment().unix(),
            nombre: "REANUDAR COCINA " + i + " " + pedido.id,
            datos: datosReanudar,
            dispositivo: t,
            idImpresion: pedido.idImpreso,
          },
          { root: true }
        );
        return true;
      })
    );
  },
  marcarPararEnCocinaLocal({ rootState }, { pedido }) {
    return db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("impresion")
      .add({
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: "esperandoImpresion",
        tickets: ["pararCocina"],
      });
  },
  marcarReanudarEnCocinaLocal({ rootState }, { pedido }) {
    return db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("impresion")
      .add({
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: "esperandoImpresion",
        tickets: ["reanudarCocina"],
      });
  },
  pararEnCocina({ rootState, dispatch, rootGetters }, pedido) {
    let impresionLocal = rootGetters["impresionLocal"];
    var ref;
    if (impresionLocal) {
      ref = dispatch("imprimirPararEnCocinaLocal", { pedido: pedido });
    } else {
      ref = dispatch("marcarPararEnCocinaLocal", pedido);
    }
    return ref.then(() => {
      return db
        .collection("pedidos")
        .doc(pedido.id)
        .set(
          {
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: rootState.auth.user.uid,
              mensaje: "Parado en cocina desde TPV ",
            }),
            estadoCocina: "parado",
          },
          { merge: true }
        );
    });
  },
  reanudarEnCocina({ rootState, dispatch, rootGetters }, pedido) {
    let impresionLocal = rootGetters["impresionLocal"];
    var ref;
    if (impresionLocal) {
      ref = dispatch("imprimirReanudarEnCocinaLocal", { pedido: pedido });
    } else {
      ref = dispatch("marcarReanudarEnCocinaLocal", pedido);
    }
    return ref.then(() => {
      return db
        .collection("pedidos")
        .doc(pedido.id)
        .set(
          {
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: rootState.auth.user.uid,
              mensaje: "Parado en cocina desde TPV ",
            }),
            estadoCocina: "reanudado",
          },
          { merge: true }
        );
    });
  },

  addProducto({ rootState, getters, commit, state, dispatch }, payload) {
    const producto = payload.producto;
    const opcionesObj = payload.opciones ? payload.opciones : false;
    const carrito = JSON.parse(
      JSON.stringify(state.pedidoEditandoObj.productos)
    );
    let opciones;
    let opcionesNombre = [];
    let opcionesPrecio = [];
    let opcionesTpv = [];
    let opcionesTipo = [];
    let impuestosGlobales = rootState.impuestos;

    if (!!opcionesObj) {
      opciones = JSON.parse(JSON.stringify(opcionesObj.ids)); //para convertir en un array sin observer
      opcionesNombre = JSON.parse(JSON.stringify(opcionesObj.nombres)); //para convertir en un array sin observer
      opcionesPrecio = JSON.parse(JSON.stringify(opcionesObj.precios)); //para convertir en un array sin observer
      opcionesTpv = JSON.parse(JSON.stringify(opcionesObj.nombresTpv)); //para convertir en un array sin observer
    } else {
      opciones = [];
    }

    let productoAdd = {
      cantidad: payload.cantidad,
      puedeOpciones: !!producto.grupoModificador,
      id: producto.id,
      //impuestoText: mostrarImpuestoTexto(state.activeProduct.impuesto, impuestosGlobales),
      impuesto: producto.impPorcentaje,
      nombre: producto.nombre,
      nombreTpv: producto.nombreTpv ? producto.nombreTpv : producto.nombre,
      opciones: opciones,
      opcionesName: opcionesNombre,
      opcionesTpv: opcionesTpv,
      opcionesPrecio: opcionesPrecio,
      categoria: producto.categoria,
      porPeso: !!producto.porPeso,
      orden: !!producto.orden ? producto.orden : null,
      ordenCat: !!producto.ordenCat ? producto.ordenCat : null,

      precio: parseFloat(producto.precio),
      comentarioCocina: "",
      ...(payload.menuId
        ? {
            menuId: payload.menuId,
            menuIdConcreto: payload.menuIdConcreto,
            menuCopy: payload.menuCopy,
          }
        : {}),
    };

    /*********************************** Busca si ya existe ***********************************/
    let coincide = carrito.findIndex((e) => {
      // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();)
      var array2Sorted = opciones.slice().sort();
      return (
        e.id === producto.id &&
        e.menuIdConcreto === productoAdd.menuIdConcreto &&
        e.opciones.length === opciones.length &&
        e.opciones
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          })
      );
    });
    if (coincide >= 0) {
      carrito[coincide].cantidad += payload.cantidad;
    } else {
      carrito.push(productoAdd);
    }
    let historialAdd = [
      ...state.pedidoEditandoObj.historial,
      {
        fecha: moment().unix(),
        mensaje:
          "Se añade producto: " +
          payload.cantidad +
          "x " +
          payload.producto.nombre,
      },
    ];
    commit("setPedidoEditandoObjValor", {
      key: "historial",
      value: historialAdd,
    });

    commit("setPedidoEditandoObjValor", { key: "productos", value: carrito });
    commit("setPedidoEditandoObjValor", {
      key: "cobradoProgramado",
      value: [],
    });

    return dispatch("calcularTotales").then(() =>
      commit("productos/reiniciarProductosPidiendo", false, { root: true })
    );
  },
  addMenu({ rootState, getters, commit, state, dispatch }, payload) {
    const menu = payload.menu;
    const opcionesObj = payload.opciones ? payload.opciones : false;
    const menus = JSON.parse(JSON.stringify(state.pedidoEditandoObj.menus));

    let menuAdd = {
      ...menu,
      cantidad: payload.cantidad,
    };

    /*********************************** Busca si ya existe ***********************************/
    let coincide = menus.findIndex((e) => {
      // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();)
      return menu.idConcreto == e.idConcreto;
    });

    if (coincide >= 0) {
      menus[coincide].cantidad += payload.cantidad;
    } else {
      menus.push(menuAdd);
    }
    let historialAdd = [
      ...state.pedidoEditandoObj.historial,
      {
        fecha: moment().unix(),
        mensaje: "Se añade menu: " + payload.cantidad + "x " + menuAdd.nombre,
      },
    ];
    commit("setPedidoEditandoObjValor", {
      key: "historial",
      value: historialAdd,
    });

    commit("setPedidoEditandoObjValor", { key: "menus", value: menus });
    return dispatch("calcularTotales").then(() =>
      commit("productos/reiniciarProductosPidiendo", false, { root: true })
    );
  },
  cambiarCantidadCarrito({ state, commit, dispatch }, payload) {
    var carrito = JSON.parse(JSON.stringify(state.pedidoEditandoObj.productos));

    /*********************************** Busca si ya existe ***********************************/
    let coincide = carrito.findIndex((e) => {
      // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
      var array2Sorted = payload.producto.opciones.slice().sort();
      return (
        e.id === payload.producto.id &&
        e.menuIdConcreto === payload.producto.menuIdConcreto &&
        e.opciones.length === payload.producto.opciones.length &&
        e.opciones
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          })
      );
    });
    if (coincide > -1) {
      /*if (payload.cantidad < carrito[coincide].cantidad) {
        //se ha anuylado algún pedido
        var carritoAnulado = !!state.pedidoEditandoObj.productosAnulados ? JSON.parse(JSON.stringify(state.pedidoEditandoObj.productosAnulados)) : []
  
        commit('setPedidoEditandoObjValor', { key: 'productosAnulados', value: carrito });
  
      }*/

      if (payload.cantidad <= 0) {
        //se marca a 0
        carrito[coincide].cantidad = payload.cantidad;
        if (!!payload.precioProductoTotalUnitario)
          carrito[coincide].precioProductoTotalUnitarioPersonalizado =
            payload.precioProductoTotalUnitario;
      } else {
        carrito[coincide].cantidad = payload.cantidad;
        if (!!payload.precioProductoTotalUnitario)
          carrito[coincide].precioProductoTotalUnitarioPersonalizado =
            payload.precioProductoTotalUnitario;
      }
      carrito[coincide].comentarioCocina = !!payload.comentarioCocina
        ? payload.comentarioCocina
        : "";
      let historialAdd = [
        ...state.pedidoEditandoObj.historial,
        {
          fecha: moment().unix(),
          mensaje:
            "Se cambia cantidad: " +
            payload.cantidad +
            "x " +
            payload.producto.nombre,
        },
      ];
      commit("setPedidoEditandoObjValor", {
        key: "historial",
        value: historialAdd,
      });

      commit("setPedidoEditandoObjValor", { key: "productos", value: carrito });
      return dispatch("calcularTotales");
    }
  },
  cambiarCantidadMenu({ state, commit, dispatch }, payload) {
    var menus = JSON.parse(JSON.stringify(state.pedidoEditandoObj.menus));

    /*********************************** Busca si ya existe ***********************************/
    let coincide = menus.findIndex((e) => {
      return e.idConcreto === payload.idConcreto;
      // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
    });
    if (coincide > -1) {
      if (payload.cantidad <= 0) {
        //se marca a 0
        menus[coincide].cantidad = payload.cantidad;
        if (!!payload.precioProductoTotalUnitario)
          menus[coincide].precioProductoTotalUnitarioPersonalizado =
            payload.precioProductoTotalUnitario;
      } else {
        menus[coincide].cantidad = payload.cantidad;
        if (!!payload.precioProductoTotalUnitario)
          menu[coincide].precioProductoTotalUnitarioPersonalizado =
            payload.precioProductoTotalUnitario;
      }
      menus[coincide].comentarioCocina = !!payload.comentarioCocina
        ? payload.comentarioCocina
        : "";
      commit("setPedidoEditandoObjValor", { key: "menus", value: menus });
      return dispatch("calcularTotales");
    }
  },
  calcularTotalesProductosParametros({}, pedido) {
    let productos = pedido.productos.map((prd) => {
      let precioProducto =
        prd.precio +
        prd.opcionesPrecio.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
      let precioProductoTotalUnitario =
        !!prd.precioProductoTotalUnitarioPersonalizado
          ? prd.precioProductoTotalUnitarioPersonalizado
          : precioProducto;
      let precioProductoTotal = precioProductoTotalUnitario * prd.cantidad;

      let baseImponible = redondeo(precioProductoTotal / (prd.impuesto + 1));
      let impuestoTotal =
        precioProductoTotal -
        (baseImponible > 0 ? baseImponible : -baseImponible);

      return {
        ...prd,
        precioProductoTotalUnitario: precioProductoTotalUnitario,
        precioProductoTotal: precioProductoTotal,
        impuestoCantidad: impuestoTotal,
        baseImponible: baseImponible,
      };
    });
    let todoComputar = productos;
    let precioTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.precioProductoTotal,
      0
    );
    let impuestosTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.impuestoCantidad,
      0
    );
    return {
      ...pedido,
      productos: productos,
      precioTotal: precioTotal,
      impuestosTotal: redondeo(impuestosTotal),
    };
  },
  calcularTotales({ state, commit }) {
    commit("setPedidoEditandoObjValor", {
      key: "productos",
      value: state.pedidoEditandoObj.productos.map((prd) => {
        let precioProducto =
          prd.precio +
          prd.opcionesPrecio.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
        let precioProductoTotalUnitario =
          !!prd.precioProductoTotalUnitarioPersonalizado
            ? prd.precioProductoTotalUnitarioPersonalizado
            : precioProducto;
        let precioProductoTotal = precioProductoTotalUnitario * prd.cantidad;

        let baseImponible = redondeo(precioProductoTotal / (prd.impuesto + 1));
        let impuestoTotal =
          precioProductoTotal -
          (baseImponible > 0 ? baseImponible : -baseImponible);

        return {
          ...prd,
          precioProductoTotalUnitario: precioProductoTotalUnitario,
          precioProductoTotal: precioProductoTotal,
          impuestoCantidad: impuestoTotal,
          baseImponible: baseImponible,
        };
      }),
    });
    if (state.pedidoEditandoObj.menus) {
      commit("setPedidoEditandoObjValor", {
        key: "menus",
        value: state.pedidoEditandoObj.menus.map((prd) => {
          // let precioProducto = (prd.precio + prd.opcionesPrecio.reduce((accumulator, currentValue) =>
          //   accumulator + currentValue, 0));
          let precioProducto = prd.precio;
          let precioProductoTotalUnitario =
            !!prd.precioProductoTotalUnitarioPersonalizado
              ? prd.precioProductoTotalUnitarioPersonalizado
              : precioProducto;
          let precioProductoTotal = precioProductoTotalUnitario * prd.cantidad;

          let baseImponible = redondeo(
            precioProductoTotal / (prd.impuesto + 1)
          );
          let impuestoTotal =
            precioProductoTotal -
            (baseImponible > 0 ? baseImponible : -baseImponible);

          return {
            ...prd,
            precioProductoTotalUnitario: precioProductoTotalUnitario,
            precioProductoTotal: precioProductoTotal,
            impuestoCantidad: impuestoTotal,
            baseImponible: baseImponible,
          };
        }),
      });
    }
    let todoComputar = [
      ...state.pedidoEditandoObj.productos.filter((p) => !p.menuId),
      ...(!!state.pedidoEditandoObj.menus ? state.pedidoEditandoObj.menus : []),
    ];

    let precioTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.precioProductoTotal,
      0
    );
    commit("setPedidoEditandoObjValor", {
      key: "precioTotal",
      value: precioTotal,
    });
    let impuestosTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.impuestoCantidad,
      0
    );
    commit("setPedidoEditandoObjValor", {
      key: "impuestosTotal",
      value: redondeo(impuestosTotal),
    });

    // commit('setPedidoEditandoObj', {
    //   ...state.pedidoEditandoObj,
    //   productos: state.pedidoEditandoObj.productos.map(prd => {
    //     return {
    //       ...prd,
    //       precioTotal: (prd.precio + prd.opcionesPrecio.reduce((accumulator, currentValue) =>
    //         accumulator + currentValue, 0)) * prd.cantidad,
    //     }
    //   })
    // })
  },
  cambiarPedido({}, pedido) {
    let id = pedido.id;
    let pActualizado = actualizarCarrito(pedido);
    return db
      .collection("pedidos")
      .doc(id)
      .set(
        {
          forma_pago: pedido.forma_pago,
          modo: pActualizado.modo,
          productos: pActualizado.productos,
          precioTotal: pActualizado.precioTotal,
          numeroProductos: pActualizado.numeroProductos,
          cambio: pActualizado.cambio ? pActualizado.cambio : 0,
        },
        { merge: true }
      );
  },
  addNuevoPedido({ state, getters, dispatch, rootState }, pedido) {
    const siguienteNumRest = state.siguienteNumRest;
    const siguienteNumRestDia = state.siguienteNumRestDia;
    const siguienteNumPedidoRestModoObj = state.siguienteNumRestDiaModo;
    const siguienteNumPedidoRestModo =
      !!siguienteNumPedidoRestModoObj &&
      !!siguienteNumPedidoRestModoObj[pedido.modoId]
        ? siguienteNumPedidoRestModoObj[pedido.modoId]
        : null;
    dispatch("numPedidoRest", {
      modo: pedido.modoId,
    });
    //buscamos serie para el modo del pedido
    let f = rootState.datosTpv.modos.find((m) => m.id === pedido.modoId);
    var siguienteNumPedidoRestModoMostrar =
      !!f && !!f.numPedidoRestModoSerie
        ? f.numPedidoRestModoSerie +
          siguienteNumPedidoRestModo.toString().padStart(2, "0")
        : siguienteNumPedidoRestModo;

    return Promise.resolve()
      .then((r) => {
        pedido = {
          ...pedido,
          numPedidoRest: siguienteNumRest,
          numPedidoRestDia: siguienteNumRestDia,
          numPedidoRestDiaModo: siguienteNumPedidoRestModo,
          numPedidoRestDiaModoMostrar: siguienteNumPedidoRestModoMostrar,
          id:
            pedido.negocio +
            "-" +
            pedido.restaurante +
            "-tpv-" +
            siguienteNumRest +
            "-" +
            moment().unix(),
        };

        var pMesa;
        if (pedido.modo == "mesa" && pedido.mesa) {
          pMesa = dispatch("grabarPedidoMesa", {
            pedido: pedido.id,
            mesa: pedido.mesa,
          });
        } else {
          pMesa = Promise.resolve();
        }

        let pPedido = db
          .collection("pedidos")
          .doc(pedido.id)
          .set(pedido, { merge: true })
          .then(() => pedido);
        if (rootState.onlineComputadora) {
          return Promise.all([pPedido, pMesa]);
        } else {
          return [pedido];
        }
      })
      .catch((e) => console.log(e));
  },
  async crearAbono({ dispatch, state, rootState }, { pedido, desglose }) {
    var pedidoAbonar;
    const siguienteNumRest = state.siguienteNumRest;
    const siguienteNumRestDia = state.siguienteNumRestDia;
    const siguienteNumPedidoRestModoObj = state.siguienteNumRestDiaModo;
    const siguienteNumPedidoRestModo =
      !!siguienteNumPedidoRestModoObj &&
      !!siguienteNumPedidoRestModoObj[pedido.modoId]
        ? siguienteNumPedidoRestModoObj[pedido.modoId]
        : null;
    dispatch("numPedidoRest", { modo: pedido.modoId });
    var datosContable = { ...desglose };
    delete datosContable.programado;
    let codigoFactura = await dispatch(
      "contable/crearApunteContable",
      {
        pedido:
          pedido.negocio +
          "-" +
          pedido.restaurante +
          "-tpv-" +
          siguienteNumRest,
        moment: moment().unix(),
        abonoContableQueAbona: datosContable.codigoFactura,
        abonoPedidoQueAbona: pedido.id,
        serie: "abono",
        ...datosContable,
      },
      { root: true }
    );
    let f = rootState.datosTpv.modos.find((m) => m.id === pedido.modoId);

    var siguienteNumPedidoRestModoMostrar =
      !!f && !!f.numPedidoRestModoSerie
        ? f.numPedidoRestModoSerie +
          siguienteNumPedidoRestModo.toString().padStart(2, "0")
        : siguienteNumPedidoRestModo;
    pedidoAbonar = {
      ...pedido,
      impuestosTotal: -redondeo(desglose.impuestosTotalesPago),
      cobrado: moment().unix(),
      numPedidoRest: siguienteNumRest,
      numPedidoRestDia: siguienteNumRestDia,
      numPedidoRestDiaModo: siguienteNumPedidoRestModo,
      numPedidoRestDiaModoMostrar: siguienteNumPedidoRestModoMostrar,
      precioTotal: -desglose.pagado,
      productos: desglose.productos.map((p) => {
        let precioProductoTotal = -(p.precioProductoTotalUnitario * p.cantidad);
        let baseImponible = redondeo(precioProductoTotal / (p.impuesto + 1));
        let impuestoTotal = precioProductoTotal - baseImponible;
        return {
          ...p,
          cantidad: p.cantidad,
          impuesto: p.impuesto,
          impuestoCantidad: -p.impuestoCantidad,
          impuestoTotal: impuestoTotal,
          precioProductoTotal: precioProductoTotal,
          precioProductoTotalUnitario: -p.precioProductoTotalUnitario,
          precioProductoTotalUnitarioPersonalizado:
            p.precioProductoTotalUnitarioPersonalizado
              ? -p.precioProductoTotalUnitarioPersonalizado
              : 0,
          cantidad: p.cantidad,
          opcionesPrecio: p.opcionesPrecio.map((op) => -op),
        };
      }),

      fechaInicio: new Date(),
      fechaServidor: firebase.firestore.Timestamp.now(),
      abonoCreado: moment().unix(),
      abonoPedidoOriginal: pedido.id,
      abonoPedidoOriginalCopy: {
        id: pedido.id,
        numPedidoRestDia: pedido.numPedidoRestDia,
        numPedidoRest: pedido.numPedidoRest,
        numPedidoRestDiaModo: pedido.numPedidoRestDiaModo,
        numPedidoRestDiaModoMostrar: pedido.numPedidoRestDiaModoMostrar,
      },
      abonoPedidoQueAbona: null,
      abonoCodigoFactura: desglose.codigoFactura,
      id:
        pedido.negocio + "-" + pedido.restaurante + "-tpv-" + siguienteNumRest,
      cobradoDesglose: [
        {
          ...desglose,
          pagado: -desglose.pagado,
          // baseImponible: -desglose.baseImponiblePago,
          impuestosTotalesPago: -desglose.impuestosTotalesPago,
          baseImponiblePago: -desglose.baseImponiblePago,
          impuestosDesglosePago: desglose.impuestosDesglosePago.map((i) => {
            return {
              ...i,
              cantidad: -i.cantidad,
            };
          }),
          codigoFactura: codigoFactura,
          esAbono: true,
          productos: desglose.productos.map((p) => {
            //let precioProductoTotal = -(p.precioProductoTotalUnitario * p.cantidad);
            //let baseImponible = redondeo(precioProductoTotal / (p.impuesto + 1));
            //let impuestoTotal = precioProductoTotal - baseImponible;
            return {
              ...p,
              cantidad: p.cantidad,
              impuesto: p.impuesto,
              precioProductoTotalUnitario: -p.precioProductoTotalUnitario,
              precioProductoTotalUnitarioPersonalizado:
                p.precioProductoTotalUnitarioPersonalizado
                  ? -p.precioProductoTotalUnitarioPersonalizado
                  : 0,
              cantidad: p.cantidad,
              opcionesPrecio: p.opcionesPrecio.map((op) => -op),
            };
          }),
        },
      ],
    };
    delete pedidoAbonar.abonoCreado;
    delete pedidoAbonar.abonoPedidoQueAbona;
    delete pedidoAbonar.abonoPedidoQueAbonaCopy; //si vieene del originiaal see borran esoos datos

    return db
      .collection("pedidos")
      .doc(pedidoAbonar.id)
      .set(pedidoAbonar, { merge: true })
      .then(() => pedidoAbonar);
  },
  async setPedidoObj({ state, getters, commit }, idpedido) {
    //toma un id de pedido y hace la mutación para el id y el objeto
    commit("setPedidoEditando", idpedido);
    let f = getters.pedidos.find((p) => {
      return p.id == state.pedidoEditando;
    });
    if (!f && idpedido) {
      await db
        .collection("pedidos")
        .doc(idpedido)
        .get()
        .then((r) => {
          if (r.exists) {
            f = {
              ...r.data(),
              id: r.id,
            };
          }
        });
    }
    commit("setPedidoEditandoObj", f ? f : {});
  },
  savePedido({ commit, dispatch, rootGetters, rootState }, pedido) {
    commit("setGrabandoPedido", true);
    commit("setPedidoEditandoFlag", pedido.id);
    //dispatch('calcularCierre', null, { root: true })

    let cuenta = rootGetters["cuenta"];
    if (rootState.onlineComputadora) {
      return Promise.all([
        db
          .collection("pedidos")
          .doc(pedido.id)
          .set({ ...pedido, ultimoGuardadoCuenta: cuenta.id }, { merge: true }),
      ]).finally(() => commit("setGrabandoPedido", false));
    } else {
      db.collection("pedidos")
        .doc(pedido.id)
        .set({ ...pedido, ultimoGuardadoCuenta: cuenta.id }, { merge: true });
      commit("setGrabandoPedido", false);
    }
  },
  getNumPedidoRest({ rootState, commit }) {
    function generarObjModo() {
      var res = {};
      rootState.datosTpv.modos.forEach((m) => {
        res[m.id] = 1;
      });
      console.log(res);
      return res;
    }
    var data = {};
    let fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    // data.fecha = fecha;
    let fechaS = rootState.zcierre
      ? rootState.zcierre.toString()
      : moment().format("YYYY-MM-DD");
    var restPedidos = db
      .collection("restaurantes")
      .doc(rootState.restauranteObj.id)
      .collection("pedidos");
    var numPedidoRestDia;
    var numPedidoRestDiaModo;
    var numPedidoRest;

    if (state.siguienteNumRestDiaListener) state.siguienteNumRestDiaListener();
    commit(
      "setSiguienteNumRestDiaListener",
      restPedidos.doc(fechaS).onSnapshot((r) => {
        if (r.exists) {
          let datosGeneralDia = r.data();
          numPedidoRestDia = datosGeneralDia.numPedidoRestDia + 1;
          numPedidoRestDiaModo = datosGeneralDia.numPedidoRestDiaModo
            ? datosGeneralDia.numPedidoRestDiaModo
            : generarObjModo();
        } else {
          numPedidoRestDia = 1;
          numPedidoRestDiaModo = generarObjModo();
        }
        commit("setSiguienteNumRestDia", numPedidoRestDia);
        commit("setSiguienteNumRestDiaModo", numPedidoRestDiaModo);
      })
    );
    if (state.siguienteNumRestListener) state.siguienteNumRestListener();
    commit(
      "setSiguienteNumRestListener",
      restPedidos.doc("datosGeneralesTpv").onSnapshot((r) => {
        var numPedidoRest;
        if (r.exists) {
          let datosGeneral = r.data();
          numPedidoRest = datosGeneral.numPedidoRest + 1;
        } else {
          data.numPedidoRest = 1;
        }
        commit("setSiguienteNumRest", numPedidoRest);
      })
    );
  },
  numPedidoRest({ rootState, commit }, { modo }) {
    var data = {};
    let fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    // data.fecha = fecha;
    let fechaS = rootState.zcierre
      ? rootState.zcierre.toString()
      : moment().format("YYYY-MM-DD");
    var restPedidos = db
      .collection("restaurantes")
      .doc(rootState.restauranteObj.id)
      .collection("pedidos");
    var numPedidoRestDia;
    var numPedidoRest;
    var numPedidoRestDiaModo;

    function generarObjModo() {
      var res = {};
      rootState.datosTpv.modos.forEach((m) => {
        res[m.id] = m.id == modo ? 2 : 1;
      });
      return res;
    }

    return Promise.all([
      restPedidos
        .doc(fechaS)
        .get()
        .then((r) => {
          if (r.exists) {
            let datosGeneralDia = r.data();
            numPedidoRestDia = datosGeneralDia.numPedidoRestDia + 1;
            numPedidoRestDiaModo = datosGeneralDia.numPedidoRestDiaModo
              ? datosGeneralDia.numPedidoRestDiaModo
              : generarObjModo();
            if (!!modo && !!numPedidoRestDiaModo[modo]) {
              numPedidoRestDiaModo[modo] = numPedidoRestDiaModo[modo] + 1;
            }
            /*return*/ restPedidos
              .doc(fechaS)
              .set(
                {
                  numPedidoRestDia: numPedidoRestDia,
                  numPedidoRestDiaModo: numPedidoRestDiaModo,
                },
                { merge: true }
              )
              .then(() => {
                data.numPedidoRestDia = numPedidoRestDia;
                return data;
              });
          } else {
            var ref = r.ref;
            var numPedidoRestDiaModo = generarObjModo();
            /*return*/ ref
              .set(
                {
                  numPedidoRestDia: 1,
                  numPedidoRestDiaModo: numPedidoRestDiaModo,
                },
                { merge: true }
              )
              .then(() => {
                data.numPedidoRestDia = 1;
                data.numPedidoRestDiaModo = numPedidoRestDiaModo;
                return data;
              });
          }
        }),
      restPedidos
        .doc("datosGeneralesTpv")
        .get()
        .then((r) => {
          var res;
          var numPedidoRest;
          if (r.exists) {
            let datosGeneral = r.data();
            numPedidoRest = datosGeneral.numPedidoRest + 1;
            res = restPedidos
              .doc("datosGeneralesTpv")
              .set({ numPedidoRest: numPedidoRest }, { merge: true })
              .then(() => {
                data.numPedidoRest = numPedidoRest;
                return data;
              });
            if (rootState.onlineComputadora) {
              return res;
            }
          } else {
            var ref = r.ref;
            res = ref.set({ numPedidoRest: 1 }, { merge: true }).then(() => {
              data.numPedidoRest = 1;
              return data;
            });
            if (rootState.onlineComputadora) {
              return res;
            }
          }
        }),
    ]).then((r) => {
      //commit('setSiguienteNumRestDia', data.numPedidoRestDia)
      //commit('setSiguienteNumRest', data.numPedidoRest)
      return data;
    });
  },
  getPedido({}, id) {
    return db
      .collection("pedidos")
      .doc(id)
      .get()
      .then((r) => {
        if (r.exists) {
          return {
            ...r.data(),
            id: r.id,
          };
        } else {
          return Promise.reject("no encontrado");
        }
      });
  },
  solicitarEliminacion({ rootState }, item) {
    let usuario = rootState.auth.user.email
      ? rootState.auth.user.email
      : rootState.auth.user.uid;
    var res = db
      .collection("pedidos")
      .doc(item.id)
      .set(
        {
          solicitarEliminado: true,
          historial: firebase.firestore.FieldValue.arrayUnion({
            fecha: moment().unix(),
            usuario: usuario,
            mensaje: "Solicitado eliminar desde TPV " + usuario,
          }),
        },
        { merge: true }
      );
    if (rootState.onlineComputadora) {
      return res;
    }
  },
  confirmarEliminarTpv({ rootState, dispatch }, item) {
    let usuario = rootState.auth.user.email
      ? rootState.auth.user.email
      : rootState.auth.user.uid;

    if (rootState.onlineComputadora) {
      return db
        .collection("pedidos")
        .doc(item.id)
        .set(
          {
            eliminado: true,
            cobrado: false,
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: usuario,
              mensaje: "Eliminado desde TPV " + usuario,
            }),
          },
          { merge: true }
        )
        .then(() => {
          if (item.modo == "mesa" && !!item.mesa) {
            return dispatch("liberarMesa", item.mesa);
          }
        });
    } else {
      db.collection("pedidos")
        .doc(item.id)
        .set(
          {
            eliminado: true,
            cobrado: false,
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: usuario,
              mensaje: "Eliminado desde TPV " + usuario,
            }),
          },
          { merge: true }
        );
      if (item.modo == "mesa" && !!item.mesa) {
        dispatch("liberarMesa", item.mesa);
      }

      return Promise.resolve();
    }
  },
  liberarMesa({ dispatch, rootState }, mesa) {
    var res = Promise.all([
      // db.collection('pedidos').doc(mesa.pedido).set({
      //   idmesa: null
      // }, { merge: true }),
      dispatch("grabarPedidoMesa", {
        mesa: mesa,
        pedido: null,
      }),
    ]);
    if (rootState.onlineComputadora) {
      return res;
    }
  },
  grabarPedidoMesa({ rootGetters, rootState }, payload) {
    var res = db
      .collection("restaurantes")
      .doc(rootState.auth.user.restauranteTpv)
      .collection("tpv")
      .doc("secreto")
      .collection("mesas")
      .doc(payload.mesa)
      .set({ pedido: payload.pedido }, { merge: true });
    if (rootState.onlineComputadora) {
      return res;
    }
  },
  crearPedidoEnMesa({ commit }, payload) {
    commit("setPedidoEditandoObjValor", {
      key: "modo",
      value: "mesa",
    });
    commit("setPedidoEditandoObjValor", {
      key: "mesa",
      value: payload.mesa.id,
    });
    commit("setPedidoEditandoObjValor", {
      key: "copyMesa",
      value: payload.mesa,
    });
    commit("setPedidoEditandoObjValor", {
      key: "comensales",
      value: payload.comensales,
    });
    commit("setModoPantalla", "pedidoCarrito");
    commit("setPedidoDialog", true);
  },
  generarFactura({ commit, state }, payload) {
    var fact = functions.httpsCallable("httpTpvGenerarFactura");
    return fact(payload).then((r) => {
      let historialAdd = [
        ...state.pedidoEditandoObj.historial,
        {
          fecha: moment().unix(),
          mensaje: "Factura generada",
        },
      ];
      commit("setPedidoEditandoObjValor", {
        key: "historial",
        value: historialAdd,
      });
    });
  },
  ultimopedidoMostrarCobrar({ getters }) {
    let p = getters.pedidos
      .filter((p) => !!p.cobrado)
      .sort((a, b) => moment(b.cobrado) - moment(a.cobrado));
    return Array.isArray(p) && p.length > 0 ? p[0].id : null;
  },
};
