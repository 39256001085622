<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      elevation="4"
      class="rounded-lg ficha-generica"
      @click="nuevoPedido"
      :color="hover ? 'primary lighten-4' : 'primary borde-blanco'"
    >
      <v-responsive :aspect-ratio="1.618" class="align-center">
        <v-card-text
          class="text-uppercase font-weight-black text-center"
          :class="$vuetify.breakpoint.mdAndDown ? '' : 'text-h4'"
        >
          <v-row justify="center" align="center" style="flex-direction: column">
            <v-btn
              outlined
              fab
              class="mb-4"
              :small="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon :x-large="$vuetify.breakpoint.lgAndUp">mdi-plus</v-icon>
            </v-btn>
            <div class="font-weight-bold">
              <span>{{ modoObj.nombre }}</span>
            </div>
            <!--           <v-icon x-large class="white--text"
              >mdi-plus</v-icon
            > -->
          </v-row>
        </v-card-text>
      </v-responsive>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  computed: {
    modoObj() {
      let f = this.$store.state.datosTpv.modos.find((m) => m.id == this.modoid);
      return f ? f : "";
    },
    modo() {
      return this.$route.params.modotipo;
    },
    modoid() {
      return this.$route.params.modoid;
    },
    modoPantalla: {
      get() {
        return this.$store.state.pedidos.modoPantalla;
      },
      set(v) {
        this.$store.commit("pedidos/setModoPantalla", v);
      },
    },
    addDialog: {
      get() {
        return this.$store.state.pedidos.pedidoDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoDialog", v);
      },
    },
  },
  methods: {
    nuevoPedido() {
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modo",
        value: this.modo,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoId",
        value: this.modoid,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoTexto",
        value: !!this.modoObj ? this.modoObj.nombre : "",
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoCopy",
        value: !!this.modoObj ? this.modoObj : {},
      });
      if (this.modo == "domicilio" || this.modo == "recoger") {
        this.modoPantalla = "seleccionCliente";
        this.$store.commit("clientes/setClienteDialog", true);
      } else if (this.modo == "mesas") {
        this.modoPantalla = "seleccionMesa";
        this.addDialog = true;
      } else if (this.modo == "mostrador") {
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "uid",
          value: "0",
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "copyUser",
          value: {
            displayName: "Contado",
          },
        });
        this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
        this.addDialog = true;
      }
      this.$store.commit("setPaginaPaginacionPedidos", 0);
    },
  },
};
</script>

<style>
</style>