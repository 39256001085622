export default {
  setLogged(state, bool) {
    state.isLogged = bool;
  },
  setUser(state, user) {
    state.user = user;
    state.isLogged = true;
  },
  logout(state) {
    state.user = null;
    state.isLogged = false;
  },
  SET_LOGIN_COMANDERO(state, b) {
    state.loginComandero = b;
  },
};
