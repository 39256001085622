var http = require('http');

export default {
    montarServidor() {

        http.get({ path: '/beep' }, function (res) {
            var div = document.getElementById('app');
            div.innerHTML += 'GET /beep<br>';

            res.on('data', function (buf) {
                div.innerHTML += buf;
            });

            res.on('end', function () {
                div.innerHTML += '<br>__END__';
            });
        });
    }
}