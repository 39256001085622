
import VueGoogleMaps from 'vue2-google-maps'
import { gmapApi } from 'vue2-google-maps'
// var google = gmapApi()
export const getSugerencias = async searchText => {
  let result
  return searchLocation(searchText)
    .then((rawResult) => {
      result = rawResult.map((res) => {

        return {

          id: res.place_id,
          value: res.description,
          types: res.types
        }
      })
      return result.slice(0, 3)
    })
  try {
    const rawResult = await searchLocation(searchText)
    result = rawResult.map((res) => {
      return {
        id: res.place_id,
        value: res.description,
        types: res.types
      }
    })
    result = result.slice(0, 3)
  } catch (err) {
    console.log(err)
    return err
  }
  return result
}

export const coordenadasDireccion = array => {
  const geocoder = new google.maps.Geocoder();
  return Promise.all(array.map(p => {
    return geocoder
      .geocode({ placeId: p.id })
      .then(r => {
        return {
          ...p,
          valueBruto: r.results[0],
          coordenadas: r.results ? [r.results[0].geometry.location.lat(), r.results[0].geometry.location.lng()] : []
        }
      })
  }))
    .then((r) => {

      return r
    })
}
// Auxiliary functions
// wrap google api's callback to an async function
export const searchLocation = async val => {
  let promise = await new Promise(async (resolve, reject) => {
    var displaySuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        reject(status)
      }

      resolve(predictions)
    }
    var service = new google.maps.places.AutocompleteService()
    service.getPlacePredictions({
      input: val,

      componentRestrictions: {
        'country': ['es'],

      }
    },
      displaySuggestions)
  }).catch(function (err) { console.log(err); throw err })
  return promise
}

export const formatearDireccionGoogle = (data) => {
  let dir = {
    calle: '',
    numero: '',
    adicional: '',
    cp: '',
    municipio: '',
    provincia: '',
    codigoProvincia: '',
    pais: '',
    codigoPais: ''
  };

  data.forEach(function (e) {
    var tipo_aux = e.types[0];
    switch (tipo_aux) {
      case 'street_number':
        dir.numero = e.long_name;
        break;
      case 'route':
        dir.calle = e.long_name;
        break;
      case 'locality':
        dir.municipio = e.long_name;
        break;
      case 'administrative_area_level_2':
        dir.provincia = e.long_name;
        break;
      case 'neighborhood':
        dir.calle = e.long_name;
        break;
      case 'bus_station':
        dir.calle = e.long_name;
        break;
      case 'train_station':
        dir.calle = e.long_name;
        break;
      case 'airport':
        dir.calle = e.long_name;
        break;
      case 'subway_station':
        dir.calle = e.long_name;
        break;
      case 'fire_station':
        dir.calle = e.long_name;
        break;
      case 'hospital':
        dir.calle = e.long_name;
        break;
      case 'museum':
        dir.calle = e.long_name;
        break;
      case 'point_of_interest':
        dir.calle = e.long_name;
        break;
      case 'park':
        dir.calle = e.long_name;
        break;
      case 'establishment':
        dir.calle = e.long_name;
        break;
      case 'transit_station':
        dir.calle = e.long_name;
        break;
      case 'postal_code':
        dir.cp = e.long_name;
        break;
      case 'country':
        dir.pais = e.long_name;
        dir.codigoPais = e.short_name;
        break;
      default:
        break;
    };
  });

  return dir;
}
export const pointInPolygon = (point, vs) => {
  var x = point[0], y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].lat, yi = vs[i].lng;
    var xj = vs[j].lat, yj = vs[j].lng;

    var intersect = ((yi > y) != (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
}


// export default async ({ app }, inject) => {
//   inject('getSugerencias', GetSuggestions)
//   inject('formatearDireccionGoogle', formatearDireccionGoogle)
//   inject('pointInPolygon', pointInPolygon)
// }
