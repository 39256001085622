
<template v-if="productos && productos.length > 0">
  <div
    class=""
    :class="!editarProductoObj ? 'pt-0' : ''"
    :style="`height: ${
      $vuetify.breakpoint.smAndDown || !!pagoDividido
        ? 'calc(100% - 54px)'
        : '100%'
    };`"
  >
    <template v-if="editarProductoObj">
      <v-card
        class="transparent fill-height"
        elevation="0"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
      >
        <v-card-title class="rounded-t-lg py-0 align-start">
          <v-list-item class="pa-0" two-line style="max-width: 100%">
            <v-list-item-content>
              <v-list-item-title class="align-self-start">
                <span class="font-weight-bold">
                  {{
                    !!editarProductoObj.nombreEs
                      ? editarProductoObj.nombreEs
                      : editarProductoObj.nombre
                  }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle v-if="!!editarProductoObj.cantidadCobrada">
                <v-chip small color="info" class="white--text border"
                  >Cobrado x{{ editarProductoObj.cantidadCobrada }}</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="my-1 align-self-center">
              <v-list-item-action-text
                class="subtitle-1 font-weight-black black--text"
              >
                {{
                  mostrarMoneda(
                    editarProductoObj.cantidad *
                      editarProductoObj.precioProductoTotalUnitario
                  )
                }}€
              </v-list-item-action-text>
              <v-chip
                small
                color="primary"
                class="black--text border"
                v-if="editarProductoObj.cantidad !== 1"
              >
                {{
                  mostrarMoneda(editarProductoObj.precioProductoTotalUnitario)
                }}€ x{{ editarProductoObj.cantidad }}</v-chip
              >
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="" class="pa-3 grey lighten-4 fill-height">
          <v-row no-gutters class="">
            <v-col cols="12">
              <v-row no-gutters class="mb-4">
                <v-col cols="4" class="px-1">
                  <v-btn
                    @click="abrirOpcionesEditarProducto()"
                    block
                    large
                    height="60"
                    color="info white--text"
                    class="black--text rounded-lg"
                    :disabled="!editarProductoObj.puedeOpciones"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-1">
                  <v-btn
                    :disabled="editarProductoObj.cantidadCobrada > 0"
                    v-if="
                      editarProductoObj.cantidad == 1 ||
                      !!editarProductoObj.porPeso
                    "
                    block
                    large
                    height="60"
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="borrar($store.state.pedidos.editarProductoObj)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="
                      editarProductoObj.cantidadCobrada ==
                      editarProductoObj.cantidad
                    "
                    v-else
                    block
                    large
                    height="60"
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('menos')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-1">
                  <v-btn
                    v-if="!editarProductoObj.porPeso"
                    block
                    large
                    height="60"
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('mas')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="keyboard--transparent-bg">
              <KeyboardNumerico
                keyboardClass="keyboard-cambio-carrito-integrado"
                noDividir
                ref="keyboardRefNumerico"
                @onChange="onChangeTecladoNuevoProductoNumerico"
                @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                :input="cambioProductoPrecioInput"
              />
            </v-col>

            <v-col cols="12" class="mt-4">
              <v-textarea
                hide-details
                class="rounded-lg white"
                outlined
                label="Comentario cocina..."
                v-model="editarProductoObj.comentarioCocina"
                ref="comentariosProducto"
                :rows="mostrarTeclado == true ? 3 : 1"
                no-resize
                @click="focusComentarioCocinaProducto"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : ''"
          class="pa-2 card-actions--bottom"
        >
          <v-row no-gutters>
            <v-col cols="6" class="pr-1">
              <v-btn
                @click="cancelarEdicion"
                block
                large
                class="black--text rounded-lg"
                color="red lighten-1"
                ><v-icon color="">mdi-arrow-left</v-icon></v-btn
              >
            </v-col>
            <v-col cols="6" class="pl-1">
              <v-btn
                @click="cambioProductoGuardar"
                block
                large
                class="black--text rounded-lg"
                color="primary lighten-1"
                ><v-icon color="black">mdi-check</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>

    <!--MENU-->
    <template v-else-if="editarProductoMenuObj">
      <!--       <v-alert color="info" outlined rounded icon="mdi-info" v-if="false">
        Está editando el producto {{ editarProductoObj.nombre }}
      </v-alert> -->
      <v-card
        class="transparent fill-height"
        elevation="0"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
      >
        <v-card-title class="rounded-t-lg py-2 align-start">
          <v-list-item class="pa-0" two-line style="max-width: 100%">
            <v-list-item-content>
              <v-list-item-title class="align-self-start">
                <span class="font-weight-bold">
                  {{ editarProductoMenuObj.nombre }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="!!editarProductoMenuObj.cantidadCobrada"
              >
                <v-chip small color="info" class="white--text border"
                  >Cobrado x{{ editarProductoMenuObj.cantidadCobrada }}</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="my-1 align-self-center">
              <v-list-item-action-text
                class="subtitle-1 font-weight-black black--text"
              >
                {{
                  mostrarMoneda(
                    editarProductoMenuObjCalculado.cantidad *
                      editarProductoMenuObjCalculado.precioProductoTotalUnitario
                  )
                }}€
              </v-list-item-action-text>
              <v-chip
                small
                color="primary"
                class="black--text border"
                v-if="editarProductoMenuObj.cantidad !== 1"
              >
                {{
                  mostrarMoneda(
                    editarProductoMenuObjCalculado.precioProductoTotalUnitario
                  )
                }}€ x{{ editarProductoMenuObjCalculado.cantidad }}</v-chip
              >
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list-item
            class="pa-0"
            two-line
            style="max-width: 100%"
            v-for="(
              productoDelMenu, i
            ) in editarProductoMenuObjCalculado.productos"
            :key="'productoMenu_' + i"
          >
            <v-list-item-content>
              <v-list-item-title class="align-self-start">
                <span class="font-weight-bold">
                  {{ productoDelMenu.cantidad }}x {{ productoDelMenu.nombre }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-text class="pt-4">
          <v-row justify="center" class="px-lg-10 pt-lg-4">
            <v-col cols="11">
              <v-row justify="end">
                <v-col cols="9" class="px-1">
                  <v-btn
                    @click="abrirOpcionesEditarMenu()"
                    block
                    large
                    color="info white--text"
                    class="black--text rounded-lg"
                  >
                    PRODUCTOS
                    <v-icon v-if="false">mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="3" class="px-1">
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="eliminarMenu()"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col v-if="false" cols="4" class="px-1">
                  <v-btn
                    :disabled="editarProductoMenuObj.cantidadCobrada > 0"
                    v-if="editarProductoMenuObj.cantidad == 1"
                    block
                    large
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="borrarMenu()"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="
                      editarProductoMenuObj.cantidadCobrada ==
                      editarProductoMenuObj.cantidad
                    "
                    v-else
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('menos')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col v-if="false" cols="4" class="px-1">
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('mas')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="keyboard--transparent-bg pt-0">
              <KeyboardNumerico
                keyboardClass="keyboard-cambio-carrito-integrado"
                noDividir
                ref="keyboardRefNumerico"
                @onChange="onChangeTecladoNuevoProductoNumerico"
                @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                :input="cambioProductoPrecioInput"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : ''"
          class="pa-4 card-actions--bottom"
        >
          <v-row>
            <v-col cols="6" md="6">
              <v-btn
                @click="cancelarEdicion"
                block
                large
                class="black--text rounded-lg"
                color="red "
                ><v-icon large color="white">mdi-close</v-icon></v-btn
              >
            </v-col>
            <v-col cols="6" md="6">
              <v-btn
                @click="cambioProductoMenuGuardar"
                block
                large
                class="black--text rounded-lg"
                color="primary"
                ><v-icon large color="black">mdi-check</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
    <template v-if="editarProductoInternoObj">
      <v-card
        class="transparent fill-height"
        elevation="0"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
      >
        <v-card-title class="rounded-t-lg py-0 align-start">
          <v-list-item class="pa-0" two-line style="max-width: 100%">
            <v-list-item-content>
              <v-list-item-title class="align-self-start">
                <span class="font-weight-bold">
                  {{ editarProductoInternoObj.nombre }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-1 align-self-center">
              <v-list-item-action-text
                v-if="false"
                class="subtitle-1 font-weight-black black--text"
              >
                {{
                  mostrarMoneda(
                    editarProductoObj.cantidad *
                      editarProductoObj.precioProductoTotalUnitario
                  )
                }}€
              </v-list-item-action-text>
              <v-chip
                small
                color="primary"
                class="black--text border"
                v-if="editarProductoInternoObj.cantidad !== 1"
              >
                x{{ editarProductoInternoObj.cantidad }}</v-chip
              >
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row justify="center" class="pt-lg-4">
            <v-col cols="11">
              <v-row justify="end">
                <v-col cols="4" class="px-1">
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="
                      borrar($store.state.internos.editarProductoInternoObj)
                    "
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn></v-col
                >
                <v-col cols="4" class="px-1">
                  <v-btn
                    :disabled="editarProductoInternoObj.cantidad <= 1"
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('menos')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-1">
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('mas')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="keyboard--transparent-bg pt-0">
              <KeyboardNumerico
                keyboardClass="keyboard-cambio-carrito-integrado"
                noDividir
                ref="keyboardRefNumerico"
                @onChange="onChangeTecladoNuevoProductoNumerico"
                @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                :input="cambioProductoPrecioInput"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : ''"
          class="pa-4 card-actions--bottom"
        >
          <v-row>
            <v-col cols="6" md="6">
              <v-btn
                @click="cancelarEdicion"
                block
                large
                class="black--text rounded-lg"
                color="red lighten-1"
                ><v-icon large color="white">mdi-close</v-icon></v-btn
              >
            </v-col>
            <v-col cols="6" md="6">
              <v-btn
                @click="cambioProductoInternoGuardar"
                block
                large
                class="black--text rounded-lg"
                color="primary lighten-1"
                ><v-icon large color="black">mdi-check</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
    <swipe-list
      ref="list"
      :disabled="!enabled"
      :items="productosComputed"
      item-key="id"
      v-else-if="productosComputed.length >= 1"
    >
      <template v-slot="{ item, index, revealLeft, revealRight, close }">
        <!-- item is the corresponding object from the array -->
        <!-- index is clearly the index -->
        <!-- revealLeft is method which toggles the left side -->
        <!-- revealRight is method which toggles the right side -->
        <!-- close is method which closes an opened side -->
        <v-list-item
          :disabled="(editarProductoObj && !item.editando) || !!pagoDividido"
          class="py-0"
          two-line
          :class="!!item.menuId ? 'producto-menu' : ''"
          @click="clickProducto(revealRight, revealLeft, close, item, index)"
        >
          <v-list-item-content>
            <v-list-item-title class="align-self-start">
              <span class="font-weight-bold">
                {{ !!item.nombreEs ? item.nombreEs : item.nombre }}
              </span>
              <!--  <span v-if="item.cantidad > 1" class="text-caption"> (x{{ item.cantidad }}) </span> -->
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="!!item.menuId && !!item.productosMostrar"
            >
              {{ item.productosMostrar }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="pl-1"
              v-for="(op, i) in item.opcionesMostrar"
              :key="i"
            >
              {{ op.cantidad > 1 ? op.cantidad + "x" : "--" }}

              {{ op.key }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="!!item.cantidadCobrada"
              :class="item.opcionesMostrar.length > 1 ? 'mt-1' : ''"
            >
              <v-chip small color="info" class="white--text border"
                >Cobrado x{{ item.cantidadCobrada }}</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="my-1 align-self-center">
            <v-list-item-action-text
              v-if="!item.interno"
              class="subtitle-1 font-weight-black black--text"
              >{{ item.precioTotalMostrar }}€</v-list-item-action-text
            >
            <v-chip
              small
              color="primary"
              class="black--text border"
              v-if="item.cantidad !== 1"
            >
              {{
                !!item.interno
                  ? ""
                  : item.precioProductoTotalUnitarioMostrar + " €"
              }}
              x{{ item.cantidad }}</v-chip
            >
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <template v-slot:right="{ item, close }" v-if="accion == 'deslizar'">
        <v-col
          class="swipeout-action red darken-2"
          align="center"
          @click="borrar(item)"
        >
          <!-- place icon here or what ever you want -->
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-col>
        <v-col
          class="swipeout-action blue lighten-2"
          align="center"
          @click="editar(item)"
        >
          <v-icon class="white--text">mdi-pencil</v-icon>
        </v-col>
        <v-col
          class="swipeout-action grey fill-height"
          align="center"
          @click="close"
        >
          <!-- place icon here or what ever you want -->

          <v-icon class="white--text">mdi-close</v-icon>
        </v-col>
      </template>
      <!--<template
        v-slot:left="{ item, close }"
        v-if="accion == 'cobrarPorProductos'"
      >
        <v-col
          class="swipeout-action blue lighten-2"
          align="center"
          @click="cambioProducto(item)"
        >
          <v-icon class="white--text">mdi-minus</v-icon>
        </v-col>
        <v-col
          class="swipeout-action red darken-2"
          align="center"
          @click="borrar(item)"
        >

          <v-icon class="white--text">mdi-plus</v-icon>
        </v-col>

        <v-col
          class="swipeout-action grey fill-height"
          align="center"
          @click="close"
        >

          <v-icon class="white--text">mdi-close</v-icon>
        </v-col>
      </template>-->
    </swipe-list>
    <v-row
      v-else
      align="center"
      justify="center"
      class="mx-0"
      style="height: 100%"
    >
      <v-col cols="10">
        <v-alert type="info" rounded="lg" border="left" elevation="2" outlined>
          Por favor, seleccione algún producto para empezar.
        </v-alert>
      </v-col>
    </v-row>
    <template v-if="!esconderComentarios && !editarProductoObj">
      <v-fade-transition origin="bottom center 0" mode="in-out" group>
        <div
          class="pa-2 comentario-contenedor"
          v-if="
            cuenta &&
            cuenta.comentariosCocinaMuestra &&
            productosComputed.length >= 1
          "
          :class="
            mostrarTeclado == true ? 'comentario-keyboard-activo pt-4' : ''
          "
          key="1"
        >
          <v-textarea
            outlined
            hide-details
            class="rounded-lg"
            v-model="comentarios"
            ref="comentarios"
            label="Comentario cocina"
            :rows="mostrarTeclado == true ? 3 : 1"
            no-resize
            @click="focusComentarioCocina"
            @click:clear="clearComentarioCocina"
            clearable
          />
        </div>
      </v-fade-transition>
    </template>
  </div>
</template>

<script>
import { SwipeList, SwipeOut } from "vue-swipe-actions";
import KeyboardNumerico from "./KeyboardNumerico";
import { mapGetters, mapState } from "vuex";
import {
  mostrarIdioma,
  mostrarMoneda,
  isFloat,
  chunkArray,
  redondeo,
} from "@/utils/helper.utils.js";
require("vue-swipe-actions/dist/vue-swipe-actions.css");
export default {
  data() {
    return {
      cambioProductoPrecioInput: "0",
      cambioProductoCampoCambiando: "precioProductoTotalUnitario",
      cambioProductoDialog: false,

      //editarProductoObj: null,
      enabled: true,
    };
  },
  props: {
    productos: {
      require: true,
    },
    botones: {
      require: true,
      default: true,
    },
    accion: {
      require: false,
      default: "deslizar",
    },
    esconderComentarios: {
      require: false,
      default: false,
    },
    pagoDividido: {
      require: false,
      default: false,
    },
  },
  components: {
    SwipeOut,
    SwipeList,
    KeyboardNumerico,
  },
  watch: {
    cobroPorProductos(v) {
      this.cerrarTodo();
    },
    inputTeclado(v) {
      this.$store.state.teclado.campoEditarObj[
        this.$store.state.teclado.campoEditarPropiedad
      ] = v;
    },
    cambioProductoDialog(v) {
      if (!v) {
        this.$refs.keyboardRefNumerico.reset();
      } else {
        this.$store.commit("teclado/setLanzarNumerico");
      }
    },
  },
  computed: {
    productosTodos() {
      return this.$store.getters["productos/PRODUCTOS_CATALOGO"].map((p) => {
        return {
          ...p,
          nombre: mostrarIdioma(p.nombre),
          nombreTpv: p.nombreTpv ? p.nombreTpv : mostrarIdioma(p.nombre),
          precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
        };
      });
    },
    ...mapState({
      cobroPorProductos: (state) => state.teclado.cobroPorProductos,
    }),
    editarProductoObj: {
      get() {
        return this.$store.state.pedidos.editarProductoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoObj", v);
      },
    },
    editarProductoMenuObj: {
      get() {
        return this.$store.state.pedidos.editarProductoMenuObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoMenuObj", v);
      },
    },
    editarProductoInternoObj: {
      get() {
        return this.$store.state.internos.editarProductoInternoObj;
      },
      set(v) {
        this.$store.commit("internos/setEditarProductoInternoObj", v);
      },
    },
    editarProductoMenuObjCalculado() {
      //cuando hay modificaciones
      return this.productosComputed.find(
        (pC) =>
          pC.idconcreto ==
          this.$store.state.pedidos.editarProductoMenuObj.idconcreto
      );
      return this.$store.state.pedidos.editarProductoMenuObj.idconcreto;
    },
    comentarios: {
      get() {
        return this.pedidoEditandoObj &&
          this.pedidoEditandoObj.comentarioPedidoTpv
          ? this.pedidoEditandoObj.comentarioPedidoTpv
          : "";
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "comentarioPedidoTpv",
          value: v,
        });
      },
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      set(v) {
        console.log("mutación carrito");
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      },
    },
    productosComputed() {
      if (Array.isArray(this.productos)) {
        var productosFinales = this.productos
          .filter(
            (p) =>
              p.cantidad > 0 || !!this.pedidoEditandoObj.abonoPedidoOriginal
          ) // o tiene más de un producto o es un abono
          .map((p) => {
            let opcionesFinal = false;
            if (Array.isArray(p.opcionesName)) {
              let res = [];
              let arrayRecorrer = Array.isArray(p.opcionesNombreEs)
                ? p.opcionesNombreEs
                : p.opcionesName;
              arrayRecorrer.forEach((op) => {
                var index = res.findIndex((o) => o.key == op);
                if (index < 0) {
                  res.push({
                    key: op,
                    cantidad: 1,
                  });
                } else {
                  res[index].cantidad++;
                }
              });
              opcionesFinal = res;
            }
            var coincide = false;
            if (!!this.editarProductoObj) {
              // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
              var array2Sorted = this.editarProductoObj.opciones.slice().sort();
              coincide =
                p.id === this.editarProductoObj.id &&
                p.opciones.length === this.editarProductoObj.opciones.length &&
                p.opciones
                  .slice()
                  .sort()
                  .every(function (value, index) {
                    return value === array2Sorted[index];
                  });
            }

            //agrupamos productos menú

            return {
              ...p,
              precioTotalMostrar: p.precioProductoTotal
                ? mostrarMoneda(p.precioProductoTotal)
                : 0,
              precioProductoTotalUnitarioMostrar: p.precioProductoTotalUnitario
                ? mostrarMoneda(p.precioProductoTotalUnitario)
                : 0,
              cantidad: redondeo(p.cantidad),
              opcionesMostrar: opcionesFinal ? opcionesFinal : [],
              editando: !!coincide,
            };
          })
          .reverse();

        //agrupamos menu
        var productosMenu = [];
        var productosMenuTipo = {
          menuId: "",
          menuIdConcreto: "",
          nombre: "",
          precioProductoTotal: 0,
          precioProductoTotalUnitario: 0,
          productos: [],
        };
        productosFinales
          .filter((p) => !!p.menuId)
          .forEach((menu) => {
            let fI = productosMenu.findIndex(
              (m) => m.menuIdConcreto === menu.menuIdConcreto
            );
            if (fI > -1) {
              productosMenu[fI].productos.push(menu);
            } else {
              //console.log(menu.menuCopy);
              productosMenu.push({
                menuId: menu.menuId,
                menuIdConcreto: menu.menuIdConcreto,
                nombre: menu.menuCopy.nombre,
                precioProductoTotal: menu.menuCopy.precio,
                precioProductoTotalUnitario: menu.menuCopy.precio,
                precioTotalMostrar: mostrarMoneda(menu.menuCopy.precio),
                precioProductoTotalUnitarioMostrar: mostrarMoneda(
                  menu.menuCopy.precio
                ),
                productos: [menu],
              });
            }
          });
        return [
          ...productosMenu.map((menu) => {
            // console.log(this.pedidoEditandoObj.menus[0].idconcreto);
            console.log(menu.menuIdConcreto);
            let f = this.pedidoEditandoObj.menus
              ? this.pedidoEditandoObj.menus.find(
                  (m) => m.idconcreto == menu.menuIdConcreto
                )
              : false;
            console.log(f);
            return {
              ...menu,
              ...f,
              productosMostrar: menu.productos
                .map((p) => p.cantidad + "x " + p.nombreTpv)
                .join(", "),
              esMenu: true,
            };
          }),
          ...productosFinales.filter((p) => !p.menuId),
        ];
      } else {
        return [];
      }
    },
    ...mapGetters({
      cuenta: "cuenta",
    }),
    mostrarTeclado: {
      get() {
        return this.$store.state.teclado.mostrarTeclado;
      },
      set(v) {
        if (v) {
          this.$store.dispatch("teclado/mostrarTeclado");
        } else {
          this.$store.dispatch("teclado/esconderTeclado");
        }
      },
    },
  },
  methods: {
    mostrarMoneda(s) {
      return mostrarMoneda(s);
    },
    setCambioProductoCampoCambiando(campo) {
      this.cambioProductoCampoCambiando = campo;
      this.$refs.keyboardRefNumerico.reset();
    },
    cambioProductoCantidad(s) {
      if (Object.keys(this.pedidoEditandoObj).length !== 0) {
        if (s == "menos") {
          if (
            this.editarProductoObj.cantidad > 1 &&
            (!this.editarProductoObj.cantidadCobrada ||
              this.editarProductoObj.cantidadCobrada <
                this.editarProductoObj.cantidad)
          ) {
            this.editarProductoObj.cantidad--;
          }
        } else {
          this.editarProductoObj.cantidad++;
        }
      } else if (this.editarProductoInternoObj) {
        if (s == "menos") {
          this.editarProductoInternoObj.cantidad--;
        } else {
          this.editarProductoInternoObj.cantidad++;
        }
      }
      // this.$store
      //   .dispatch("internos/cambiarCantidadCarrito", {
      //     producto: item,
      //     cantidad: 0,
      //   })
      //   .then(() => {
      //     this.editarProductoInternoObj = null;
      //   });
    },
    onKeyPressTecladoNuevoProductoNumerico(button) {
      if (button == "C") {
        if (this.editarProductoObj) {
          setTimeout(() => {
            // this.cambioProductoObj[this.cambioProductoCampoCambiando] = "0";
            this.editarProductoObj[this.cambioProductoCampoCambiando] = "0";
            this.$refs.keyboardRefNumerico.reset();
          }, 100);
        } else if (this.editarProductoInternoObj) {
          setTimeout(() => {
            // this.cambioProductoObj[this.cambioProductoCampoCambiando] = "0";
            this.editarProductoInternoObj.cantidad = 1;
            this.$refs.keyboardRefNumerico.reset();
          }, 100);
        }
      }
    },
    onChangeTecladoNuevoProductoNumerico(input) {
      if (this.editarProductoObj) {
        if (this.cambioProductoCampoCambiando == "precioProductoTotalUnitario")
          input = mostrarMoneda(parseInt(input) / 100);
        // this.cambioProductoObj[this.cambioProductoCampoCambiando] = input;
        this.editarProductoObj[this.cambioProductoCampoCambiando] = input;
        if (isFloat(parseFloat(this.cambioProductoPrecioInput)))
          this.cambioProductoPrecioInput = "0";
      } else if (this.editarProductoInternoObj) {
        console.log(input);
        this.editarProductoInternoObj.cantidad = parseInt(input);
      }
    },
    cambioProductoMenuGuardar() {
      this.editarProductoMenuObj = null;
      this.$store.commit("productos/setPasoPidiendoProducto", 0);
      this.$store.commit("productos/setProductoPidiendoMenu", null);
    },
    cambioProductoGuardar() {
      this.$store
        .dispatch("pedidos/cambiarCantidadCarrito", {
          producto: this.editarProductoObj,
          cantidad: this.editarProductoObj.cantidad,
          precioProductoTotalUnitario: parseFloat(
            this.editarProductoObj.precioProductoTotalUnitario
          ),
          comentarioCocina: this.editarProductoObj.comentarioCocina,
        })
        .finally(() => {
          this.editarProductoObj = null;
          this.cambioProductoDialog = false;
        });
    },
    cambioProductoInternoGuardar() {
      this.$store
        .dispatch("internos/cambiarCantidadCarrito", {
          producto: this.editarProductoInternoObj,
          cantidad: this.editarProductoInternoObj.cantidad,
        })
        .finally(() => {
          this.editarProductoInternoObj = null;
          // this.cambioProductoDialog = false;
        });
    },
    clickProducto(revealRight, revealLeft, close, item, index) {
      if (this.accion == "deslizar") {
        //en pedido dialog
        console.log(item);
        if (item.esMenu) {
          this.editarMenu(item);
        } else if (item.interno) {
          this.editarInterno(item);
        } else {
          this.editar(item);
        }
        //this.cerrarTodo();
        //revealRight();
      } else {
        this.$emit("onClickProducto", item, index);
      }
    },
    cerrarTodo() {
      this.$refs.list.closeActions();
    },
    eliminarMenu() {
      console.log(this.editarProductoMenuObj);
      console.log(
        this.productos.filter(
          (p) =>
            p.menuIdConcreto &&
            p.menuIdConcreto == this.editarProductoMenuObj.menuIdConcreto
        )
      );
      Promise.all(
        this.productos
          .filter(
            (p) =>
              p.menuIdConcreto &&
              p.menuIdConcreto == this.editarProductoMenuObj.menuIdConcreto
          )
          .map((p) => {
            console.log(p);
            return this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
              producto: p,
              cantidad: 0,
            });
          })
      )
        .then(() => {
          return this.$store.dispatch("pedidos/cambiarCantidadMenu", {
            producto: this.editarProductoMenuObj,
            cantidad: 0,
          });
        })
        .then(() => {
          this.editarProductoObj = null;
          this.editarProductoMenuObj = null;
          this.$store.commit("productos/setProductoPidiendoMenu", null);
          this.$store.commit("productos/setPasoPidiendoProducto", 0); //elección menú
        });
    },
    borrar(item) {
      if (Object.keys(this.pedidoEditandoObj).length !== 0) {
        this.$store
          .dispatch("pedidos/cambiarCantidadCarrito", {
            producto: item,
            cantidad: 0,
          })
          .then(() => {
            this.editarProductoObj = null;
          });
      } else if (this.editarProductoInternoObj) {
        this.$store
          .dispatch("internos/cambiarCantidadCarrito", {
            producto: item,
            cantidad: 0,
          })
          .then(() => {
            this.editarProductoInternoObj = null;
          });
      }
    },
    cambioProducto(item) {
      this.cambioProductoDialog = true;
      this.cambioProductoObj = JSON.parse(JSON.stringify(item));
    },
    editar(item) {
      this.cerrarTodo();
      this.editarProductoObj = { ...item };
      //this.cambioProductoObj = JSON.parse(JSON.stringify(item));
    },
    editarInterno(item) {
      this.cerrarTodo();
      this.editarProductoInternoObj = { ...item };
      console.log(this.editarProductoInternoObj);
      //this.cambioProductoObj = JSON.parse(JSON.stringify(item));
    },
    editarMenu(item) {
      let fP = this.$store.getters["productos/PRODUCTOS_CATALOGO"].find(
        (p) => p.id == item.menuId
      );
      this.cerrarTodo();
      this.editarProductoMenuObj = item;
      console.log(item);
      this.$store.commit("productos/setProductoPidiendoMenu", {
        ...fP,
        nombre: mostrarIdioma(fP.nombre),
        idconcreto: item.menuIdConcreto,
      }); //aqui habrá que poner las opciones ya seleccionadas
      this.$store.commit("productos/setPasoPidiendoProducto", 2); //elección menú

      //this.cambioProductoMenuObj = JSON.parse(JSON.stringify(item));
    },
    abrirOpcionesEditarMenu() {
      let f = this.$store.getters["productos/PRODUCTOS_CATALOGO"].find(
        (p) => p.id == this.editarProductoMenuObj.menuId && !!p.menu
      );
      console.log(f);
      if (f) {
        this.$store.commit("productos/setProductoPidiendoMenu", {
          ...f,
          ...this.editarProductoMenuObj,
        });
        this.$store.commit("productos/setProductoPidiendoMenuDialog", true);
      }
    },
    abrirOpcionesEditarProducto() {
      let fP = this.productosTodos.find(
        (p) => p.id === this.editarProductoObj.id
      );

      console.log(fP);
      //fp.opciones contiene si tiene opciones. Puede ser que en carrito no estén pero tenga la opción
      if (fP.opciones && fP.opciones.length > 0) {
        this.$store.commit("productos/setProductoPidiendo", fP);

        this.editarProductoObj.opciones.map((op, i) => {
          let opFound = fP.opciones.find((o) => o.id == op);

          this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
            incompatibilidades: !!opFound ? opFound.incompatibilidades : [],
            nombreMostrar: this.editarProductoObj.opcionesName[i],
            //nombreTpv: op.nombreTpv,
            id: op,
            precioCobrar: this.editarProductoObj.opcionesPrecio[i],
            cantidad: 1,
            //panel: panel.id,
          });
        });

        this.$store.commit("productos/setPasoPidiendoProductoOpciones", 1);
        //this.opcionesProductoDialogo = true;
      } else {
        var f = this.$store.state.productos.categorias.find(
          (c) => c.id == v.categoria
        );
        if (f) {
          this.$store.commit("productos/setCategoriaPidiendo", {
            ...f,
            nombre: mostrarIdioma(f.nombre),
          });
          this.$store.commit("productos/setPasoPidiendoProducto", 1);
        }
      }
      this.$store.commit("productos/setOpcionesProductoDialogo", true);
    },

    cancelarEdicion() {
      this.editarProductoObj = null;
      this.editarProductoMenuObj = null;
      this.editarProductoInternoObj = null;
    },
    contentClick(e) {},
    itemDblClick(e) {},
    fbClick(e) {},
    sbClick(e) {},
    sumRest(item, accion) {
      this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
        producto: item,
        cantidad: accion == "mas" ? item.cantidad + 1 : item.cantidad - 1,
      });
    },
    clearComentarioCocina() {
      this.comentarios = "";
      this.focusComentarioCocina();
    },
    focusComentarioCocina() {
      if (this.$store.state.necesarioTeclado) {
        setTimeout(() => {
          let pos = this.comentarios.length ? this.comentarios.length : 0;
          this.$refs.comentarios.$el
            .querySelector("textarea")
            .setSelectionRange(pos, pos);
        }, 200);
        var _vm = this;

        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit("teclado/setActualValor", this.comentarios);
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this);
        this.$store.commit("teclado/setInput", "comentarios");
        this.$store.commit("teclado/setCampoEditarPropiedad", "comentarios");
      }
    },
    focusComentarioCocinaProducto() {
      if (this.$store.state.necesarioTeclado) {
        setTimeout(() => {
          let pos =
            this.editarProductoObj &&
            this.editarProductoObj.comentarioCocina.length
              ? this.editarProductoObj.comentarioCocina.length
              : 0;
          this.$refs.comentariosProducto.$el
            .querySelector("textarea")
            .setSelectionRange(pos, pos);
        }, 200);
        var _vm = this;

        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit(
          "teclado/setActualValor",
          this.editarProductoObj.comentarioCocina
        );
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this.editarProductoObj);
        this.$store.commit("teclado/setInput", "comentarioCocina");
        this.$store.commit(
          "teclado/setCampoEditarPropiedad",
          "comentarioCocina"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.swipeout {
  .swipeout-action {
    display: flex;
  }
}
.v-text-field {
  &.text-center {
    input {
      text-align: center;
    }
  }
}
.input--fake-solo,
.input--p0 {
  &.v-text-field--outlined {
    > .v-input__control {
      > .v-input__slot {
        fieldset {
          color: transparent !important;
          border: none !important;
        }
      }
    }
  }
  input {
    /* fix para Safari */
    max-height: none !important;
    padding: 0 !important;
  }
}
.comentario-contenedor {
  background: #fafafa;
  &.comentario-keyboard-activo {
    background: white;
  }
}
.comentario-keyboard-activo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}
.card-actions--bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid;
}
.keyboard--transparent-bg {
  .hg-theme-default {
    background-color: transparent;
  }
}

.producto-menu {
  background-color: rgb(200, 200, 147);
}
</style>