import { db, functions } from '@/firebase'

export default {
  // getUltimaSincronizacionProductos({ rootState, commit }) {
  //   let rest = rootState.auth.user ? rootState.auth.user.restauranteTpv : null
  //   console.log(rest);
  //   return db.collection('restaurantes').doc(rest).collection('secreto').doc('neo')
  //     .get().then(r => {
  //       if (r.exists) {
  //         console.log(r.data())
  //       }
  //     })
  // }
  actualizarNeoRestaurante() {
    var f = functions.httpsCallable('httpNeoActualizarNeoRestauranteTpv');
    return f().then((r) => {

    })
  }
}