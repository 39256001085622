<template>
  <v-dialog
    v-model="historialDialog"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="
      $vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'
    "
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title
        class="white py-3 pr-3" height="78"
      >
        <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          HISTORIAL - {{ tituloPidiendo }} {{ numeroPidiendo }}
        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="historialDialog = false"
          top
          right
        >
          <v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
            color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4 blue-grey lighten-5">
        
            <v-list flat color="transparent">
              <template v-for="(h, i, n) in historialComputed">
                <v-list-item :key="i" class="justify-space-between" style="max-width: 100%;">
                  <v-list-item-content>
                    <div :class="$vuetify.breakpoint.smAndDown ? '' : 'text-h6'" style="line-height: 1.2">
                      {{ h.mensaje }}
                    </div>
                  </v-list-item-content>
                  <v-list-item-action :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'text-h5'" class="text-right">
                    {{ h.fechaMostrar }}
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="n"></v-divider>
              </template>
            </v-list>
          </v-card-text
      ></v-card
    ></v-dialog
  >
</template>

<script>
import moment from "moment";
export default {
  watch: {
    moverPedidoModoDialog(v) {
      if (!v) {
      }
    },
  },
  computed: {
    tituloPidiendo() {
      var res = "";
      if (!!this.pedidoEditandoObj) {
        if (this.pedidoEditandoObj.modoCopy)
          return this.pedidoEditandoObj.modoCopy.nombre;
        switch (this.pedidoEditandoObj.modo) {
          case "domicilio":
            res = "DOMICILIO";
            break;
          case "recoger":
            res = "RECOGER";
            break;
          case "mesa":
            res = "MESA " + this.pedidoEditandoObj.copyMesa.nombre;
            break;
          case "mostrador":
            res = "MOSTRADOR ";
            break;
        }
      }
      return res;
    },
    numeroPidiendo() {
      return this.pedidoEditandoObj
        ? this.pedidoEditandoObj.numPedidoRestDia
        : "-";
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
    },
    historialComputed() {
      return this.pedidoEditandoObj && this.pedidoEditandoObj.historial
        ? this.pedidoEditandoObj.historial
            .sort((a, b) => b.fecha - a.fecha)
            .map((h) => {
              return {
                ...h,
                fechaMostrar: moment.unix(h.fecha).format("HH:mm"),
              };
            })
        : [];
    },
    historialDialog: {
      get() {
        return this.$store.state.pedidos.historialDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setHistorialDialog", v);
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>