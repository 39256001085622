export default {
    setImpresionDialog(state, b) {
        state.impresionDialog = b;
    },
    setTrabajosPorImprimir(state, a) {
        state.trabajosPorImprimir = a;
    },
    addTrabajoPorImprimir(state, t) {
        state.trabajosPorImprimir.push(t);
    },
    eliminarPrimero(state) {
        state.trabajosPorImprimir.shift();
    },
    marcarImprimiendoPrimero(state) {
        state.trabajosPorImprimir[0].imprimiendo = true;
    },
    addTrabajoQueSeEstanTratando(state, i) {
        state.trabajoQueSeEstanTratando.push(i);
    },
    setLogosCaja(state, a) {
        state.logosCaja = a;
    },
    pushLogosCaja(state, obj) {
        state.logosCaja.push(obj);
    },
    setPedidoOcupado(state, i) {
        state.pedidoOcupado = i;
    }
}