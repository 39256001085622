<template>
  <v-dialog
    :transition="$store.state.bajaCalidad ? 'none' : 'scale-transition'"
    v-model="dialog"
    fullscreen
    scrollable
  >
    <v-card flat rounded="0" color="blue-grey lighten-5">
      <v-card-title class="pa-0">
        <v-toolbar dark height="78">
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'title'"
            class="font-weight-black"
          >
            {{ titulo }}
          </h2>
          <v-spacer />
          <slot name="botones" />

          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="dialog = false"
            :loading="loading"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 100%">
        <v-container fluid>
          <slot />
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      require: false,
      default: "",
    },
    name: {
      type: String,
      require: true,
    },
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  computed: {
    nameArray() {
      return this.name.split("/");
    },
    nameStore() {
      return this.nameArray.length == 2 ? this.nameArray[0] + "/" : "";
    },
    nameStoreWithout() {
      return this.nameArray.length == 2 ? this.nameArray[0] : "";
    },
    nameAction() {
      return this.nameArray.length == 2 ? this.nameArray[1] : this.nameArray[1];
    },
    dialog: {
      get() {
        return this.$store.state[this.nameStoreWithout][
          this.nameAction + "Dialog"
        ];
      },
      set(v) {
        let s =
          this.nameStore + "SET_" + this.nameAction.toUpperCase() + "_DIALOG";
        this.$store.commit(s, v);
      },
    },
  },
};
</script>

<style>
</style>