
export default {
    SET_TRABAJOS_CONTABLES(state, a) {
        state.trabajosContables = a;
    },
    SET_TRABAJOS_CONTABLES_LISTENER(state, a) {
        state.trabajosContablesListener = a;
    },
    SET_NUMEROS_CONTABLES(state, obj) {
        state.numerosContables = obj;
    },
    // SET_CODIGO_RESTAURANTE(state, s) {
    //     state.codigoRestaurante = s;
    // },
}