<template>
  <v-dialog
    v-model="abonoDialog"
    max-width="1200px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="
      $vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'
    "
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title class="white py-3 pr-3" height="78">
        <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          ABONAR PRODUCTOS AL PEDIDO - {{ tituloPidiendo }} {{ numeroPidiendo }}
        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="abonoDialog = false"
          top
          right
        >
          <v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 44" color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-title class="white py-3 pr-3" height="78">
        {{ subtitulo }}
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4 blue-grey lighten-5">
        <v-row class="pa-2 pa-md-0" v-if="!modoPaginacion">
          <v-col
            v-for="ped in pedidosCobrados"
            :key="ped.id"
            cols="6"
            sm="4"
            md="3"
            class="pa-2 pa-md-3"
          >
            <pedido-card :pedido="ped" />
          </v-col>
        </v-row>
        <v-row class="pa-2 pa-md-0" v-else>
          <v-col
            v-for="ped in pedidosPaginados"
            :key="ped.id"
            cols="12"
            sm="6"
            md="3"
            class="pa-2 pa-md-3"
          >
            <pedido-card :pedido="ped" />
          </v-col> </v-row></v-card-text></v-card
  ></v-dialog>
</template>

<script>
import moment from "moment";
import { chunkArray } from "@/utils/helper.utils.js";
import { mapState } from "vuex";
import PedidoCard from "../components/PedidoCard.vue";

export default {
  components: {
    PedidoCard,
  },
  data: () => {
    return {
      pasoAbono: "elegirPedido", //elegirPedido, elegirProducto
      pedidoElegido: null,
    };
  },
  watch: {
    moverPedidoModoDialog(v) {
      if (!v) {
      }
    },
  },
  computed: {
    subtitulo() {
      switch (this.pasoAbono) {
        case "elegirPedido":
          return "Elije el pedido donde quieres abonar";
        case "elegirProducto":
          return "Elije los productos a abonar";
      }
    },
    ...mapState([
      "modoPaginacion",
      "pedidosPaginacionPedidos",
      "paginaPaginacionPedidos",
    ]),
    tituloPidiendo() {
      var res = "";
      if (!!this.pedidoEditandoObj) {
        if (this.pedidoEditandoObj.modoCopy)
          return this.pedidoEditandoObj.modoCopy.nombre;
        switch (this.pedidoEditandoObj.modo) {
          case "domicilio":
            res = "DOMICILIO";
            break;
          case "recoger":
            res = "RECOGER";
            break;
          case "mesa":
            res = "MESA " + this.pedidoEditandoObj.copyMesa.nombre;
            break;
          case "mostrador":
            res = "MOSTRADOR ";
            break;
        }
      }
      return res;
    },
    numeroPidiendo() {
      return this.pedidoEditandoObj
        ? this.pedidoEditandoObj.numPedidoRestDia
        : "-";
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
    },
    pedidosCobrados() {
      return this.$store.getters["pedidos/pedidos"].filter((p) => {
        return !!p.cobrado;
      });
    },
    pedidosPaginados() {
      if (this.abonoDialog) {
        let pedidos = chunkArray(
          this.pedidosCobrados,
          this.pedidosPaginacionPedidos
        );
        let res = pedidos[this.paginaPaginacionPedidos];
        this.$store.commit(
          "setMaximoPaginacionPedidos",
          this.pedidosCobrados.length
        );
        this.$store.commit(
          "setMostrandoPaginacionPedidos",
          res ? res.length : 0
        );
        console.log("abono");
        return res;
      }
    },
    abonoDialog: {
      get() {
        return this.$store.state.pedidos.abonoDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setAbonoDialog", v);
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>