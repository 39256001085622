<template>
  <div style="height: 100%" class="blue-grey lighten-5" id="inspire">
    <v-bottom-sheet :value="zcierreDialogMostrar" persistent>
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" text color="red" @click="logout"> LOGOUT </v-btn>
        <div class="py-3">
          Inicio el día en el TPV para poder usar el comandero
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="tpvOfflineMostrar" persistent>
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" text color="red" @click="tpvOfflineMostrar = false">
          CONTINUAR
        </v-btn>
        <div class="py-3">El TPV está offline, no podrá recibir el pedido</div>
      </v-sheet>
    </v-bottom-sheet>
    <v-app-bar height="56" app dark>
      <v-toolbar-title class="">
        <v-btn
          @click="
            $router.push(modosMesas[0].enlace);
            tab = 0;
          "
          class="px-0 py-7 fe--inicio-btn"
          max-width="100%"
        >
          <v-img
            class="pa-0"
            src="/img/logoFunEats.svg"
            lazy-src="/img/logoFunEats.svg"
            contain
            :width="$vuetify.breakpoint.xsOnly ? 120 : 150"
            height="30"
          ></v-img>
        </v-btn>
      </v-toolbar-title>
      <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
      <v-spacer></v-spacer>
      <v-btn
        class="rounded-lg justify-start usuarios-btn"
        :large="$vuetify.breakpoint.mdAndUp"
        outlined
        color="primary"
        :max-width="$vuetify.breakpoint.xsOnly ? '45%' : '60%'"
        @click="$store.commit('usuarios/setEleccionUsuarioDialog', true)"
      >
        <v-icon class="mx-0 mr-2 ml-n1" left>mdi-account-check</v-icon>
        <div class="text-truncate">
          {{ $store.state.usuarios.usuarioActualObj.nombre }}
        </div>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="modosMenu"
      fixed
      right
      color="primary"
      width="280"
    >
      <v-list-item class="justify-end pr-0">
        <v-btn text fab @click="modosMenu = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-list-item>
      <v-tabs
        background-color="transparent"
        slider-size="8"
        slider-color="teal lighten-2"
        active-class="font-weight-black"
        v-model="tab"
        vertical
        style="height: calc(100% - 60px)"
      >
        <v-tab
          v-for="modoMesa in modosMesas"
          :key="modoMesa.id"
          @click="navegar(modoMesa)"
          class="black--text justify-start pl-8"
        >
          {{ modoMesa.nombre }}
        </v-tab>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-tab @click="ultimopedido" class="black--text justify-start pl-8">
          Último pedido cobrado
        </v-tab>
        <v-tab
          @click="$router.push('/busquedapedidos')"
          class="black--text justify-start pl-8"
        >
          Todos los pedidos del día
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>
    <v-sheet
      class="subheader-nav--sticky subheader--comandero"
      color="primary"
      elevation="3"
    >
      <v-tabs
        v-if="$vuetify.breakpoint.smAndUp"
        background-color="primary"
        show-arrows
        center-active
        slider-size="8"
        slider-color="teal lighten-2"
        active-class="font-weight-black"
        height="56"
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
        v-model="tab"
      >
        <!-- <v-btn text @click="$router.push('/pedidos/domicilio')"
          >DOMICILIO</v-btn
        >
        <v-btn text @click="$router.push('/pedidos/recoger')">RECOGER</v-btn> -->
        <v-tab
          v-for="modoMesa in modosMesas"
          :key="modoMesa.id"
          @click="navegar(modoMesa)"
          class="black--text"
        >
          {{ modoMesa.nombre }}
        </v-tab>

        <v-spacer />
        <!-- <v-tab class="black--text" @click="$router.push('/dashboard')"
          >MÁS</v-tab
        > -->
      </v-tabs>
      <template v-else>
        <div class="d-flex px-4">
          <div class="caption font-weight-bold py-2 pl-1 text-uppercase">
            {{ modosMesas[tab] ? modosMesas[tab].nombre : "" }}
          </div>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modosMenu = true"></v-app-bar-nav-icon>
        </div>
      </template>
    </v-sheet>
    <v-content>
      <v-container fluid class>
        <!-- Vista principal -->
        <router-view />
      </v-container>
    </v-content>
    <eleccion-usuario-dialog />
    <eleccion-usuario-admin-acciones-dialog />

    <cobrar-dialog />
    <cliente-dialog />
    <anadir-dialogo />
    <v-btn
      v-if="$store.state.mostrarFabAdd"
      fab
      height="68"
      width="68"
      bottom
      right
      color="primary"
      fixed
      @click="abrirDialogoAnadir = true"
      ><v-icon size="48" color="black">mdi-plus</v-icon></v-btn
    >
  </div>
</template>

<script>
import AnadirDialogo from "../components/AnadirDialogo.vue";
import CobrarDialog from "../components/CobrarDialog.vue";
import EleccionUsuarioDialog from "../components/EleccionUsuarioDialog.vue";
import EleccionUsuarioAdminAccionesDialog from "../components/EleccionUsuarioAdminAccionesDialog.vue";
import ClienteDialog from "@/components/ClienteDialog.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    EleccionUsuarioDialog,
    EleccionUsuarioAdminAccionesDialog,
    CobrarDialog,
    AnadirDialogo,
    ClienteDialog,
  },
  data() {
    return {
      checkboxFunEats: true,
      tab: 0,
      checkboxJustEat: false,
      checkboxUberEats: false,
      checkboxGlovo: false,
      checkboxMesaLibre: true,
      checkboxMesaOcupada: false,
      modosMenu: false,
      tpvOfflineMostrar: false,
    };
  },
  watch: {
    online(v) {
      this.tpvOfflineMostrar = v;
    },
    modosMesas: function (oldV, newV) {
      console.log(newV);
      if (oldV.length == 0 && newV.length > 0) {
        //se han cargado los modos, para cuando hay logueo nuevo de comandero
        this.$router.push(this.modosMesas[0].enlace);
        this.tab = 0;
      }
    },
  },
  computed: {
    ...mapState([
      "online",
      "zcierreDialog",
      "datosTpv",
      "horaActual",
      "zcierre",
      "configuracionTpv",
    ]),
    zcierreDialogMostrar() {
      return (
        this.configuracionTpv &&
        this.configuracionTpv.zcierreObligatorio &&
        !this.zcierre
      );
    },
    // tab: {
    //   get() {
    //     return this.$store.state.tabNavegacionModos;
    //   },
    //   set(v) {
    //     this.$store.commit("setTabNavegacionModos", v);
    //   },
    // },
    modosMesas() {
      return this.$store.state.datosTpv.modos
        .filter((m) => !!m.tipoModo)
        .map((m) => {
          var link = "";
          switch (m.tipoModo) {
            case "domicilio":
              link = "/pedidos/domicilio/" + m.id;
              break;
            case "recoger":
              link = "/pedidos/recoger/" + m.id;
              break;
            case "mostrador":
              link = "/pedidos/mostrador/" + m.id;
              break;
            case "mesas":
              link = "/mesas/" + m.id;
              break;
          }
          return {
            ...m,
            enlace: "/comandero" + link,
          };
        });
    },
    abrirDialogoAnadir: {
      get() {
        return this.$store.state.pedido.pedidoDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoDialog", v);
      },
    },
    usuarioSeleccionado: {
      get() {
        return this.$store.state.usuarios.usuarioActual;
      },
      set(v) {},
    },
    modo() {
      return this.$route.params.modotipo;
    },
    modoid() {
      return this.$route.params.modoid;
    },
    modomesa() {
      return this.$route.params.modomesa;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/firebaseLogout",
    }),
    // setTab() {
    //   alert(this.modo + " " + this.modoid + " " + this.modomesa);
    //   let fIndex = this.$store.state.datosTpv.modos.findIndex(
    //     (m) => m.id == this.modoid
    //   );
    //   console.log(this.$route.params.modomesa);
    //   // alert(this.modoid + " " + fIndex);
    //   this.tab = fIndex > -1 ? fIndex : 0;
    //   // this.$store.commit("setTabNavegacionModos", );
    // },
    navegar(modoMesa) {
      // alert(modoMesa.id);
      // this.tab = modoMesa.id;
      this.$router.push(modoMesa.enlace);
    },
    ultimopedido() {
      this.$store.dispatch("pedidos/ultimopedidoMostrarCobrar").then((r) => {
        if (r) {
          this.$store.commit("pedidos/setCobrarDialog", true);

          this.pedidoEditando = r;
          this.$store.dispatch("pedidos/setPedidoObj", r);
        }
      });
    },
  },
  mounted() {
    let fI = this.modosMesas.findIndex((m) => m.enlace == this.$route.path);
    this.tab = fI;
    this.$store.dispatch("cargaDespuesInicio");
    this.$store.commit("setModoFuncionamiento", "comandero");
    // screen.orientation.lock("portrait").catch((e) => {
    //   console.log("no se puede bloquear");
    // });
    console.log("aqui");
    console.log(this.$route.fullPath);
    console.log(this.modosMesas.length);
    if (this.$route.fullPath === "/comandero" && this.modosMesas.length > 0) {
      this.$router.push(this.modosMesas[0].enlace);
      this.tab = 0;
    }
  },
};
</script>

<style>
.subheader-nav--sticky.subheader--comandero {
  top: 56px;
}
</style>