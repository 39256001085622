<template>
  <div>
    <v-row>
      <v-col cols="12" class="pb-0">
        <h1 class="mb-4 mt-2 display-1 text-center">Dipositivos</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" xl="6" class="mx-auto pt-0 text-right">
        <v-btn large color="primary" class="black--text rounded-lg" @click="dialogoDispositivos = true">
          <v-icon large>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" xl="6" class="mx-auto pt-0">
        <v-list elevation="1" class="rounded-lg">
          <v-list-item
            v-for="(disp, i) in dispositivos"
            :key="i"
            :class="i !== 0 ? 'border--list-item' : ''"
          >
            <v-list-item-title>
              {{ disp.productName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ disp.tipo }}
              <span v-if="disp.tipo == 'ordenador'"
                >Impresión local <span v-if="disp.impresionLocal"> SI</span
                ><span v-else>NO</span></span
              >
            </v-list-item-subtitle>

            <v-list-item-action>
              <!-- <v-btn color="red" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn> -->
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoDispositivos"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      overlay-opacity="0.90"
      content-class="dialogo--custom-fs"
      scrollable
      >
      <v-card class="transparent">
        <v-card-title
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'rounded-0'
              : 'rounded-t-lg'
          "
          class="py-3 pr-3 white"
        >
           <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption'
              : $vuetify.breakpoint.mdAndDown
                ? 'title'
                : 'display-1'"
            class="font-weight-black"
          >
            Añadir dispositivo
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="dialogoDispositivos = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="rounded-b-lg blue-grey lighten-5 pt-4"
        >
        <v-row>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="vincularUsb()"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-usb-port</v-icon>
                  Asociar dispositivo USB
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="vincularSerie()"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-serial-port</v-icon>
                  Asociar dispositivo Serie
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="vincularHid()"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-keyboard</v-icon>
                  Asociar dispositivo HID
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="volcarAServidor"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-server</v-icon>
                  Volcar a servidor
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="verSerie"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-serial-port</v-icon>
                  Ver serie
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="verHid"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-keyboard</v-icon>
                  Ver HID
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="conectarBalanza"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-scale-balance</v-icon>
                  Conectar balanza
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="cerrarBalanza"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-scale-balance</v-icon>
                  Cerrar balanza
                </div>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-card
              elevation="4"
              outlined
              class="rounded-lg pa-4"
              color="primary"
              @click="conectarHid"
            >
              <v-responsive aspect-ratio="1.618" class="align-center">
                <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                  <v-icon class="mb-2">mdi-keyboard</v-icon>
                  Conectar HID
                </div>
              </v-responsive>
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialogoDispositivos: false,
    }
  },
  computed: {
    dispositivos() {
      return this.$store.state.datosTpv.dispositivosTpv.filter(
        (d) => d.cuenta == this.$store.state.auth.user.email
      );
    },
  },
  methods: {
    ...mapActions({
      vincularUsb: "configuracion/vincularUsb",
      vincularSerie: "configuracion/vincularSerie",
      vincularHid: "configuracion/vincularHid",
      verSerie: "configuracion/verSerie",
      verUsb: "configuracion/verUsb",
      verHid: "configuracion/verHid",
      enviarDatosDispositivo: "configuracion/enviarDatosDispositivo",
      conectarBalanza: "configuracion/conectarBalanza",
      conectarHid: "configuracion/conectarLectorCodigo",
      cerrarBalanza: "configuracion/cerrarBalanza",
    }),

    imprimirPrueba() {
      this.$store.dispatch("configuracion/imprimirPrueba");
    },
    volcarAServidor() {
      Promise.all([this.verUsb(), this.verSerie(), this.verHid()])
        .then((r) => {
          console.log(r);
          return this.$store.dispatch(
            "configuracion/volcarDispositivosAlServidor",
            r.flat().filter((r) => !!r)
          );
        })
        .then((r) => {
          return this.$store.dispatch("dispositivosTpv");
        })
        .then((r) => {
          console.log(this.$store.state.datosTpv.dispositivosTpv);
        });
    },
  },
  mounted() {
    console.log(this.$store.state.datosTpv.dispositivosTpv);
  },
};
</script>

<style>
</style>