<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <h1 class="mb-4 mt-2 display-1 text-center">Clientes</h1>
    </v-col>
    <v-col cols="12" md="8" xl="6" class="mx-auto white rounded-lg elevation-1">
      <clientes-component modoLista />
    </v-col>
  </v-row>
</template>

<script>
import ClientesComponent from "../components/ClientesComponent.vue";

export default {
  components: { ClientesComponent },
};
</script>


<style>
</style>