import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { db } from "@/firebase";
import firebase from "firebase";
import MultiFiltersPlugin from "./plugins/Multifilters";
import VuetifyConfirm from "vuetify-confirm";
import * as VueGoogleMaps from "vue2-google-maps";
import lineClamp from "vue-line-clamp";
import Barcoder from "barcoder";

require("@/assets/sign.message.js");

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDuLwfgGkATl--v_KliZ8OGr980E_1s6SU",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
  installComponents: true,
});

Vue.use(lineClamp, {
  // plugin options
});

Vue.config.productionTip = false;
Vue.use(MultiFiltersPlugin);
Vue.use(VuetifyConfirm, { vuetify });

function updateIndicator() {
  console.log(window.navigator.onLine);
  store.commit("setOnlineComputadora", window.navigator.onLine);
}
window.addEventListener("online", updateIndicator);
window.addEventListener("offline", updateIndicator);
store.commit("setOnlineComputadora", window.navigator.onLine);

// store.commit("setLoaded", true);

firebase.auth().onAuthStateChanged(function (user) {
  // alert(user);

  if (
    window.location.href.includes("/iniciocomandero") &&
    !store.state.auth.loginComandero
  ) {
    store.commit("auth/SET_LOGIN_COMANDERO", false);
    store.dispatch("auth/firebaseLogout", false);
    store.commit("setLoaded", true);
  } else {
    if (user) {
      return db
        .collection("users")
        .doc(user.uid)
        .get()
        .then(async (doc) => {
          let user_db = {};
          if (doc.exists) {
            user_db = doc.data();
          }
          let user_combinado = { ...user, ...user_db };
          store.commit("auth/setUser", user_combinado);
          if (user_db.role == "tpv") {
            //router.push('/dashboard')

            return store
              .dispatch("inicioTpvAlternativo")
              .then(() => {
                funcHora();
                //lector pistola, solo en tpv
                document.addEventListener("keydown", onKeyPressed, false);
                function onKeyPressed(e) {
                  var keyCode = e.keyCode;

                  if (keyCode == 13) {
                    store.commit("setPistolaLeyendo", false);
                    if (Barcoder.validate(store.state.pistolaTexto)) {
                      store.commit(
                        "setPistolaTextoDefinitivo",
                        store.state.pistolaTexto
                      );
                    }
                  } else {
                    if (keyCode != 16) {
                      store.commit("setPistolaLeyendo", true);
                      store.commit(
                        "setPistolaTexto",
                        store.state.pistolaTexto + e.key
                      );
                      setTimeout(() => {
                        store.commit("setPistolaTexto", "");
                      }, 1000);
                      // commit('setPistolaTexto', '');
                    } else {
                    }
                  }
                }
              })
              .then(() => {
                //presencia firebase

                // Fetch the current user's ID from Firebase Authentication.
                var uid = firebase.auth().currentUser.uid;
                var deviceId = uid;

                //si se desconecta otro usuario

                // Create a reference to this user's specific status node.
                // This is where we will store data about being online/offline.
                var userStatusDatabaseRef = firebase
                  .database()
                  .ref("/status/" + deviceId);
                var refStatus = userStatusDatabaseRef.child("state");
                refStatus.on("value", function (ss) {
                  if (ss.val() !== "online") {
                    // another window went offline, so mark me still online
                    setTimeout(() => {
                      let user = firebase.auth().currentUser; //usuario firebase
                      if (!!user) refStatus.set("online");
                    }, 300);
                  }
                });
                //ref.onDisconnect().remove();
                var isOfflineForDatabase = {
                  uid: uid,
                  state: "offline",
                  rest: store.getters.cuenta.marcaConectado
                    ? store.state.restauranteObj.id
                    : null,
                  last_changed: firebase.database.ServerValue.TIMESTAMP,
                };

                var isOnlineForDatabase = {
                  uid: uid,
                  state: "online",
                  rest: store.getters.cuenta.marcaConectado
                    ? store.state.restauranteObj.id
                    : null,
                  last_changed: firebase.database.ServerValue.TIMESTAMP,
                };
                var userStatusFirestoreRef = firebase
                  .firestore()
                  .doc("/status/" + deviceId);
                var isOfflineForFirestore = {
                  uid: uid,
                  state: "offline",
                  rest: store.getters.cuenta.marcaConectado
                    ? store.state.restauranteObj.id
                    : null,
                  last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                };
                var isOnlineForFirestore = {
                  uid: uid,
                  state: "online",
                  rest: store.getters.cuenta.marcaConectado
                    ? store.state.restauranteObj.id
                    : null,
                  last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                };
                firebase
                  .database()
                  .ref(".info/connected")
                  .on("value", function (snapshot) {
                    // If we're not currently connected, don't do anything.
                    if (snapshot.val() == false) {
                      userStatusFirestoreRef.set(isOfflineForFirestore);
                      return;
                    }
                    userStatusDatabaseRef
                      .onDisconnect()
                      .set(isOfflineForDatabase)
                      .then(function () {
                        // The promise returned from .onDisconnect().set() will
                        // resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected:
                        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                        // We can now safely set ourselves as 'online' knowing that the
                        // server will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                        userStatusFirestoreRef.set(isOnlineForFirestore);
                        // console.log(store.state.ordenadorConf)
                        // if (nuevoDispositivo) {
                        //   store.dispatch('configuracion/volcarDispositivosAlServidor', [{ tipo: 'ordenador', productId: store.state.uniqueId, productName: 'Ordenador ' + store.state.uniqueId, ...isOnlineForFirestore }])
                        // } else {
                        //   store.dispatch('configuracion/volcarDispositivosAlServidor', [{ ...store.state.ordenadorConf, ...isOnlineForFirestore }])
                        // }
                      });
                  });
                userStatusFirestoreRef.onSnapshot(function (doc) {
                  var isOnline = doc.data().state == "online";
                  store.commit("setOnline", isOnline);
                  // ... use isOnline
                });
              });
          } else if (user_db.role == "tpv-comandero") {
            return store.dispatch("inicioTpvAlternativo").then(() => {
              funcHora();
              // screen.orientation.lock("portrait");
              if (router.currentRoute.fullPath !== "/comandero")
                router.push("/comandero");
            });
          } else {
            router.push("/login");
            store.commit("setRestauranteObj", false);
            store.commit("setNegocioObj", false);
            store.commit("setLoaded", true);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          store.commit("setLoaded", true);
        });
    } else {
      if (!router.currentRoute.path.includes("/iniciocomandero")) {
        //router.push('/login')
      }
      store.commit("setLoaded", true);
    }
  }
});

const funcHora = () => {
  store.commit("setHoraActual", new Date());
  setTimeout(funcHora, 5000);
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
