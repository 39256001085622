<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :disabled="disabled"
      @click="$emit('accion')"
      :rounded="!!esMenu"
      elevation="4"
      class="rounded-lg ficha-generica"
      :style="hover ? 'cursor: pointer' : ''"
      :color="!!seleccionado ? 'primary' : ''"
    >
      <v-chip
        x-small
        class="custom-badge producto-badge elevation-3 info caption font-weight-bold white--text"
        v-if="!!cantidad"
      >
        {{ cantidad }}
      </v-chip>
      <v-chip
        label
        x-small
        class="custom-badge producto-price elevation-3 font-weight-bold black--text d-flex justify-center"
        v-if="!!precio"
        :class="!!seleccionado ? 'white' : 'primary'"
      >
        {{ precio }}
      </v-chip>
      <v-responsive :aspect-ratio="1.618" class="align-center">
        <v-card-text
          class="text-uppercase font-weight-black text-center"
          :class="
            $vuetify.breakpoint.smAndDown || !!muchasOpciones
              ? 'caption pa-1'
              : $vuetify.breakpoint.mdAndDown
              ? 'body-2'
              : 'body-2'
          "
          :style="
            $vuetify.breakpoint.smAndDown || !!muchasOpciones
              ? 'line-height:1;'
              : 'line-height:1.2;'
          "
        >
          <v-row no-gutters>
            <v-col
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'max-height: 48px;'
                  : $vuetify.breakpoint.lgAndDown
                  ? 'max-height: 56px;'
                  : 'max-height: 120px;'
              "
              v-line-clamp="$vuetify.breakpoint.lgAndDown ? 3 : 7"
              style="word-break: normal !important"
            >
              {{ texto }}
            </v-col>
          </v-row>
          <div class="opciones-bg" v-if="!!tieneOpciones || !!esMenu">
            <v-icon color="orange lighten-2" v-if="!!esMenu"
              >mdi-silverware-variant</v-icon
            >
            <v-icon color="info lighten-1" v-else-if="!!tieneOpciones"
              >mdi-cog</v-icon
            >
          </div>
        </v-card-text>
      </v-responsive>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    precio: {
      required: false,
    },
    cantidad: {
      required: false,
    },
    texto: {
      type: String,
      required: true,
    },
    esMenu: {
      required: false,
    },
    tieneOpciones: {
      required: false,
    },
    muchasOpciones: {
      required: false,
    },
    seleccionado: {
      required: false,
    },
    disabled: {
      require: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.opciones-bg {
  position: absolute;
  right: 0;
  top: 0;
}
</style>