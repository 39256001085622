<template></template>

<script>
export default {
  mounted() {
    let modo = this.$store.state.datosTpv.modos.filter((m) => !!m.tipoModo)[0];
    var link = "";
    switch (modo.tipoModo) {
      case "domicilio":
        link = "/pedidos/domicilio/" + modo.id;
        break;
      case "recoger":
        link = "/pedidos/recoger/" + modo.id;
        break;
      case "mostrador":
        link = "/pedidos/mostrador/" + modo.id;
        break;
      case "mesas":
        link = "/mesas/" + modo.id;
        break;
    }
    this.$router.push(link);
  },
};
</script>

<style lang="scss">
.combo--no-gira {
  &.v-select--is-menu-active {
    .v-input__icon--append {
      .v-icon {
        transform: rotate(0) !important;
      }
    }
  }
}
</style>