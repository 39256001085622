import moment from 'moment-timezone'
export default {
    preguntarInventario({ dispatch }) {
        var data = "";
        return dispatch('qz/escribirFichero', {
            fichero: data,
            ruta: 'Inventary_prueba.txt'
        }, { root: true })
        // write
        // Inventary _12345
    },
    cobroEfectivo({ dispatch, commit }, { cantidad, pedido }) {
        return dispatch('qz/escucharCambios', null, { root: true })
            .then((r) => {
                console.log(r)
                let fichero = 'Sale_' + pedido + '_.txt';
                commit('SET_FICHERO_CASHGUARD', fichero)
                return dispatch('qz/escribirFichero', {
                    fichero: cantidad,
                    ruta: fichero
                }, { root: true })
            })

    },
    reiniciar({ commit }) {
        commit('SET_ACCION_SIGUE', '')
        commit('SET_FICHERO_CASHGUARD', '')
        commit('SET_FICHERO_CASHGUARD_RESULTADO', '')
        commit('SET_CASHGUARD_DIALOG', false)
        commit('SET_CASHGUARD_BOTTOM', false)


    }

}