export default () => {
  return {
    internosDialog: false,
    paso: "listado",
    pasoPidiendo: 0,
    editarProductoObj: {},

    pedidos: [],
    categorias: [],
    productos: [],
    busqueda: "",
    pedidoEnCurso: {
      id: null,
      fechaInicio: null,
      fechaFin: null,
      carritoInterno: [],
    },
    pedidoGuardado: true,
    pedidoGuardadoLoading: false,
    pedidoEnviado: false,
    pedidoEnviarLoading: false,
    pedidoEnCursoComprobar: {
      id: null,
      fechaInicio: null,
      fechaFin: null,
      carritoInterno: [],
    },
    editarProductoInternoObj: null,
    estados: [
      {
        value: "realizando",
        text: "Realizando el franquiciado",
      },
      {
        value: "espera",
        text: "En espera",
      },
      {
        value: "enviado",
        text: "Enviado por la central",
      },
      {
        value: "finalizado",
        text: "Finalizado",
      },
    ],
  };
};
